<template>
  <div>
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="item in banner" :key="item">
        <img :src="item" alt="" class="w-100">
      </el-carousel-item>
    </el-carousel>
    <div class="d-flex w-100 align-items-center flex-column">
      <div class="" style="width: 1180px;">
        <div class="d-flex my-4">
          <div><a href="/"> <i class="el-icon-s-home"></i>跃迁出国</a></div>
          <div class="mx-3 color999">/</div>
          <div><a href="/hot"> 热门项目</a></div>
          <div class="mx-3 color999">/</div>
          <div class="color999">新加坡投资移民</div>
        </div>

        <h1 class="hot-title">新加坡投资移民</h1>

        <div class="hot-nav ">
          <a href="#001" class="active 001">国家概况</a>
          <a href="#002" class="002">条件及优势</a>
          <a href="#003"  class="003">费用清单</a>
          <a href="#004" class="004">流程图</a>
        </div>
      </div>
      <hr class="my-0 w-100" style="opacity: .2">
    </div>

    <div class="w-100 d-flex justify-content-center" style="background-color: #f2f2f2">
      <div style="width: 1180px;">
        <div id="001" class="bg-white p-3 my-5">
          <div class="-&quot;line-big&quot;">
            <div class="padding-big-left padding-big-right">
              <h2>新加坡概况</h2>
              <hr>
              <p>新加坡是一个发达的资本主义国家，被誉为“亚洲四小龙”之一，其经济模式被称作为“国家资本主义”。根据2018年的全球金融中心指数（GFCI）排名报告，新加坡是继纽约、伦敦、香港之后的第四大国际金融中心&nbsp; 。新加坡也是亚洲重要的服务和航运中心之一，被评为世界一线城市第五位&nbsp; 。新加坡是东南亚国家联盟成员国之一，也是世界贸易组织、英联邦以及亚洲太平洋经济合作组织成员经济体之一。</p>
            </div>
          </div>
        </div>

        <div id="002" class="bg-white p-3 my-5">
          <div class="row w-100">
            <div class="col-8 ">
              <h2>新加坡投资移民</h2>
              <hr>
              <p><strong>符合以下准则的申请人，有资格通过GIP计划申请为新加坡永久居民</strong></p>
              <p><strong><span class="fontstyle0">&nbsp;资深企业主 – 方案A、B 或 C</span></strong></p>
              <p>a) 您必须拥有不少于三年的创业、经商经历； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                b) 您所经营的公司1最近一年的营业额必须达到不少于新币两亿元，并且最近三年的年均营业额必须达到不少于新币两亿元； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                c) 如果您的公司属于私人所有2，您必须持有至少30%的股权； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                d) 该公司必须属于指定行业列表中至少一项商业领域。</p>
              <p><strong>&nbsp;<span class="fontstyle0">下一代企业主 – 方案A、B 或 C</span></strong></p>
              <p>a) 您的直系家族必须持有您申报本计划的公司不少于30%的股份，或是股东； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                b) 该公司最近一年的营业额必须达到至少新币五亿元，并且最近三年的年均营业额必须达到至少新币五亿元； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                c) 您必须是该公司的管理层 (例如：高管、董事会成员);&nbsp; <span style="color: #ff6600;"><strong>以及</strong></span><br>
                d) 该公司必须属于指定行业列表中至少一项商业领域。</p>
              <p><strong>快速增长企业创始人 – 方案A、B 或 C</strong></p>
              <p>a) 您必须是一家估值不少于新币五亿元的公司的创始人及个人股东之一； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                b) 该公司必须由的风险投资公司或私募股权公司投资；<span style="color: #ff6600;"> <strong>以及</strong></span><br>
                c) 该公司必须属于指定行业列表中至少一项商业领域。</p>
              <p><strong>家族理财办公室负责人 –&nbsp; 方案C</strong></p>
              <p>a)您必须拥有不少于五年的创业、投资或管理经历； <span style="color: #ff6600;"><strong>以及</strong></span><br>
                b)您必须拥有不少于新币两亿元的可投资资产净额。<br>
                (备注：可投资资产净额包括除房地产的所有金融资产，如银行存款、资本市场产品、集体投资计划、人寿保险保单及其他投资产品所缴的保费。 )</p>
              <p><strong> <span class="fontstyle0">投资方案<br>
</span></strong><br>
                <span style="color: #ff6600;"><strong>方案A&nbsp;</strong></span> 投资不少于新币两百五十万元建立新的商业实体或扩大现有的业务营运。</p>
              <p><strong>投资方案A评估标准：</strong><span style="color: #ff6600;"><strong><br>
</strong></span>1）方案A申请人必须提交一份详细的五年商业或投资计划书。其内容须依据申请书表格B中所列出的项目，包括预计就业人数、年度商业经费支出以及相关财务数据。评估范围将包括该计划书的可行性、您在该公司的职责范围、其公司的商务活动以及相应所创造的本地就业机会。 以及<br>
                2）您必须持有该公司至少30%的股权并且是该公司管理层的一员(例如：高管、董事会)； 以及<br>
                3）该公司必须属于指定行业列表中至少一项商业领域。</p>
              <p><span style="color: #ff6600;"><strong>方案B&nbsp;</strong> </span>投资不少于新币两百五十万元于主要投资新加坡企业的全球商业投资者计划基金。</p>
              <p><strong>投资方案B评估标准：<br>
              </strong>1）方案B评估范围将包括五年内商业计划的可行性、您在该公司的职责范围、其公司的商务活动、相应所创造的本地就业机会以及年度商业经费支出等。</p>
              <p><span style="color: #ff6600;"><strong>方案C&nbsp;</strong></span> 投资不少于新币两百五十万元于一家成立于新加坡的新或现有单一家族理财办公室。该办公室必须管理不少于新币两亿元的资产。</p>
              <p><strong>投资方案C评估标准：<br>
              </strong>3）方案C申请人必须提交一份详细的五年商业计划书。其内容须依据申请书表格B中所列出的项目，包括相应的预计就业人数，以及年度财务预测。该计划书必须注明您在单一家族理财室中的职能以及资产配置及投资策略，包括资产类别、投资行业和地理区域等</p>
              <p><strong>项目优势</strong></p>
              <p>极低税率：利于税务规划；<br>
                精英双语教育：中西结合，双语教育；<br>
                身份便利：可享全球多国免签；<br>
                福利健全：社会福利优厚健全；<br>
                汉语通行：华人世界无语言障碍；<br>
                贸易跳板：世界市场，机遇多多；<br>
                法制健全：完善法律保证资金安全；</p>
            </div>
            <div class="col-4">
              <div class="p-1" style="background-color: #e8f7ff; border: #d2eefc solid 1px;">
                <p><strong>简介</strong></p>
                <hr>
                <p>新加坡投资移民Global Investor Program（GIP）是由新加坡经济发展局推出的一项投资移民计划，让具备丰富经商经验，并有意通过新加坡扩展国际业务的企业创始人，通过该计划申办新加坡永久居留权，申办人的配偶及未婚子女（21周岁以下）可以作为附属申请人同时通过全球商业投资者计划获得永久居留权。</p>
                <p>签证类型：一步到位获移民身份</p>
              </div>
            </div>
          </div>
        </div>

        <div id="004" class="bg-white  p-3 my-4 text-center">
          <h2>新加坡移民流程图</h2>
          <hr>
          <p><span style="color: #ff6600;"><br>
</span>免费资格评估&nbsp;<span style="color: #ff6600;"><strong> →&nbsp; </strong></span>准备全部材料，递交申请<span style="color: #ff6600;">&nbsp; <strong>→&nbsp; </strong></span>参加新加坡经济发展局安排的面试<span style="color: #ff6600;">&nbsp;&nbsp;</span><strong><span style="color: #ff6600;">→&nbsp;</span> </strong>收到移民局颁发的原则批准函， 6个月内完成投资&nbsp;&nbsp; <span style="color: #ff6600;">→&nbsp;</span> 新加坡经济发展局确认投资证明文件，新加坡移民局颁发最终批准函&nbsp;<span style="color: #ff6600;"> &nbsp;</span><strong><span style="color: #ff6600;">→&nbsp;</span> </strong>登陆办理相关手续，激活新加坡永居身份</p>
          <p><span style="font-size: 8pt;"><strong>*</strong>目前平均办理周期约9~12个月，周期仅供参考，具体案例以实际办理为准</span></p>
        </div>
      </div>
    </div>

    <div style="background-color: #428bca;height: 12px">

    </div>
  </div>
</template>

<script>
export default {
  name: "singapore",
  data() {
    return {
      banner: [
        require('../assets/singapore/1.jpg'),
      ],
    }
  }
}
</script>

<style scoped>
h2, .h2 {
  font-size: 24px;
}
p, .p {
  font-size: 14px;
  margin-bottom: 15px;
  line-height: 24px;
}
.hot-nav .active {
  border-bottom: #0073b6 solid 4px;
  color: #0073b6;
}
.hot-nav a {
  color: #333;
  font-size: 16px;
  width: 125px;
  display: inline-block;
  height: 45px;
  line-height: 45px;
  border-bottom: #fff solid 4px;
  text-align: center;
}
.color999 {
  color: #999;
}

a {
  color: #428bca;
  text-decoration: none;
}

.hot-title {
  width: 320px;
  height: 50px;
  background-color: #e8f7ff;
  border: #d2eefc solid 1px;
  text-align: center;
  line-height: 50px;
  color: #428bca;
  font-size: 22px;
  font-weight: lighter;
}
</style>