import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'element-ui/lib/theme-chalk/index.css';
import httpApi from './api/http.js'
import store from './store'
import ElementUI from 'element-ui';
import LiveBox from "@/components/LiveBox";
document.title = "跃迁出国"
Vue.use(ElementUI);
Vue.prototype.httpApi = httpApi

Vue.config.productionTip = false

Vue.component('LiveBox',LiveBox)

import SwiperCore, {
  Autoplay,Pagination,Navigation
} from 'swiper';

// install Swiper modules

SwiperCore.use([Autoplay,Pagination,Navigation]);


new Vue({
  store,
  router,
  LiveBox,
  render: h => h(App),
}).$mount('#app')
