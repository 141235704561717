<template>
  <div class="d-flex flex-column align-items-center ">
    <img src="../assets/008.jpg" alt="" class="w-100">
    <div class="" style="color:#8E624B; font-size:40px; margin-top:80px; font-weight:bold;">
      跃迁出国
    </div>
    <div
        style="font-size:28px;color:#595757; border:solid 1px #A8A8A8; text-align:center; width:470px; padding:10px; margin:30px auto 0;">
      专注海外移民
    </div>
    <div class="text-center height-big my-4">
      跃迁出国（上海跃迁因私出入境服务有限公司）成立于2017年，是上海专业办理出国服务的机构。 <br>
      本公司提供一站式的出国业务办理服务，涵盖北美、欧洲、亚洲等各国的投资移民、海外资产配置、技术移民、出国留学等业务。 <br>

      跃迁出国拥有众多十年以上从业经验的优秀员工。成熟的服务流程体系和海内外默契配合，<br>让我们在申请、获签、登陆、续签、转永居、家庭团聚、子女海外教育、财富规划等各个环节都能够为您提供最专业的服务。 <br>

      成立至今，跃迁出国已帮助数千个家庭成功获得海外身份，为子女提供最优质的教育资源，为海外生活提供全方位服务。 <br>

      跃迁出国的咨询中心和文案中心均设在上海，同时在美国的旧金山、西班牙的巴塞罗那，日本的大阪等地设有分公司，负责当地移民申请，投后投前管理，落地安家服务等一应事宜。 <br>

    </div>
    <div class="w-100 d-flex justify-content-center py-5" style="background-color:#f8f8f8">
      <div class="w1180 d-flex align-items-center">
        <div class="col-4 p-5">
          <div class="text-center" style="font-size: 30px;color: #d0a15c">
            全球移民项目
          </div>
          <div class="text-center">
            Global Immigration
          </div>
          <div class="mt-5">
            跃迁出国拥有专业的移民顾问，多年来深耕全球移民领域，在北美和欧洲拥有自己的特色项目，获批率高，获批时间短，投资回报率高。
          </div>
        </div>
        <div class="col-8">
          <img src="../assets/AuoutUs/1.jpg" alt="" class="w-100">
        </div>
      </div>
    </div>
    <div class="position-relative my-4">
      <div class="position-absolute" style="top:-54px;right: 400px">
        <img src="../assets/AuoutUs/2.png" alt="" style="width: 16px">
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center" style="background-color: #1b1e2f">
      <div class="w1180 d-flex" style="padding: 90px 0 50px 0">
        <div class="col-5"></div>
        <div class="col-7 text-white">
          <div class="" style="font-size: 30px;color: #d0a15c">
            专业服务
          </div>
          <div class="">
            Professional Service
          </div>
          <div class="mt-5">
            跃迁出国以道德为基石，诚心、诚信待客，将客户利益放在第一位，始终以保护客户利益最大化为己任。跃迁出国的专业服务为客户移居海外全程保驾护航。
          </div>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <div class="position-absolute" style="top:-154px;right: 200px">
        <img src="../assets/AuoutUs/3.png" alt="" class="" style="width: 272px">
      </div>
    </div>
    <div style="height: 300px"></div>
    <div class="position-relative my-4">
      <div class="position-absolute" style="top:-54px;left: 400px">
        <img src="../assets/AuoutUs/2.png" alt="" style="width: 16px">
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center" style="background-color: #f2f2f2;margin-bottom: 70px;">
        <div class="w1180 d-flex py-5">
          <div>
            <img src="../assets/AuoutUs/4.png" alt="" style="width: 704.5px">
          </div>
          <div class="p-5">
            <div class="" style="font-size: 30px;color: #d0a15c">
              公司地址
            </div>
            <div class="">
              Our Office
            </div>
            <div class="mt-5">
              公司地址位于星荟中心T2大楼709室（上海市虹口区四川北路88号），临近苏州河畔，我们在这里等待您的光临。
            </div>
            <div class="mt-5" style="font-size: 16px;color: #d0a15c">
              垂询电话：137 0185 4273
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs"
}
</script>

<style scoped>

</style>