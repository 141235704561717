let list = [
    {
        title: "加拿大安省雇主担保项目，32天获批案例分享",
        date: "2021-07-20",
        img: require('@/assets/Cases/1.jpg'),
        content: "加拿大&#x1f1e8;&#x1f1e6;安省雇主担保项目安省试点EOI新政后首发成功案例，32天获批省提名！&#x1f44f;客户接近50岁，多年财务经验，有过加拿大陪读经历…",
        id: 18
    },
    {
        title: "加拿大安省雇主担保项目成功案例分享",
        date: "2021-07-20",
        img: require('@/assets/Cases/2.jpg'),
        content: "EOI新政后6/16首次获得邀请，14天获批省提名！\n" +
            "                    70后女性，工科硕士，多年市场经理经验。\n" +
            "                    2021.6.16被邀请；\n" +
            "                    2021.6.29 提交全档申请；\n" +
            "                    2021.7.14 获批提名… ",
        id: 17
    },
    {
        title: "为什么要办安提瓜护照？为公司业务，全球通行便捷，全球资产配置",
        date: "2018-08-24",
        img: require('@/assets/Cases/3.jpg'),
        content: "近来，常有投资人问为什么要办安提瓜护照？什么人群会选择办安提瓜护照？安提瓜护照的优势、用途等。 通过 近众多成功获得安提瓜护照的案例，小编将和大家具…",
        id: 16
    },
    {
        title: "让孩子去国际学校，A女士选择安提瓜护照",
        date: "2018-08-24",
        img: require('@/assets/Cases/4.jpg'),
        content: "近来，常有投资人问为什么要办安提瓜护照？什么人群会选择办安提瓜护照？安提瓜护照的优势、用途等。 通过 近众多成功获得安提瓜护照的案例，小编将和大家…",
        id: 15
    },
    {
        title: "错失香港购房移居，不再错过希腊购房移民！",
        date: "2018-04-19",
        img: require('@/assets/Cases/5.jpg'),
        content: "随着各国的移民门槛提高，很多在犹豫要不要移的人，错过了好的移民时机，有些国家及地区的移民政策不仅仅门槛提高了，甚至直接关停。就像当年通过购房办理香港…",
        id: 14
    },
    {
        title: "回老家还是移民希腊？这不是个问题！",
        date: "2018-03-23",
        img: require('@/assets/Cases/6.jpg'),
        content: "自述 我在上海一家大型的4A广告公司担任中层职位，日常工作繁忙，这个行业注定加班是家常便饭。今年年初，我正在公司彻夜奋战之时，圈里的朋友给我转…",
        id: 13
    },
    {
        title: "空气质量越来越严峻，为家人健康C先生办理美国投资移民",
        date: "2022-07-03",
        img: require('@/assets/Cases/7.jpg'),
        content: "我是企业的高级管理人员，由于工作的关系，我经常去美国出差，对这个国家的各个方面都比较了解。随着国内的食品安全及空气质量等问题越来越严峻，我决定为自己…",
        id: 12
    },
    {
        title: "为了女儿的发展选择美国投资移民",
        date: "2018-03-28",
        img: require('@/assets/Cases/8.jpg'),
        content: "我今年42岁，和丈夫一起在国内经商，公司情况还算不错。我有一个非常可爱的女儿，为了她能获得更自由全面的教育和生活环境，初中就送她去了美国念书，由我陪读…",
        id: 11
    },
    {
        title: "在美留学工作人士，父母赠与资金成功移民美国",
        date: "2019-05-16",
        img: require('@/assets/Cases/9.jpg'),
        content: "我今年30岁，和先生一起留学美国攻读硕士学位，几年读下来我们都对这里的学术环境和就业发展空间非常满意。毕业后我的先生很快找到了一份很不错的工作，并且幸…",
        id: 10
    },
    {
        title: "为企业扩张选择美国投资移民",
        date: "2018-11-20",
        img: require('@/assets/Cases/10.jpg'),
        content: "我今年46岁，开了一家国际交流机构，经常与海外各地学校、组织、公司等进行合作举办各类活动，促进人才交流。多年工作以来，我逐渐熟悉了海外的市场与教育环境… ",
        id: 9
    },
    {
        title: "为女儿的教育，我和先生选择全家移民美国",
        date: "2017-10-06",
        img: require('@/assets/Cases/11.jpg'),
        content: "我今年45岁，在上海一所高校任职快20年了，我的先生同样服务于教育行业，我们共同育有一个可爱的女儿。在孩子的教育问题上，我和先生很早就达成共识把女儿送到… ",
        id: 8
    },
    {
        title: "父母赠与的方式，成功办理美国投资移民",
        date: "2018-01-20",
        img: require('@/assets/Cases/12.jpg'),
        content: "我姓吕，今年21岁，目前在美国一所大学读书。我的父母都是大学教授，因此对我的教育非常重视。为了能让我受到更好的教育，拥有有更广阔的发展空间，在高一结束…",
        id: 7
    },
    {
        title: "卖房款作为投资来源，成功办理美国投资移民",
        date: "2017-06-20",
        img: require('@/assets/Cases/13.jpg'),
        content: "我是一家私营企业的人事经理。2013年春天，经过长时间的考虑后，我决定为全家办理移民。做出这个决定之前我们也做了一些筹备规划，了解了相关一些政策信息，在…",
        id: 6
    },
    {
        title: "为女儿毕业后方便留美，成功投资移民美国",
        date: "2019-02-02",
        img: require('@/assets/Cases/14.jpg'),
        content: "我的女儿在美国念的高中，现在是大学在读。由于女儿长期在美国生活和学习，已经适应美国的环境，并且有毕业后留在美国工作的打算，出于生活和工作便利性的考虑…",
        id: 5
    },
    {
        title: "外企高管成功获批I-526申请",
        date: "2017-12-08",
        img: require('@/assets/Cases/15.jpg'),
        content: "我曾留学于美国，一直就职于美资企业，与妻子相识相恋于美国，并在美国结婚、生下我们的大儿子。多年来的从业经验让我对美国有了深刻的了解。但是在美毕业后，…",
        id: 4
    },
    {
        title: "为妻子、儿子考量成功办理美国投资移民",
        date: "2017-10-09",
        img: require('@/assets/Cases/16.jpg'),
        content: "我年近50，马上就到知天命的年纪，心思也多放在家庭和孩子上面。我学历不算高，一心希望儿子能受到好的教育，也想给妻子更好的生活。朋友们的孩子都陆续出国念…",
        id: 3
    },
    {
        title: "为孩子教育，房产抵押成功办理美国投资移民",
        date: "2017-06-07",
        img: require('@/assets/Cases/19.jpg'),
        content: "我姓叶，今年43岁，目前是一名全职太太，儿子9岁，正在国内接受小学教育。为了儿子将来能够在更加自由和开放的环境里接受以后的中学和大学教育。于是就开始考虑…",
        id: 2
    },
    {
        title: "老客户，加拿大转美国投资移民，成功移民美国",
        date: "2017-05-30",
        img: require('@/assets/Cases/20.jpg'),
        content: "我姓芦，是一家大型日资企业的在华高管。2010年夏天的时候，我就已经朋友推荐，在跃迁办理了加拿大魁北克投资移民。由于当初属于魁北克旧例投资移民的后一批申…",
        id: 1
    },
]


export default list