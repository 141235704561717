<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/EB1A海报.jpg" alt="" class="w-100">
  <div class="w1180 d-flex flex-column align-items-center">
    <img src="../assets/eb1a/EB-1A-1.jpg" alt="" class="my-4 w1180">
    <img src="../assets/eb1a/EB-1A-2.jpg" alt="" class="my-4 w1180">
    <img src="../assets/eb1a/EB-1A-3.jpg" alt="" class="my-4 w1180">
    <img src="../assets/eb1a/EB-1A-4.jpg" alt="" class="my-4 w1180">


    <div class="d-flex flex-column align-items-center">

      <div class="d-flex justify-content-center" style="width: 1300px;">
        <router-link tag="div" :to="item.url" class="m-3" v-for="(item,index) in list2" :key="index" >
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large" v-html=" item.name "></strong>
              </p>
              <p v-html="item.content"> </p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <LiveBox/>
</div>
</template>

<script>
export default {
  name: "eb1a",
  data(){
    return {
      list2:[
        {
          name:"纳斯达克上市企业<br>Noodles & Company",
          content:"EB-5乡村项目 <br> 80万投资款 无排期 审批快",
          url:"/noodles",
          img : require('../assets/usa/改图21.jpg')
        },
        {
          name:"汉堡王EB-1C项目",
          content:"无排期 最快45天 一步到位全家永绿",
          url:"/burger_king",
          img : require('../assets/usa/改图22.jpg')
        },

      ],
    }
  }
}
</script>

<style scoped>
.txt-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: bottom;
  color: #fff;
}
.pic-box {
  cursor: pointer;
  position: relative;
}
.text-large {
  font-size: 24px;
}
.pic-box:hover {
  box-shadow: 0 0 10px #000;
}
</style>