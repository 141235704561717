<template>
  <div>
    <div class="d-flex py-2 w-100 nav-box justify-content-center align-items-end">
      <div class="logo " style="height: 80%;margin-right: 80px;"><img src="../assets/logo.png" alt="" height="100%"></div>
      <div class="m_m_tit" @click="goRouter('/')">首页</div>
      <div class="m_m_tit" @mouseover="changeNav(0)" @click="goRouter('/usa')">美国移民</div>
      <div class="m_m_tit" @mouseover="changeNav(1)" @click="goRouter('/europe')">欧洲移民</div>
      <div class="m_m_tit" @mouseover="changeNav(2)" @click="goRouter('/hot')">热门国家</div>
      <div class="m_m_tit" @mouseover="changeNav(3)" @click="goRouter('/passport')">全球护照</div>
      <div class="m_m_tit" @mouseover="changeNav(4)"  @click="goRouter('/AboutUs')">跃迁出国</div>
    </div>
    <div class="d-flex justify-content-center py-3">
      <div class="nav-item" v-for="item in nav[nowMenu]" :key="item.name" @click="goRouter(item.url)">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HeaderNav',
  data() {
    return {
      nowMenu: 1,
      nav: [
        [{
          name: "EB-5概述",
          url: "/eb5profile"
        }, {
        //   name: "EB5乡村项目—Noodle & Company",
        //   url: "/noodles"
        // }, {
          name: "EB-1C – 汉堡王连锁餐厅",
          url: "/burger_king"
        },{
          name: "EB-1A 美国杰出人才移民",
          url: "/eb1a"
        },
        ],
        [ {
          name: "葡萄牙",
          url: "/por"
        },{
          name: "西班牙",
          url: "/spainzt"
        },{
          name: "希腊",
          url: "/filiaestate"
        }, {
          name: "爱尔兰",
          url: "/irelandiip"
        }, {
          name: "马耳他",
          url: "/maltazt"
        },  {
          name: "英国",
          url: "/ukzt"
        },
        ],
        [{
          name: "加拿大",
          url: "/canadazt"
        }, {
          name: "日本",
          url: "/japan"
        }, {
          name: "新加坡",
          url: "/singapore"
        }, {
          name: "澳大利亚",
          url: "/australiazt"
        }, {
          name: "新西兰",
          url: "/nz"
        }],
        [ {
          name: "格林纳达",
          url: "/grenada"
        },
        //   {
        //   name: "土耳其",
        //   url: "/turkey"
        // },
          {
          name: "圣基茨",
          url: "/stkitts"
        }, {
          name: "安提瓜",
          url: "/antigua"
        }, {
        //   name: "多米尼克",
        //   url: "/Dominica"
        // }, {
          name: "瓦努阿图",
          url: "/vanuatu"
        }],
        [{
          name: "经典案例",
          url: "/cases"
        }, {
          name: "移民资讯",
          url: "/news"
        }, {
          name: "About Us",
          url: "/aboutUs"
        }]
      ]
    }
  },
  methods: {
    goRouter(i) {
      this.$router.push(i)
    },
    changeNav(id) {
      this.nowMenu = id
      console.log(this.nowMenu);
      this.$forceUpdate()
    }
  }
}
</script>
<style scoped>
.nav-item {
  margin: 0 20px;
  cursor: pointer;
}

.nav-item:hover {
  color: #428bca;
}

a {
  color: #FFFFFF;
}

.m_m_tit {
  width: 120px;
  color: #c8a063;
  cursor: pointer;
}

.m_m_tit:hover {
  color: #428bca;
}

.nav-box {
  height: 100px;
  background-color: rgba(0, 0, 0, .8);
}
</style>