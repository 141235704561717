<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/antigua/1.jpg" alt="" class="w-100">
    <div class="w-100 d-flex justify-content-center py-4" style="background-color: #f3f3f3">
      <img src="../assets/antigua/2.jpg" alt="" class="w1180">
    </div>
    <img src="../assets/antigua/3.jpg" alt="" class="">
    <div class="w-100 d-flex justify-content-center py-4" style="background-color: #f3f3f3">
      <img src="../assets/antigua/4.jpg" alt="" class="w1180">
    </div>
    <div class="w1180">
      <img src="../assets/antigua/5.jpg" alt="" class="my-4">


      <div class="row w-100">
        <div class="col-3">
          <img src="../assets/antigua/vsantigua_06.jpg" class="w-100">
          <div style="background-color:#182a80; height:250px; padding:20px 10px 0;" class="text-center">
            <h2 style="color:#b4814a;font-size:36px;">税务天堂</h2>
            <p class="text-white margin-large-top" style="font-size:18px;">安提瓜无遗产税及继承税<br>
              无个人所得税/资本利得税<br>
              非全球征税
            </p>
          </div>
        </div><!--x3 -->
        <div class="col-3">
          <div style="background-color:#182a80; height:250px; padding:20px 10px 0;" class="text-center">
            <h2 style="color:#b4814a;font-size:36px;">财富自由</h2>
            <p class="text-white margin-large-top" style="font-size:18px; line-height:2em;">
              富裕人群既希望自己的投资行为能带来更高更稳固的收益，也希望通过合理合法的方式，减少资产管理的成本。
            </p>
          </div>
          <img src="../assets/antigua/vsantigua_07.jpg" class="w-100">
        </div><!--x3 -->
        <div class="col-3">
          <img src="../assets/antigua/vsantigua_08.jpg" class="w-100">
          <div style="background-color:#182a80; height:250px; padding:20px 10px 0;" class="text-center">
            <h2 style="color:#b4814a;font-size:36px;">教育自由</h2>
            <p class="text-white margin-large-top" style="font-size:18px;">中国父母面临着<br>
              一类国际院校对身份的设限<br>
              北上广深等地的一流国际名校<br>
              有严格的国际身份要求
            </p>
          </div>
        </div><!--x3 -->
        <div class="col-3">
          <div style="background-color:#182a80; height:250px; padding:20px 10px 0;" class="text-center">
            <h2 style="color:#b4814a;font-size:36px;">出行自由</h2>
            <p class="text-white margin-large-top" style="font-size:18px;">安提瓜护照可免签出入<br>
              包括英国/申根26国在内的<br>
              150+国家
            </p>
          </div>
          <img src="../assets/antigua/vsantigua_09.jpg" class="w-100">
        </div><!--x3 -->
      </div>
    </div>

    <img src="../assets/antigua/6.jpg" alt="" class="">
    <div class="d-flex justify-content-center w-100 layout py-4">
      <div class="w1180 d-flex justify-content-between px-5 text-white">
        <div class="mx-5">
          <span style="font-size:26px;"><span style="font-size:60px;">20</span>万美元</span><br>
          购买房产或入股房产企业，5年后可出售
          <p style="margin-top:40px;">
            项目名称 安提瓜半月湾度假村<br>
            单位投资 20万美元<br>
            投资期限 5年无息返还<br>
            护照属性 英联邦护照
          </p>
        </div>
        <div class="mx-5">
          <span style="font-size:26px;"><span style="font-size:60px;">10</span>万美元</span><br>
          捐赠至国家发展基金
          <p style="margin-top:40px;">
            项目名称 安提瓜国家发展基金<br>
            单位投资 10万美元<br>
            投资期限 捐赠无返还<br>
            护照属性 英联邦护照
          </p>
        </div>
      </div>
    </div>
    <img src="../assets/antigua/8.jpg" alt="" class="my-4">
    <img src="../assets/antigua/9.jpg" alt="" class="my-4">
    <div class="w1180 overflow-hidden">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               style="width: 377px"
               v-for="(item,index) in list" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <div class="w1180">
      <img src="../assets/antigua/10.jpg" alt="" class="my-4">
    </div>
    <img src="../assets/antigua/11.jpg" alt="" class="my-4">
    <LiveBox/>
  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "antigua",
  data() {
    return {
      list: [
        require('../assets/antigua/vsantigua_s01.jpg'),
        require('../assets/antigua/vsantigua_s02.jpg'),
        require('../assets/antigua/vsantigua_s03.jpg'),
        require('../assets/antigua/vsantigua_s04.jpg'),
        require('../assets/antigua/vsantigua_s05.jpg'),
        require('../assets/antigua/vsantigua_s06.jpg'),
      ]
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop:true,
      autoplay:{
        delay:2000
      },
      slidesPerView: 4,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30
    });
  }
}
</script>

<style scoped>
.layout {
  background: url('../assets/antigua/7.jpg') no-repeat center center;
  background-size: cover;
}
</style>