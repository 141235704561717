var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/vanuatu/1.jpg"),"alt":""}}),_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/vanuatu/2.jpg"),"alt":""}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/vanuatu/8.jpg"),"alt":""}}),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('img',{attrs:{"src":require("../assets/vanuatu/15.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/vanuatu/16.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/vanuatu/17.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/vanuatu/18.jpg"),"alt":""}}),_c('LiveBox')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180 mt-4",staticStyle:{"background-color":"#5996b5"}},[_c('div',{staticClass:"col-3 p-0"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/vanuatu/vanuatu_02.jpg")}})]),_c('div',{staticClass:"col-6 text-white p-6"},[_c('img',{attrs:{"src":require("../assets/vanuatu/vanuatu_05.jpg")}}),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  所在洲：大洋洲")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  位置：太平洋西南侧，澳大利亚东侧")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  国土面积：约84万平方公里（83个岛屿）")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  首都：维拉港（Port Villa）")]),_c('img',{staticClass:"mt-4",attrs:{"src":require("../assets/vanuatu/vanuatu_06.jpg")}}),_c('div',[_c('span',{staticClass:"icon-circle text-white text-small"}),_vm._v("  英语、法语、比斯拉马语")])]),_c('div',{staticClass:"col-3 p-0"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/vanuatu/vanuatu_03.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180",staticStyle:{"background-color":"#2dc7cf"}},[_c('div',{staticClass:"col-9 p-0"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/vanuatu/vanuatu_07.jpg")}})]),_c('div',{staticClass:"col-3 text-white p-4 pt-5"},[_c('img',{staticClass:"mt-5",attrs:{"src":require("../assets/vanuatu/vanuatu_08.jpg")}}),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  约27万人")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  95%为原居民")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  其余为欧洲人丶其他太平洋岛民和亚洲人")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  瓦努阿图为基督教国家")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180",staticStyle:{"background-color":"#43acf0"}},[_c('div',{staticClass:"col-3 p-0"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/vanuatu/vanuatu_04.jpg")}})]),_c('div',{staticClass:"col-9 text-white p-4 pt-5"},[_c('img',{staticClass:"mt-5",attrs:{"src":require("../assets/vanuatu/vanuatu_09.jpg")}}),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  被国际组织四次(2006，2010，2013，2014)评选为“地球上最快乐的地方”；")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  在2009年世界银行的研究报告中被评为该地区“容易营商” (“Ease of Doing Business”)之最高评级（宽松的营商环境：体现在企业在当地遵循的政策法规下开设、经营、贸易、纳税、执行合约等方面所需的时间短，成本低）；")]),_c('div',[_c('span',{staticClass:"icon-circle"}),_vm._v("  2011年6月被国际经济合作与发展组织 (OECD) 评为国际“白名单”, “White List”成员（拥有透明的税收制度和合作的税收信息交换系统）。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 d-flex justify-content-end"},[_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/vanuatu/7.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 d-flex my-4"},[_c('div',{staticStyle:{"width":"90%"}},[_c('p',[_vm._v("瓦努阿图共和国，位于南太平洋西部，属热带海洋性气候，四季如夏，生态环境优美。 瓦努阿图政治稳定，实行一会民主制保障人权，尊重法制，实施普通法法律制度。四次被国际组织评为“全球快乐的国家”。 国家无个人入息税及公司所得税，企业增值税等，不会向公民在当地之外的收入、利息、赠与、财产及遗产等征税。被世界银行评为“容易营商”的高评级。被国际经济合作与发展组织 （OECD） 评为国际“白名单“国成员。 瓦努阿图护照含金量高，免签国家和地区超过100个，并且承认双重国籍。")]),_c('p',[_vm._v("瓦努阿图捐献计划(VCP)：于二零一六年十二月十九日瓦努阿图共和国已根据《国籍法第112章》第13D条正式立法通过《2016年第220号国籍条例（捐献计划）法令》。该法令制定了捐献计划(Contribution Program)的细则，透过捐献计划筹集资金并吸纳高质素的新移民，推动瓦国经济发展。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":require("../assets/vanuatu/9.jpg"),"alt":""}})]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('img',{attrs:{"src":require("../assets/vanuatu/10.jpg"),"alt":""}})]),_c('div',{staticClass:"x12 margin-large-top mt-4 px-4"},[_c('p',[_vm._v("1、年满18周岁（可携同配偶、18岁以下子女、18-25岁在读未婚子女和50岁以上父母提交申请。）;")]),_c('p',[_vm._v("2、无犯罪记录;")]),_c('p',[_vm._v("3、25万美金以上资产证明;")]),_vm._v("                    "),_c('p',[_vm._v("4、向瓦努阿图捐献计划捐款USD 80,000。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180 mt-4"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"d-flex justify-content-center w-100"},[_c('img',{attrs:{"src":require("../assets/vanuatu/11.jpg"),"alt":""}})]),_c('div',{staticClass:"mt-4"},[_c('p',[_vm._v("1、完善的法律基础，荣誉公民，地位显赫;")]),_c('p',[_vm._v("2、罕有机会，限额极少，瓦努阿图政府全力支持;")]),_c('p',[_vm._v("3、无需登录、无居住要求;")]),_c('p',[_vm._v("4、特快获批，约30天便可完成;")]),_c('p',[_vm._v("5、一人提名、三代同行;")]),_c('p',[_vm._v("6、无外汇管制、个人入息税、公司利得税、增值税和遗产税等;")]),_c('p',[_vm._v("7、英联邦及联合国成员国，护照免签全球124个国家和地区，包括英、法、德、意、西等40个欧洲国家。")])])]),_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":require("../assets/vanuatu/12.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-100 my-5 d-flex justify-content-center layout"},[_c('img',{attrs:{"src":require("../assets/vanuatu/14.png"),"alt":""}})])
}]

export { render, staticRenderFns }