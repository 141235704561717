<template>
  <div>
    <img src="../assets/fsno/001.png" alt="" class="w-100">

    <div class="container section01" style="margin-top: -70px;">
      <div class="d-flex">
        <div style="background-color: #8f1212;width: 2px;"></div>
        <div style="font-size: 24px;color: #8f1212;line-height: 35px;font-weight: bold;margin-left: 20px;">
          百年名门开发商
          <br>航母投资方护航
        </div>
      </div>
      <p style="margin-top: 80px; margin-bottom: 15px; line-height: 24px;">
        百年望族 -
        卡朋特(Carpenter)家族，携手比尔·盖茨家族办公室、四季酒店集团和中东某国家石油公司，对位于新奥尔良运河大街2号的原世贸中心进行整体性翻修，完工后1-18层、共计341套客房是四季酒店，19-33层是由四季酒店提供物业服务之高级私人住宅。目前，酒店已正式运营，住宅部分即将于2022年完工。</p>
      <p style="margin-bottom: 15px; line-height: 24px;">
        新奥尔良四季酒店拥有其他项目难以媲美的开发团队。无论是开发商，还是投资方均可为首屈一指。主开发商卡朋特家族系美国历史的开创者，而投资方更因比尔盖茨和沙特国家石油公司的加入显得熠熠生辉。</p>
      <div class="row mb-5">

        <div class="col-4">
          <img src="../assets/fsno/FSNO-0902-2.jpg" class="w-100">
        </div>
        <div class="col-4">
          <img src="../assets/fsno/FSNO-0902-3.jpg" class="w-100">
        </div>
        <div class="col-4">
          <img src="../assets/fsno/FSNO-0902-4.jpg" class="w-100">
        </div>
      </div>
    </div>
    <img src="../assets/fsno/002.jpg" alt="" class="w-100">

    <div class="d-flex justify-content-center my-5">
      <div class="d-flex" style="width: 1180px;">
        <div>
          <img src="../assets/fsno/003.jpg" alt="">
        </div>
        <div>
          <div class="items">
            <div class="item">
              <div class="arrow-tip"></div>
              <div class="content">
                <h2 class="color-8f1212">城市地标 </h2>
                <hr class="bg-8f1212">
                <p class="height-big">
                  新奥尔良是全美第二大海港城市<br>
                  四季酒店承袭了原世贸中心的地标特质<br>
                  更是对新奥尔良独立乐观和精神的致敬
                </p>
              </div>

            </div>
            <div class="item">
              <div class="arrow-tip"></div>
              <div class="content">
                <h2 class="color-8f1212">中心地段</h2>
                <hr class="bg-8f1212">
                <p class="height-big">距离两座机场均为20分钟车程，<br>
                  举步即达历史悠久的法国街区、仓库艺术区和公园绿地，<br>
                  咫尺之距就是新奥尔良有轨电车站，从容享受城市便利</p>
              </div>


            </div>
            <div class="item">
              <div class="arrow-tip"></div>
              <div class="content">
                <h2 class="color-8f1212">稳定客流</h2>
                <hr class="bg-8f1212">
                <p class="height-big">两个街区之外就是Ernest N. Morial会展中心<br>
                  作全美第六大会展中心，全美前十会展目的地<br>
                  全新奢华酒店无疑将是参展人员首选的下榻之地。</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex" style="margin-top:-260px">
      <div class="" style="width: 60%;"></div>
      <img src="../assets/fsno/004.png" width="300px" style="transform: translateY(294px);">
    </div>
    <div class="d-flex justify-content-center w-100">
      <div style="width: 1180px;">
        <div class="d-flex">
          <div style="background-color: #8f1212;width: 2px;"></div>
          <div style="font-size: 24px;color: #8f1212;line-height: 35px;font-weight: bold;margin-left: 20px;">
            百年名门开发商
            <br>航母投资方护航
          </div>
        </div>
      </div>

    </div>
    <div class="d-flex justify-content-center" style="background-color: #333a50;margin-top: 30px;padding: 20px;">
      <div class="row text-white" style="width: 1180px;">
        <div class="col-6 m-0" style="margin-left: 20px;font-size: 14px;line-height: 30px">
          四季酒店是一家国际性奢华酒店管理集团，总部设于加拿大多伦多，在47个国家拥有124家酒店及度假酒店。在中国，四季在北京、上海、广州、深圳、杭州等中心城市拥有多家酒店。四季酒店被《漫旅Travel+Leisure》杂志及Zagat指南等权威评论评为世界知名酒店集团。
        </div>
      </div>
    </div>


    <div class="my-4 d-flex flex-column align-items-center w-100">
      <img src="../assets/fsno/005.png" alt="" width="200">
      <h2 class="mt-3"><b>四季酒店尊贵服务与设施</b></h2>
      <h2><b>极致奢华的服务与居住体验</b></h2>
    </div>

    <div class="w-100 d-flex justify-content-center" style="margin-top: 80px;">
      <div style="width: 1180px;overflow: hidden;position: relative">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide d-flex flex-column align-items-center"
                 style="width: 377px"
                 v-for="(item,index) in list" :key="index">
              <img :src="item" class="w-100"/>
            </div>
          </div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
      </div>
    </div>


    <div class="d-flex justify-content-center mt-5">
      <div class="d-flex align-items-center" style="width: 1180px;">
        <div class="line-hr flex-grow-1"></div>
        <div class="line-text h1 color-8f1212 px-5"><strong>强强联手</strong></div>
        <div class="flex-grow-1"></div>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-5">
      <div class="d-flex align-items-center" style="width: 1180px;">
        <div class="flex-grow-1"></div>
        <div class="line-text h1 color-8f1212 px-5"><strong>演绎奢华</strong></div>
        <div class="line-hr flex-grow-1"></div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="d-flex justify-content-between " style="width: 1180px;">
        <div class=" border p-4" style="width: 48%;font-size: 14px;line-height: 30px">
          <div class="text-center text-bbbb">建筑设计公司</div>
          <div class="box-item">
            <strong>Cambridge Seven Associates</strong><br>
            1962年于波士顿创立<br>
            美国东海岸著名的建筑设计行
          </div>
          <div class="box-item"><strong>Trapolin-peer Architects</strong><br>
            于1981年创立至今<br>
            致力于新建筑和历史建筑的翻新和修复
          </div>
        </div>
        <div class=" border p-4" style="width: 48%;font-size: 14px;line-height: 30px">
          <div class="text-center text-bbbb">总承包商</div>
          <div class="box-item">
            <strong>Tishman</strong><br>
            铁狮门建筑，世界知名的建筑公司之一<br>
            124年的历史中负责建设了超过5亿平方英尺的空间
          </div>
          <div class="box-item"><strong>Woodward Design+Build</strong><br>
            新奥尔良本土设计师行+建筑行<br>
            以创新闻名，倡导“设计+建筑”一体化的运营模式
          </div>
        </div>
      </div>
    </div>

    <img src="../assets/fsno/006.jpg" alt="" class="w-100 my-5">

    <div class="d-flex align-items-center flex-column">
      <h2>项目总预算6.075亿美元</h2>
      <h2>EB-5优先股仅占总投资19.8%</h2>
      <div class="d-flex justify-content-center" style="width: 1180px;">
        <div style="width: 500px;" class="d-flex justify-content-center align-items-center">
          <table style="text-align: center;">
            <tbody>
            <tr>
              <td width="100">EB-5优先股</td>
              <td width="100">$1.200亿</td>
              <td width="100">19.8%</td>
            </tr>
            <tr style="border-top: dashed 1px #9f9f9f;border-bottom: dashed 1px #9f9f9f;">
              <td>开发商股权</td>
              <td>$2.077亿</td>
              <td>34.2%</td>
            </tr>
            <tr style="border-bottom: dashed 1px #9f9f9f;">
              <td>银行贷款</td>
              <td>$2.455亿</td>
              <td>40.4%</td>
            </tr>
            <tr>
              <td>其他权益</td>
              <td>$0.343亿</td>
              <td>5.6%</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div>
          <img src="../assets/fsno/007.jpg" alt="" class="" width="500px">
        </div>
      </div>

      <div class="d-flex justify-content-between mt-4" style="width: 1180px;">

        <div class="d-flex flex-column align-items-center item1">
          <div class="tit">优先股权<br>权益保障</div>
          <div class="text">
            开发商出资占比逾34%，并劣后于EB-5投资人的优先股，这充分确保的投资人的资本安全。
          </div>
        </div>
        <div class="d-flex flex-column align-items-center item1">
          <div class="tit">持股担保<br>安全无忧</div>
          <div class="text">
            开发商保证持有至少25%股权，以确保项目再融资和整体售出的利益动机，从而确保投资人的顺利推出。
          </div>
        </div>
        <div class="d-flex flex-column align-items-center item1">
          <div class="tit">退出机制<br>额外保障</div>
          <div class="text">
            如到期日， 开发商还未偿还EB-5投资款，EB-5优先股权可优先占用酒店的营业额，直到还清为止。
          </div>
        </div>
        <div class="d-flex flex-column align-items-center item1">
          <div class="tit">销售如虹<br>还款基础</div>
          <div class="text">
            新奥尔良四季酒店私人住宅部分，已经售出70%，酒店估值为3.66亿美金，为EB-5还款提供了充足的保障。
          </div>
        </div>
      </div>

      <div class="d-flex my-5" style="width: 1180px;">
        <div>
          <div class="d-flex">
            <div style="background-color: #8f1212;width: 2px;"></div>
            <div style="font-size: 24px;color: #8f1212;line-height: 35px;font-weight: bold;margin-left: 20px;">
              移民局模范认证
              <br>快速绿卡有保障
            </div>
          </div>
          <div class="mt-5">
            新奥尔良四季酒店及私人住宅项目已获美国移民局（USCIS）模范认证，无论是其就业机会创造模式，还是其退出机制，这充分确保了项目在商业层面和移民方面的安全性。
          </div>
        </div>
        <div>
          <img src="../assets/fsno/008.png" alt="" width="380">
        </div>
      </div>


    </div>
    <div class="layout"></div>

    <div class="d-flex justify-content-center my-5">
      <div class="d-flex justify-content-between" style="width:1180px">
        <div class="item2">
          <div class="tit mb-5">2.63倍就业机会
            <br>已经超额完成
          </div>
          <img src="../assets/fsno/010.jpg">
          <div class="tit color-8f1212">项目已创造4443个就业机会</div>
        </div>

        <div class="item2">
          <div class="tit mb-5">2022美国移民新法
            <br>窗口时机 政策红利
          </div>
          <p class="margin-large-top-fs">
            2022年3月15日，美国移民局通过了新移民法，这次修订是EB-5在过去30年来的一次重大突破，提出了“预留签证”的制度，其中城市高失业地区（TEA）拥有10%的预留签证。
          </p>
          <p class="margin-big-top">
            考虑到新法通过后，优质成熟的城市TEA 项目非常罕有，这无疑为新奥尔良四季酒店项目的投资人提供了绝佳的窗口时机，申请人将有很大机会免排期快速获得全家绿卡。
          </p>
          <img src="../assets/fsno/12.jpg"
               class="img-responsive aligncenter">
        </div>


      </div>
    </div>
    <div class="d-flex justify-content-center my-5">
      <div class="d-flex justify-content-between" style="width:1180px;background-color: #333a50">
        <div class="item2" style="background-color: #333a50">
          <div class="tit mb-5 text-white">新奥尔良四季酒店已经开业
            <br>10月房价逾6400元/晚
          </div>
          <img src="../assets/fsno/011.jpg">
        </div>

        <div class="item2" style="background-color: #333a50">
          <div class="tit mb-5 text-white">新奥尔良四季酒店已经开业
            <br>10月房价逾6400元/晚
          </div>
          <img src="../assets/fsno/012.jpg">
        </div>

      </div>
    </div>

    <LiveBox />
  </div>
</template>

<script>

import Swiper from "swiper";
import LiveBox from "@/components/LiveBox";

export default {
  name: "fsno",
  components: {LiveBox},
  data() {
    return {
      list: [
        require('../assets/fsno/slide_01.jpg'),
        require('../assets/fsno/slide_02.jpg'),
        require('../assets/fsno/slide_03.jpg'),
        require('../assets/fsno/slide_04.jpg'),
        require('../assets/fsno/slide_05.jpg'),
        require('../assets/fsno/slide_06.jpg'),
        require('../assets/fsno/slide_07.jpg'),
        require('../assets/fsno/slide_08.jpg'),
        require('../assets/fsno/slide_09.jpg'),
        require('../assets/fsno/slide_10.jpg'),
        require('../assets/fsno/slide_11.jpg'),
        require('../assets/fsno/slide_12.jpg'),
      ]
    }
  },
  mounted() {
    new Swiper('.swiper-container', {
      slidesPerView: 3,
      spaceBetween: 30,
      centeredSlides: true,
      loop: true,
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
    });
  }
}
</script>

<style scoped>
@import url("../../node_modules/swiper/swiper.min.css");

.item2 {
  background-color: #ececec;
  width: 580px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout {
  background-image: url("../assets/fsno/009.jpg");
  background-size: cover;
  height: 151px;
  margin-top: -200px;
}

.text {
  margin: 16px 0;
  font-size: 14px;
  line-height: 30px;
}

.tit {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  text-align: center;
}

.item1:nth-child(2n) {
  background-color: #323a51;
}

.item1 {
  width: 260px;
  background-color: #8f1212;
  color: #fff;
  padding: 30px;
}

td, th {
  padding: 0;
  vertical-align: middle;
  height: 44px;
}

.box-item {
  background-color: #333a50;
  text-align: center;
  margin-top: 25px;
  color: #fff;
  padding: 20px;
}

.text-bbbb {
  font-size: 24px !important;
  font-weight: bold;
  background-color: #fff;
  width: 150px;
  margin: -47px auto 0;
}

.h1 {
  font-size: 30px;
}

.line-hr {
  height: 2px;
  background-color: #8f1212;
}

.swiper-slide-active {
  transform: scale(1) !important;
}

.swiper-wrapper .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}

.color-8f1212 {
  color: #8f1212;
  font-weight: bold;
}

.bg-8f1212 {
  background-color: #8f1212;
}

h2 {
  font-size: 24px;
}

.items {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  font-size: 14px;
}

.items .item {
  display: flex;
  align-items: center;
}

.items .item .arrow-tip {
  width: 0;
  height: 0;
  border: 25px solid;
  border-color: transparent #ececec transparent transparent;
}

.items .item .content {
  background-color: #ececec;
  flex-grow: 1;
  padding: 30px 20px;
}
</style>