<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/hd6/1.jpg" alt="" class="w-100">
    <div class="w1180">
      <img src="../assets/hd6/3.jpg" alt="" class="w-100">
      <img src="../assets/hd6/4.jpg" alt="" class="w-100">
      <img src="../assets/hd6/5.jpg" alt="" class="w-100 mt-4">
      <img src="../assets/hd6/6.jpg" alt="" class="w-100">
      <img src="../assets/hd6/7.jpg" alt="" class="w-100 mt-4">
      <img src="../assets/hd6/8.jpg" alt="" class="w-100 mt-4">
      <img src="../assets/hd6/9.jpg" alt="" class="w-100 mt-4">
      <img src="../assets/hd6/10.jpg" alt="" class="w-100 my-5">
      <div class="row w-100">
        <div class="col-5 d-flex align-items-center flex-column">
          <img src="../assets/hd6/11.jpg" alt="" class=" mt-4">
          <p class="height-big">睿利集团(RELEVANT Group)，由资深房地产开发商Richard Heyman与Grant
            King领导管理的高端地产开发公司，是好莱坞乃至大洛杉矶地区知名的酒店开发商。睿利集团全球战略合作伙伴包括众多知名精品酒店和餐饮娱乐管理品牌，包括梦酒店集团、道(TAO)餐饮集团和Commune酒店集团。</p>
          <p class="height-big">项目移民基金管理方-好莱坞国际区域中心(Hollywood International Regional Center -
            HIRC)是睿利地产集团附属公司，是一所拥有丰富EB-5融资经验的成熟区域中心，负责管理该集团旗下之EB-5商业地产开发项目。目前已完成2亿美元的EB-5融资。</p>
        </div>
        <div class="col-7 p-0 row">
          <div class="col-4 ">
            <img src="../assets/hd6/12.jpg" alt="" class="w-100">
          </div>
          <div class="col-4 ">
            <img src="../assets/hd6/13.jpg" alt="" class="w-100">
          </div>
          <div class="col-4 ">
            <img src="../assets/hd6/14.jpg" alt="" class="w-100">
          </div>
          <div class="col-4 ">
            <img src="../assets/hd6/15.jpg" alt="" class="w-100">
          </div>
          <div class="col-4 ">
            <img src="../assets/hd6/16.jpg" alt="" class="w-100">
          </div>
          <div class="col-4 ">
            <img src="../assets/hd6/17.jpg" alt="" class="w-100">
          </div>
        </div>
        <img src="../assets/hd6/18.jpg" alt="" class="my-5">
      </div>
      <img src="../assets/hd6/19.jpg" alt="" class="my-4">

      <div class="w-100 overflow-hidden my-4">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide"
                 v-for="(item,index) in list" :key="index">
              <img :src="item" class=""/>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination2"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>

    <LiveBox/>

  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "hd6",
  data() {
    return {
      list: [
        require('../assets/hd6/hgdh6_s01.jpg'),
        require('../assets/hd6/hgdh6_s02.jpg'),
        require('../assets/hd6/hgdh6_s03.jpg'),
        require('../assets/hd6/hgdh6_s04.jpg'),
        require('../assets/hd6/hgdh6_s05.jpg'),
        require('../assets/hd6/hgdh6_s06.jpg'),
        require('../assets/hd6/hgdh6_s07.jpg'),
        require('../assets/hd6/hgdh6_s08.jpg'),
        require('../assets/hd6/hgdh6_s09.jpg'),
        require('../assets/hd6/hgdh6_s10.jpg'),

      ]
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      slidesPerView: 3,
      spaceBetween: 30,
      autoplay: {
        delay: 2000
      },
      centeredSlides: true,
      paginationClickable: true,
    });

  }
}
</script>

<style scoped>
@import url("../../node_modules/swiper/swiper.min.css");
</style>