<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/stkitts/1.jpg" alt="" class="w-100" style="margin-bottom:200px;">
    <div class="w-100 d-flex justify-content-center" style="background-color: #ededed">
      <div class="w1180 row py-5">
        <div class="col-6">
          <img src="../assets/stkitts/2.png" alt="" style="margin-top: -140px;">
        </div>
        <div class="col-6">
          <img src="../assets/stkitts/3.png" class="img-responsive">
          <p style="font-size:36px; margin-top:30px;">
            圣基茨·尼维斯——英联邦成员国
          </p>
          <p class="height-big margin-large-top">
            圣基茨·尼维斯位于东加勒比海背风群岛北部，为英联邦成员国之一，政治稳定，司法独立，社会安定。官方语言为英语，且有世界上历史悠久的投资移民项目。</p>

          <p class="height-big">圣基茨·尼维斯享有“加勒比海明珠”、“北美后花园”等声誉，其风景优美，气候宜人，是北美人度假、旅游、养老的天堂。人均
            GDP 高，居民生活水平较高、犯罪率极低。</p>

          <p class="height-big">2018年获彭博社评选圣基茨为最佳旅游地之一，酒店业蓬勃发展。2017 年圣基茨旅游业总收入为 GDP
            的 26.8%。并预计将以每年 5.2% 的增长速度，在2028年以前达到 GDP 的 34.3%。<br>
            数据来源:世界旅游协会（World Travel &amp; Tourism Council) 2018 报告
          </p>
        </div>
      </div>
    </div>
    <img src="../assets/stkitts/4.png" alt="" class="my-4">
    <img src="../assets/stkitts/5.png" alt="" class="my-4">
    <p class="margin-large-top" style="font-size:42px;    color: #7A6A58;">
      圣基茨公民身份六大优势
    </p>
    <img src="../assets/stkitts/6.jpg" alt="" class="my-4">
    <div class="w-100 d-flex flex-column align-items-center layout">
      <img src="../assets/stkitts/4.png" alt="" class="my-4">
      <img src="../assets/stkitts/8.png" alt="" class="my-4">
      <p class="margin-large-top" style="font-size:42px;    color: #BAA391;">
        去名人钟爱的加勒比度假胜地投资，你能得到什么?
      </p>
      <div class="my-4 text-white w1180 height-big">
        一望无际的海滩、得天独厚的地理位置和热带美景，使圣基茨和尼维斯成为欧美明星与富豪的度假首选胜地，莱昂纳多•迪卡普里奥、史泰龙、布兰妮、奥普拉等国际名人都曾到此度假。圣基茨还被加勒比媒体评为“年度创新旅游胜地”，并获得“加勒比旅游大奖”。
      </div>
      <div class="row w1180 my-4">
        <div class="col-3">
          <img src="../assets/stkitts/a2.png" alt="">
        </div>
        <div class="col-3">
          <img src="../assets/stkitts/a4.jpg" alt="">
        </div>
        <div class="col-3">
          <img src="../assets/stkitts/a3.jpg" alt="">
        </div>
        <div class="col-3">
          <img src="../assets/stkitts/a1.jpg" alt="">
        </div>
      </div>
    </div>

    <img src="../assets/stkitts/4.png" alt="" class="my-4">
    <img src="../assets/stkitts/5.png" alt="" class="my-4">
    <p class="margin-large-top" style="font-size:42px;    color: #7A6A58;">
      圣基茨护照投资计划
    </p>
    <div class="my-4 height-big " style="text-align:center;">
      圣基茨1984年设立护照投资移民计划，为世界上最早开展此类项目的国家之一。<br>
      政府旨在通过高素质投资者为本国带来大量投资，促进该国发展。
    </div>
    <div class="row w1180 align-items-stretch my-4">
      <div class="col-6 d-flex">

        <div class="flex-shrink-0 d-flex justify-content-center align-items-center"
             style="background-color:#ff3e40; color:#fff; font-size:24px; width: 75px;">
          申<br>请<br>条<br>件<br>
        </div>
        <div class="ps-2" style="line-height:40px;">
          1. 主申请人的配偶;<br>
          2. 主申请人或主申请人配偶的 30 周岁以下未婚全日制在读子女;<br>
          3. 与主申请人或主申请人配偶共同生活并由其全力赡养的 55 周岁以上的父母、祖父母、外祖父母。
        </div>
      </div>
      <div class="col-6 d-flex">
        <div class="h-100 flex-shrink-0 d-flex justify-content-center align-items-center"
             style="background-color:#ff3e40; color:#fff; font-size:24px; width: 75px;">
          投<br>资<br>要<br>求
        </div>
        <div class="ps-2" style="line-height:40px;">
          向可持续发展基金捐赠15万美元;<br>
          家庭成员4人以下：15万美元;<br>
          每多加1人：1万美元/人。
        </div>
      </div>
    </div>
    <div class="my-4 height-big" style="text-align:center;">
      家庭人员在四口以下的家庭，捐赠要求从19.5万美元降至15万美元，可省下4.5万美元。<br>优惠的时间从2020年7月3日起正式生效，2020年12月31日截止。
    </div>
    <img src="../assets/stkitts/9.jpg" alt="" class="w-100">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "stkitts"
}
</script>

<style scoped>
.layout {
  background: url('../assets/stkitts/7.jpg') no-repeat center center;
  background-size: cover;
}

</style>