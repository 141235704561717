<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/allen/1.jpg" alt="" class="w-100">
  <div class="w1180 d-flex justify-content-end layout my-4">
    <div class="p-4 text-white" style="width:50%">
      <p>休斯顿是全美第四大城市，经济增长迅猛，是美国南部沿岸的经济中心。拥有国际性的文化和持续增长的多元化人口。不断扩张而又充满活力，休斯顿的每一个角落都充满机遇。</p>
      <p>便捷宜居<br>休斯顿大都会区陆上交通便利，公共设施完善，生活成本低廉</p>
      <p>经济强劲<br>休斯顿大都会区GDP 5254亿美元，排名全美第四，人均收入远超全美平均水平</p>
      <p>国际交流<br>每年举办超过360场展会、活动和展览，接待访客1750万</p>
      <p>高等教育<br>莱斯大学，美国著名私立学府，被誉为“南方哈佛”</p>
    </div>
  </div>

  <div class="w1180 row">
    <div class="col-6 text-white">
      <div style="width:449px;margin:0 auto;background-color:#006851;">
        <img src="../assets/allen/allen_02.jpg" class="img-responsive aligncenter">
        <div style="width:90%;padding-left:20px;padding-bottom:10px;padding-top:10px;height:133px;">
          •&nbsp;&nbsp;世界能源之都<br>•&nbsp;&nbsp;众多能源巨头总部所在地<br>•&nbsp;&nbsp;爱克森美孚、壳牌、中国石化等设立园区
        </div>
      </div>
    </div>
    <div class="col-6 text-white">
      <div style="width:449px;margin:0 auto;background-color:#006851;">
        <img src="../assets/allen/allen_03.jpg" class="img-responsive aligncenter">
        <div style="width:90%;padding-left:20px;padding-bottom:27px;padding-top:10px;">
          休斯顿港<br>•&nbsp;&nbsp;国际运输量全美第一<br>•&nbsp;&nbsp;国际运输货物价值全美第二<br>•&nbsp;&nbsp;总运输量全美第二
        </div>
      </div>
    </div>
    <div class="col-6 mt-4 text-white">
      <div style="width:449px;margin:0 auto;background-color:#006851;">
        <img src="../assets/allen/allen_04.jpg" class="img-responsive aligncenter">
        <div style="width:90%;padding-left:20px;padding-bottom:10px;padding-top:10px;">
          NASA约翰逊太空中心<br>•&nbsp;&nbsp;NASA的航天任务控制总部<br>•&nbsp;&nbsp;美国宇航员的选拔训练中心<br>•&nbsp;&nbsp;代表美国，领导国际空间站计划
        </div>
      </div>
    </div>
    <div class="col-6 mt-4 text-white">
      <div style="width:449px;margin:0 auto;background-color:#006851;">
        <img src="../assets/allen/allen_05.jpg" class="img-responsive aligncenter">
        <div style="width:90%;padding-left:20px;padding-bottom:10px;padding-top:10px;">
          •&nbsp;&nbsp;德州医疗中心：<br>&nbsp;&nbsp;&nbsp;全球知名医疗中心<br>•&nbsp;&nbsp;MD安德森癌症中心：<br>&nbsp;&nbsp;&nbsp;连续8年美国癌症研究医院评比中排名第1
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex w-100 justify-content-center" style="background-color: #bdc7c9">
    <div class="w1180 position-relative">
      <img src="../assets/allen/3.jpg" alt="">
      <div class="position-absolute" style="bottom: 0;height: 180px;width: 330px;left: 70px">
        休斯顿爱伦中心I期 · 豪庭总高38层，包括100套豪华独立产权公寓、180间汤普森酒店客房、生活时尚中心及精品零售&餐厅。项目坐落于休斯顿市美丽的Allen Parkway，处于市中心、富豪区河畔橡园和知名的购物商圈Galleria之间的交汇点。
      </div>
    </div>
  </div>
  <img src="../assets/allen/4.jpg" alt="" class="my-4">
  <div class="w1180">
    <div class="w-100 overflow-hidden my-4">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide"
               v-for="(item,index) in list" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination2"></div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
      </div>
    </div>
  </div>

  <img src="../assets/allen/5.jpg" alt="" class="my-4">
  <div class="d-flex flex-column align-items-center my-4 w-100" style="background-color: #016952">
      <img src="../assets/allen/6.jpg" alt="" class="my-4">
      <img src="../assets/allen/7.jpg" alt="" class="my-4">
  </div>
  <img src="../assets/allen/8.jpg" alt="" class="">
  <div class="w1180 row align-items-center my-4">
    <div class="col-2">
      <img src="../assets/allen/9.jpg" alt="" class="">
    </div>
    <div class="col-10">
      <p>DC Partners 总部位于休斯顿，是一家专注于房地产开发与投资的专业公司。深厚的本地人脉、丰富的开发经验以及对卓越品质的不懈追求激励着我们不断执着向前。<br>DC Partners已成功开发及正在开发的物业类型包括高层奢华产权公寓、出租型公寓、大型商业综合体、酒店、写字楼等，总价值超过25 亿美元。<br>DC Partners 运营自己的区域中心，融资金额高达近亿美元，并保持条件绿卡和永久绿卡成功记录。</p>
    </div>
  </div>
  <img src="../assets/allen/10.jpg" alt="" class="">
  <div class="w1180 row align-items-center my-4">
    <div class="col-2">
      <img src="../assets/allen/11.jpg" alt="" class="">
    </div>
    <div class="col-10">
      <p>天庆房地产开发集团是中国房地产百强企业，开发物业面积超过350万平方米，创造就业岗位超过5200余个。所开发项目从高端商业项目到大型住宅项目、酒店及零售、学校以及文化产业基地等。
        天庆集团秉持国内成功房地产开发经验，迅速进军北美市场，天庆集团在2013年将业务拓展至加拿大，总投资额超过5亿加币。2015年天庆集团进军美国市场，首个项目落地休斯顿，代表着对休斯顿这座城市和战略合作伙伴DC Partners充满信心。</p>
    </div>
  </div>
  <img src="../assets/allen/12.jpg" alt="" class="">
  <LiveBox/>
</div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "allen",
  data() {
    return {
      list: [
        require('../assets/allen/allen_1.jpg'),
        require('../assets/allen/allen_2.jpg'),
        require('../assets/allen/allen_3.jpg'),
        require('../assets/allen/allen_4.jpg'),
        require('../assets/allen/allen_5.jpg'),
        require('../assets/allen/allen_6.jpg'),
        require('../assets/allen/allen_7.jpg'),
        require('../assets/allen/allen_8.jpg'),
        require('../assets/allen/allen_9.jpg'),
        require('../assets/allen/allen_110.jpg'),


      ]
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      slidesPerView: 4,
      spaceBetween: 30,
      autoplay: {
        delay: 2000
      },
      centeredSlides: true,
      paginationClickable: true,
    });

  }
}
</script>

<style scoped>
.layout{
  background: url('../assets/allen/2.jpg') no-repeat center center;
  background-size: cover;
}

</style>