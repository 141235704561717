<template>
  <div>

    <div class="d-flex flex-column align-items-center">
      <div class="line w1180"><h1 class="text-center height-large">多米尼克护照投资</h1>
        <p class="height-big">多米尼克位于加勒比海小安的列斯群岛中向风群岛的北端，南隔马提尼克海峡与马提尼克岛相望，北隔多米尼克海峡与瓜德罗普岛为邻。多米尼克是全球的离岸金融中心和税务天堂，对公民的海外收益<sup>*</sup>零税收，无资本利得税、净资产税、海外收益<sup>*</sup>税、遗产税、赠与税、房产税等，是一个非全球征税的国家！
        </p>
        <div class="line-big padding-big-right">
          <p><strong>投资方式</strong></p>
          <p>1、主申请人年满18周岁;<br>
            2、无犯罪记录;<br>
            3、投资10万美金起</p>
          <p><strong>项目优势</strong></p>
          <p>1、北美低额护照：单人申请只需10万美金，一步到位获得北美护照<br>
            2、条件宽松，无需居住：申请材料简单，无语言、无学历等要求，无需登陆多米尼克，资金来源无需证明<br>
            3、一步到位，通行全球：免签125个国家，包括爱尔兰、德国等欧盟国家以及英国等<br>
            4、办理周期快，直接拿护照：快至21个工作日获得批复，38个工作日拿到护照<br>
            5、政策稳定，保障申请人权益：项目在1993年写进国家宪法，法案担保一次申请终身有效，先批复，后捐款，保证申请人资金安全<br>
            6、离岸金融，避税天堂：轻松境外开设账户，公司上市，离岸金融中心，海外资产零税收，全球避税天堂</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dominica",
  data() {
    return {
      banner: [
        require('../assets/turkey/1.jpg'),
      ],
    }
  }
}
</script>

<style scoped>

</style>