<template>
  <div>
    <div class="text-center text-white py-3" style="background-color:#0C845C; font-size:22px;">
      <a href="#000" target="_self" class=" text-white">项目优势</a> |
      <a href="#001" target="_self" class=" text-white">国家优势</a> |
      <a href="#003" target="_self" class=" text-white">优教育高福利</a> |
      <a href="#tiaojian" target="_self" class=" text-white">申请条件 </a> |
      <a href="#liucheng" target="_self" class=" text-white">申请流程</a>
    </div>
    <div class="layout d-flex flex-column align-items-center py-5">
      <img src="../assets/irelandiip/3.png" alt="" class="my-3">
      <img src="../assets/irelandiip/4.png" alt="" width="1180">
    </div>
    <div class="layout1 d-flex flex-column align-items-center py-5" id="001">
      <div class="container bg-white" style="padding-top:50px; padding-bottom:50px;width: 1180px">
        <div class="x12 margin-large-bottom text-center layout3">
          <span style="color:#0C845C;">爱尔兰</span>-欧洲的 <span style="color:#ECB000;">“加拿大”</span>
          <div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <div class="m-3 item4">
            <img src="../assets/irelandiip/ierlan814_04.jpg" class="img-responsive aligncenter" style="">
            <div class="onebox">
              <div class="onebox-tit">
                爱尔兰的审批速度更快·流程更简单
              </div>
              <div class="onebox-content">
                爱尔兰投资移民项目，是经爱尔兰移民局核准的投资移民计划。与美国和加拿大的移民申请相比，爱尔兰的审批速度更快（仅需4-6个月即可获批）、流程更简单、文件要求更宽松、维持身份所需满足的居住要求更低（每年仅需登陆一次即可）。
              </div>
            </div>
          </div>

          <div class="m-3 item4">
            <img src="../assets/irelandiip/ierlan814_05.jpg" style="">
            <div class="onebox">
              <div class="onebox-tit">
                获得爱尔兰Stamp4居住签证·高品质教育
              </div>
              <div class="onebox-content">
                通过爱尔兰投资移民，可直接获得爱尔兰Stamp 4居民签证，子女可直接享受本地高品质的义务教育；相较之下，英国Tier
                1移民要求投资人在英维持投资或运营企业，且持续经营5年，这对以子女教育作为主要移民目的的中国投资人而言，无疑更加可行且便捷。
              </div>
            </div>
          </div>


          <div class="m-3 item4">
            <img src="../assets/irelandiip/ierlan814_06.jpg" class="img-responsive aligncenter" style="">
            <div class="onebox">
              <div class="onebox-tit">
                爱尔兰是世界第六大英语国家
              </div>
              <div class="onebox-content">
                爱尔兰是世界第六大英语国家，子女可享纯英式的精英教育。爱尔兰之于英国，就如同加拿大之于美国。且爱尔兰被誉为欧洲硅谷，众多跨国公司总部皆设于此。其杰出的教育资源和高品质就业机会，使其远超其他欧洲国家，更适合未来长期居住和发展。
              </div>
            </div>
          </div>

          <div class="m-3 item4">
            <img src="../assets/irelandiip/ierlan814_07.jpg" class="img-responsive aligncenter" style="">
            <div class="onebox">
              <div class="onebox-tit">
                爱尔兰投资移民具备“安全性”和“可行性”
              </div>
              <div class="onebox-content">
                爱尔兰投资移民要求投资人在获得批准后，才需完成投资，相较澳大利亚及新西兰投资移民，投资额合理，易于操作。可以说，爱尔兰是结合“安全性”和“可行性”为一体的投资移民项目。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img class="my-4" src="../assets/irelandiip/7.jpg" alt="">
      <img src="../assets/irelandiip/8.jpg" alt="">
    </div>

    <div class="layout4 d-flex justify-content-center ">
      <div class="d-flex align-items-start" style="width: 1180px;">
        <div class="flex-grow-1  py-4">
          <img src="../assets/irelandiip/10.png" alt="" width="100%">
        </div>
        <div class="p-5"
             style="width: 50%;background-color: rgba(16, 10, 91, .9);border-bottom: 5px #ecb000 solid">
          <p class="height-big text-white mb-3">
            爱尔兰气候温和湿润，为典型温带海洋性气候。受北大西亚暖流的影响，爱尔兰气候平稳，全国气温基本一致。
          </p>
          <img src="../assets/irelandiip/11.png" class="w-100">
        </div>
      </div>
    </div>

    <div class="layout5 d-flex  justify-content-center py-5" id="003">
      <div class="d-flex flex-column align-items-center" style="width: 1180px;">
        <img src="../assets/irelandiip/14.png" alt="">
        <p class="mt-4">爱尔兰非常重视教育，政府将其作为重中之重。在2019 年IMD
          世界竞争力排名中，爱尔兰教育系统排名世界第七。</p>
        <p class="mt-4">爱尔兰政府向所有爱尔兰居民提供免费的小学和初高中教育，通过投资移民获得Stamp
          4签证的适龄子女可享受这一福利。同时，在爱尔兰完成最后三年高中教育的Stamp
          4签证持有人就读大学时按欧盟生标准享学费优惠待遇。</p>

        <div class="row mt-5">
          <div class="col-4">
            <img src="../assets/irelandiip/ireland_22.png" class="img-responsive aligncenter">
            <div class="padding-left padding-right" style="color:#273C68;">
              <h3 class="mt-3">
                <strong>圣三一学院</strong>
              </h3>
              <span>
						Trinity College Dublin
					</span>
              <hr style="width:100px; height:0px;border:none;border-top:2px solid #3E3A39;opacity: 1">
              <p class="margin-large-top" style="color:#231815;">
                2017/18QS 世界大学排名中位居全球第88名
              </p>
            </div>
          </div>
          <div class="col-4">
            <img src="../assets/irelandiip/ireland_23.png" class="img-responsive aligncenter">
            <div class="padding-left padding-right" style="color:#273C68;">
              <h3 class="mt-3">
                <strong>爱尔兰国立梅努斯大学</strong>
              </h3>
              <span>
						National University of Ireland, Maynooth
					</span>
              <hr style="width:100px; height:0px;border:none;border-top:2px solid #3E3A39;opacity: 1">
              <p class="margin-large-top" style="color:#231815;">
                曾出过2位诺贝尔文学奖得主
              </p>
            </div>
          </div>
          <div class="col-4">
            <img src="../assets/irelandiip/ireland_24.png" class="img-responsive aligncenter">
            <div class="padding-left padding-right" style="color:#273C68;">
              <h3 class="mt-3">
                <strong>都柏林大学</strong>
              </h3>
              <span>
						University College Dublin
					</span>
              <hr style="width:100px; height:2px;background-color: #3E3A39;opacity: 1">
              <p class="margin-large-top" style="color:#231815;">
                其思摩菲特商学院全球知名
              </p>

            </div>
          </div>
        </div>

        <div class="text-center text-white text-big my-4">
          <p style="font-size:24px; color:#3E3A39;">知名跨国企业在爱尔兰的雇佣人数</p>
        </div>

        <div class="line-big w-100">
          <div class="p-3 text-white text-center text-big" style="background-color:#004832;">
            <table style="width:100%;">
              <tbody>
              <tr>
                <td class="border-right">
                  苹果<br>6,000+
                </td>
                <td class="border-right">
                  谷歌<br>7,000+
                </td>
                <td class="border-right">
                  Facebook<br>4,000+
                </td>
                <td class="border-right">
                  领英<br>1,200+
                </td>
                <td class="border-right">
                  英特尔<br>4,900+
                </td>
                <td class="border-right">
                  花旗银行<br>7,000+
                </td>
                <td class="border-right">
                  辉瑞<br>3,200+
                </td>
                <td>
                  Paypal<br>2,400+
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>

    </div>

    <div class="d-flex justify-content-center py-5">
      <div class="d-flex flex-column align-items-center" style="width: 1180px;">
        <img src="../assets/irelandiip/ierlan814_81.png" alt="">
        <div class="mt-4 text-center text-big height-big">
          从国土面积而言，爱尔兰如同欧洲许多富裕国家一样，是一个精品小国，但随着国际企业投资的纷至沓来，近年来爱尔兰已成为欧洲富裕的国家之一。<br>
          根据国际货币基金组织公布数据显示，2019年爱尔兰人均GDP为77,160美元，全球国家排名第五。远高于美国（65,062美元）、<br>
          澳大利亚（57,204美元）、德国（49,692美元）、法国（43,500美元）、英国（42,036美元）等国家平均水平。
        </div>

        <div class="w-100 mt-4" style="position:relative; height:650px;">
          <div class="item5" style="left:58px; top:55px;"  >
            <img src="../assets/irelandiip/ierlan814_28.png"
                 class="img-responsive aligncenter radius-circle">
            <div class="circlebox" >
              <div class="circlebox-content">
                <br>婴幼儿<br>享受国家免费健康发展检查<br>及疫苗接种；
                6岁以下儿童享受免费医疗福利；
                孕期妇女可享受26周产假，每周245欧元补贴。
              </div>
            </div>
          </div>

          <div class="item5" style="left:451px; top:0;">
            <img src="../assets/irelandiip/ierlan814_27.png" class="img-responsive aligncenter">
            <div class="circlebox" >
              <div class="circlebox-content">
                DPS药物卡：<br>
                一个家庭每月最多花费
                144欧元；
                爱尔兰政府对一个孩子的家庭每月补助140欧元；
                两个孩子280欧元，以此类推，直至孩子年满18周岁
              </div>
            </div>
          </div>
          <div class="item5" style=" left:844px; top:55px;">
            <img src="../assets/irelandiip/ierlan814_29.png" class="img-responsive aligncenter">
            <div class="circlebox" >
              <div class="circlebox-content">
                66周岁以上老人<br>可申请免费交通卡，享受免费境内交通；
                70周岁以上老人可减免水电生活费；
                70周岁以上老人免费发放医疗卡，享受免费公共医疗
              </div>
            </div>
          </div>
          <div class="item5" style="left:620.5px; top:350px;">
            <img src="../assets/irelandiip/ierlan814_30.png" class="img-responsive aligncenter">
            <div class="circlebox red">
              <div class="circlebox-content">
                <br>
                欧盟境内医疗卡(EHIC)：<br>适用于所有欧盟国家的医疗卡。
                前往英国境内短期访问的爱尔兰公民，无需此卡，即可获得医疗保障。
              </div>
            </div>
          </div>
          <div class="item5" style="left:288.5px; top:350px;">
            <img src="../assets/irelandiip/ierlan814_31.png" class="img-responsive aligncenter">
            <div class="circlebox red">
              <div class="circlebox-content">
                <br>
                需长期服药住院的病人<br>公立医院住院费：80欧/天，800欧/年；
                长期疾病医疗计划：
                对于特殊疾病但无医疗卡的公民，
                可免费获得药物和医疗器材。
              </div>
            </div>
          </div>
          <div style="width:130px; height:0px; border-top:dashed 1px #000;transform: rotate(-30deg);
-ms-transform: rotate(-30deg);
-webkit-transform: rotate(-30deg);
-o-transform: rotate(-30deg);
-moz-transform: rotate(-30deg); position:absolute; left:327px; top:180px	"></div>
          <div style="width:130px; height:0px; border-top:dashed 1px #000;transform: rotate(30deg);
-ms-transform: rotate(30deg);		/* IE 9 */
-webkit-transform: rotate(30deg);	/* Safari and Chrome */
-o-transform: rotate(30deg);		/* Opera */
-moz-transform: rotate(30deg); position:absolute; left:721.5px; top:179px	"></div>
          <div style="width:127px; height:0px; border-top:dashed 1px #000;transform: rotate(30deg);
-ms-transform: rotate(30deg);		/* IE 9 */
-webkit-transform: rotate(30deg);	/* Safari and Chrome */
-o-transform: rotate(30deg);		/* Opera */
-moz-transform: rotate(30deg); position:absolute; left:214.5px; top:350px	"></div>
          <div
              style="width:56px; height:0px; border-top:dashed 1px #000;position:absolute; left:563.5px; top:492px	"></div>
          <div style="width:96px; height:0px; border-top:dashed 1px #000;transform: rotate(-40deg);
-ms-transform: rotate(-40deg);		/* IE 9 */
-webkit-transform: rotate(-40deg);	/* Safari and Chrome */
-o-transform: rotate(-40deg);		/* Opera */
-moz-transform: rotate(-40deg); position:absolute; left:832.5px; top:346px	"></div>
        </div>
      </div>
    </div>

    <div class="layout6 d-flex justify-content-center">
      <img src="../assets/irelandiip/15.png" alt="" width="1180">
    </div>
    <div class="d-flex justify-content-center py-4" style="background-color: #e6eaec">
      <div class="d-flex flex-column align-items-center" style="width: 1180px;">
        <img src="../assets/irelandiip/17.png" alt="">
        <div class="d-flex justify-content-between py-5">
          <div class="">
            <img src="../assets/irelandiip/18.png" alt="">
          </div>
          <div class="ms-4" style="width:43%">
            <img src="../assets/irelandiip/19.jpg" alt="" class="w-100">
            <div style="background-color:#E1EFF4; padding:30px;">
              <h2>
                投资概要
              </h2>
              <p class="text-big height-big margin-large-top">
                为了吸引爱尔兰境内投资和稳固爱尔兰国际化经济体国家之一的地位，爱尔兰政府于2012年7月推出爱尔兰投资居留计划（简称IIP），非欧盟国家和地区的投资者可通过此计划获得在爱尔兰生活和发展的机会。
              </p>
            </div>
          </div>
        </div>
        <div class="my-5" id="tiaojian">
          <h2 style="color:#273C68; font-size:24px;">
            -申请要求-
          </h2>
        </div>
        <img src="../assets/irelandiip/20.png" alt="">
        <div class="my-5" id="liucheng">
          <h2 style="color:#273C68; font-size:24px;">
            -申请流程-
          </h2>
        </div>
        <img src="../assets/irelandiip/21.png" alt="">
      </div>
    </div>

    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "irelandiip",
  components: {}

}
</script>

<style scoped>

.item5{
  width:279px; height:278px; position:absolute;
}
.item5:hover .circlebox{
  display: block;
}
.circlebox.red{
  background: url(../assets/irelandiip/ierlan814_69.png) no-repeat;
}
.circlebox {
  position: absolute;
  top: 0;
  width: 279px;
  height: 278px;
  border-radius: 50%;
  text-align: center;
  background: url(../assets/irelandiip/ierlan814_68.png) no-repeat;
  background-size: cover;
  display: none;
}
.circlebox-content {
  width: 220px;
  font-size: 16px;
  line-height: 30px;
  padding-top: 20px;
  margin: 0 auto;
  color: #fff;
}

.border-right {
  border-right: solid 1px #ddd;
}

td, th {
  padding: 0;
  vertical-align: middle;
  height: 44px;
}

.height-big {
  line-height: 30px;
}

.onebox {
  border: #0C845C solid 1px;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
}

.onebox-tit {
  background-color: #0C845C;
  color: #fff;
  line-height: 50px;
  text-align: center;
}

.onebox-content {
  flex-grow: 1;
  padding: 20px;
  line-height: 30px;
  background-color: #FFFFFF;
}

.item4 {
  position: relative;
}

.item4:hover .onebox {
  display: flex;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.layout {
  background-image: url("../assets/irelandiip/2.jpg");
  background-size: cover;
}

.layout1 {
  background-image: url("../assets/irelandiip/5.png");
  background-size: cover;
}

.layout3 {
  background: url('../assets/irelandiip/6.jpg') no-repeat center center;
  line-height: 63px;
  font-size: 27px;
}

.layout4 {
  background: url('../assets/irelandiip/9.jpg') no-repeat center center;
  background-size: cover;
}

.layout5 {
  background: url('../assets/irelandiip/13.jpg') no-repeat center center;
  background-size: cover;
}

.layout6 {
  background: url('../assets/irelandiip/16.jpg') no-repeat center center;
  background-size: cover;
}
</style>