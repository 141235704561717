<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/cpt/2.jpg" alt="" class="my-4">
    <div class="w1180">
      <p class="height-big">中央公园壹号由大型中国央企保险公司为还款提供责任险*的EB-5投资移民项目。</p>
      <p class="height-big">
        中央公园壹号已顺利通过一家中国保险集团的严格风控审核流程，成为获大型中国央企保险公司承保的EB-5投资移民项目。中国保险集团承保，充分说明其对中央公园壹号项目投资及绿卡安全性的高度认可，并为投保的投资人提供了无可比拟的还款保障。</p>
      <p class="text-end">*选择投保责任险的投资人需另行支付保费。</p>
    </div>
    <div class="w1180 row my-4">
      <div class="col-6">
        <img src="../assets/cpt/4.jpg" class="">
        <div class="mt-5">
          <p>
            美国EB-5投资移民正处于政策关键时期，极有可能迎来历史首次政策变动。在这样的关键时刻，无论是从投资角度，还是从移民申请角度，已获美国移民局批准的项目，都为新投资者提供了成功保障：</p>
          <p style="color:#b78700">· 项目相关的文件（商业计划，经济学就业报告等）已完全获得移民局审批通过；<br>·
            在以后每个投资者的I-526审批中，移民局将不再审核项目文件，仅审核与投资者个人身份及资金来源相关的文件；<br>·
            I-526批准速度远快于一般项目。</p>
        </div>
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/cpt/3.jpg" class="">
      </div>


    </div>
    <div class="d-flex  justify-content-center w-100" style="background-color: #e7e7e7">
      <img src="../assets/cpt/5.jpg" class="">
    </div>
    <div class="w1180 my-4">
      <p>继纽约楼王One57后，Extell地产集团将与人全球大师级团队共同打造中央公园壹号(Central Park
        Tower)。中央公园壹号位于素有“亿万富翁之街”(Billionaire's
        Row)的中央公园南57街，楼高1,550英尺，共131层。中央公园壹号将在高度及奢华程度上全面超越One57，成为纽约新一代楼王。</p>
      <p>
        大楼总面积120万平方英尺，底部是一个7层高的高档商场，将会由诺德斯特龙(Nordstrom)百货公司旗舰店入驻。其上是179套奢华住宅。所有的住宅单元都配备了落地玻璃幕墙，住客可以欣赏到如同明信片般完美的中央公园和城市景观。</p>
    </div>
    <div class="d-flex  justify-content-center w-100" style="background-color: #e7e7e7">
      <img src="../assets/cpt/6.jpg" class="">
    </div>
    <img src="../assets/cpt/7.jpg" class="">
    <div class="w1180 overflow-hidden my-4">
      <div class="swiper-container position-relative">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               style="width: 377px"
               v-for="(item,index) in list" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>

    </div>
    <img src="../assets/cpt/8.jpg" class="my-4">
    <div class="w1180 d-flex justify-content-between align-items-start">
      <div>
        <img src="../assets/cpt/9.jpg" alt="">
      </div>
      <div><img src="../assets/cpt/10.jpg" alt=""></div>
      <div><img src="../assets/cpt/11.jpg" alt=""></div>
      <div><img src="../assets/cpt/12.jpg" alt=""></div>
    </div>
    <img src="../assets/cpt/13.jpg" class="my-4">
    <div class="w1180 row">
      <div class="col-5">

        <p class="height-big">Extell地产集团的名字源于两个英文单词：Excellence（卓越）和Intelligence（智慧），这就是董事局主席Gary
          Barnett先生于1989年创立Extell集团的初衷-为精英人士打造卓越的住宅。</p>
        <p class="height-big">
          秉持这样的理念，在过去的27年时间里，Barnett先生带领Extell全体同仁，开发了超过2,000万平方英尺的空间，为纽约带来了一个又一个地标；纽约楼王One57、时代广场W酒店、上西Riverside
          South豪华社区、国际宝石大厦等，均出自Extell集团之手。</p>
        <p class="height-big">Extell集团穆迪信用评级为A2级，其公司债券在以色列证券交易市场上市。</p>
        <p class="height-big">2011-2013连续3年间，Extell蝉联纽约开发商总排名前列。</p>

      </div>
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list2" :key="'list2'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center w-100" style="background-color: #e7e7e7">
      <div class="w1180">

        <img src="../assets/cpt/14.png" alt="">
        <div class="row w-100">
          <div class="col-6">
            <p class="height-big">
              曼哈顿中央公园南57街是纽约尊贵的地址。这条全长3公里的马路，摩天大楼栉次鳞比，豪宅公馆茂盛林立，因此，被冠以“亿万富豪之街”的美誉。全美知名的楼王云集于此：公园大道432号、One57等等。57街的房价动辄50万人民币/平方米。Extell开发集团曾在这里创造了One57这一纽约楼王，如今，又将在这条亿万富豪之街上创造新的骄傲。</p>
          </div>
          <div class="col-6">
            <img src="../assets/cpt/15.jpg" alt="">
          </div>
        </div>
      </div>

    </div>
    <img src="../assets/cpt/16.jpg" class="my-4">
    <img src="../assets/cpt/17.jpg" class="my-4">
    <img src="../assets/cpt/18.jpg" class="my-4">
    <img src="../assets/cpt/19.jpg" class="my-4">
    <div class="w1180 overflow-hidden my-4">
      <div class="swiper-container position-relative">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               style="width: 377px"
               v-for="(item,index) in list1" :key="index">
            <img :src="item" class="w-100"/>
            <div>
              {{ t[index] }}
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

    <img src="../assets/cpt/20.jpg" class="my-4">
    <div class="w1180 row">
      <div class="col-6">
        <img src="../assets/cpt/21.jpg" alt="">
      </div>
      <div class="col-6">
        <p class="text-big">中央公园壹号EB-5贷款将由上市母公司Extell Limited提供无条件、不可更改、不可撤销的还款担保。Extell
          Limited是美国房地产开发巨头，公司信誉穆迪评级*(Moody's
          Midroog)为A2(优等投资级)，其债券在以色列证券交易市场上市。</p>
        <p class="text-default margin-bottom">*穆迪(Moody's)创立于1909年，其评级系统以权威性、独立性著称于世，与标准普尔(S&amp;P)、惠誉(Fitch)并列全球三大评级公司。</p>
        <img src="../assets/cpt/22.jpg" alt="">
      </div>

    </div>
    <img src="../assets/cpt/23.jpg" class="my-4">
    <div class="w1180">
      <p class="height-big text-big">中央公园壹号由大型中国央企保险公司为还款提供责任险*的EB-5投资移民项目。</p>
      <p class="height-big text-big">
        中央公园壹号已顺利通过一家中国保险集团的严格风控审核流程，成为获大型中国央企保险公司承保的EB-5投资移民项目。中国保险集团承保，充分说明其对中央公园壹号项目投资及绿卡安全性的高度认可，并为投保的投资人提供了无可比拟的还款保障。</p>
      <p class="text-end">*选择投保责任险的投资人需另行支付保费。</p>
    </div>
    <img src="../assets/cpt/24.jpg" class="my-4">
    <div style="font-size: 24px;font-weight: bold">
      - 专业移民讲座 -
    </div>
    <div>
      行业大咖主持，成功客户分享经验
    </div>

    <img src="../assets/cpt/26.jpg" class="my-4">
    <div class="w1180 overflow-hidden my-4 position-relative">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               style="width: 377px"
               v-for="(item,index) in list3" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <img src="../assets/cpt/27.jpg" class="my-4">
    <div class="w1180 row">
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list4" :key="'list4'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
      <div class="col-5">

        <p class="height-big margin-large-top">Adrian Smith + Gordon Gill Architecture与Adamson Associates Architects携手，设计中央公园壹号。AS+GG是一家总部位于美国芝加哥的建筑和设计公司，由美国建筑师艾德里安·史密斯(Adrian Smith)、戈登·吉尔(Gordon Gill)及罗伯特·福斯特(Robert Forest)创立。</p>
        <p class="height-big">AS+GG以擅长设计超高层摩天大楼知名于世，其名作包括迪拜哈里发塔（828米高，世界高楼），沙特王国塔（1,000米高，2020年完工后将成为世界至高的建筑）和上海金茂大厦（由艾德里安·史密斯在SOM时设计）等。</p>
      </div>

    </div>
    <img src="../assets/cpt/28.jpg" class="my-4">
    <div class="w1180 row">

      <div class="col-5">
        <p class="height-big">联实集团(Lendlease Group)，1958年在悉尼成立，澳大利亚证交所上市公司(ASX企业代码：LLC)，是全球一流的提供全流程地产服务的开发商和承建商。50年来，联实集团参与建设、管理、重建了世界各地的地标建筑，目前业务主要在澳大利亚，亚洲，英国，欧洲，中东及美国运营，业务覆盖零售、办公、工业、酒店、电信、教育及生命科学。</p>
        <p class="height-big">联实集团的主要数据包括：<br>· 全球雇佣员工超18,000名，已开发项目逾10,000件；<br>· 道琼斯可持续发展指数(DJSI)首家澳洲上榜企业；<br>· 旗下管理资产逾123亿澳元。</p>
      </div>
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list5" :key="'list4'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
    </div>
    <img src="../assets/cpt/30.jpg" class="my-4">
    <div class="row w1180">
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/cpt/31.jpg" class="my-4">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/cpt/32.jpg" class="my-4">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/cpt/33.jpg" class="my-4">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/cpt/34.jpg" class="my-4">
      </div>
    </div>
    <LiveBox/>
  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "cpt",
  data() {
    return {
      t: [
        '诺德斯特龙高级百货·效果图', '住客大堂·效果图', '空中室内泳池·效果图', '华宅内部·效果图', '中央公园壹号'
        , '中央公园壹号', '北向景观', '南向景观'
      ],
      list: [
        require('../assets/cpt/cpt_c1.jpg'),
        require('../assets/cpt/cpt_c2.jpg'),
        require('../assets/cpt/cpt_c3.jpg'),
        require('../assets/cpt/cpt_c4.jpg'),
        require('../assets/cpt/cpt_c5.jpg'),
        require('../assets/cpt/cpt_c6.jpg'),
        require('../assets/cpt/cpt_c7.jpg'),
        require('../assets/cpt/cpt_c8.jpg'),
        require('../assets/cpt/cpt_c9.jpg'),
        require('../assets/cpt/cpt_c10.jpg'),
      ],
      list1: [
        require('../assets/cpt/cpt_33.jpg'),
        require('../assets/cpt/cpt_35.jpg'),
        require('../assets/cpt/cpt_37.jpg'),
        require('../assets/cpt/cpt_39.jpg'),
        require('../assets/cpt/cpt_40.jpg'),
        require('../assets/cpt/cpt_41.jpg'),
        require('../assets/cpt/cpt_42.jpg'),
        require('../assets/cpt/cpt_43.jpg'),
      ],
      list2: [
        require('../assets/cpt/cpt_70.jpg'),
        require('../assets/cpt/cpt_72.jpg'),
        require('../assets/cpt/cpt_74.jpg'),
        require('../assets/cpt/cpt_79.jpg'),
        require('../assets/cpt/cpt_80.jpg'),
        require('../assets/hres/hres_30.png'),
      ],
      list4: [
        require('../assets/cpt/cpt1_03.jpg'),
        require('../assets/cpt/cpt1_05.jpg'),
        require('../assets/cpt/cpt1_07.jpg'),
        require('../assets/cpt/cpt1_12.jpg'),
        require('../assets/cpt/cpt1_13.jpg'),
        require('../assets/hres/hres_30.png'),
      ],
      list3: [
        require('../assets/cpt/b/2.jpg'),
        require('../assets/cpt/b/3.jpg'),
        require('../assets/cpt/b/4.jpg'),
        require('../assets/cpt/b/9.jpg'),
        require('../assets/cpt/b/5.jpg'),
        require('../assets/cpt/b/6.jpg'),
        require('../assets/cpt/b/7.jpg'),
        require('../assets/cpt/b/8.jpg'),
      ],
      list5: [
        require('../assets/cpt/cpt1_17.jpg'),
        require('../assets/cpt/cpt1_19.jpg'),
        require('../assets/cpt/cpt1_21.jpg'),
        require('../assets/cpt/cpt1_26.jpg'),
        require('../assets/cpt/cpt1_27.jpg'),
        require('../assets/hres/hres_30.png'),
      ],
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 4,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30
    });
  }
}
</script>

<style scoped>

</style>