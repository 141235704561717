let list = [
    {
        title: "巴塞沿海联排别墅-310平-4层-78万",
        date: "2022-11-11",
        img: require('@/assets/house/2.jpg'),
        content: "巴塞沿海联排别墅-310平-4层-78万",
        id: 5
    },
    {
        title: "巴塞罗那扩展区精装公寓-B-Rom21107-116平-3室2卫-78.5万欧",
        date: "2022-11-11",
        img: require('@/assets/house/2.jpg'),
        content: "巴塞罗那扩展区精装公寓-B-Rom21107-116平-3室2卫-78.5万欧",
        id: 4
    },
    {
        title: "R446 北部富人区2022全新楼盘 37万欧元起",
        date: "2022-11-11",
        img: require('@/assets/house/2.jpg'),
        content: "R446 北部富人区2022全新楼盘 37万欧元起",
        id: 3
    },
    {
        title: "【希腊移民】雅典精品房源，25万欧起，3代绿卡",
        date: "2019-12-20",
        img: require('@/assets/house/2.jpg'),
        content: "房产简介 位置：雅典南部 户型：3居室 面积：120㎡ 公寓位于雅典南部，距离雅典国际机场16km，宪法广场11.5km，比雷埃夫斯港13km；周边基础设施齐全…",
        id: 2
    },
    {
        title: "【希腊购房移民】雅典北部房源，25万欧购房，3代绿卡",
        date: "2019-05-12",
        img: require('@/assets/house/1.jpg'),
        content: "房产信息 户型：一居室 面积：67㎡ 位置：北部 跃迁推出的雅典房产项目，只需25万欧购房还附赠全家欧洲身份，不仅每年可以获得房租收入，还可以获得…",
        id:1
    },
]


export default list