<template>
  <div class="w-100">
    <div class="w-100 d-flex flex-column align-items-center  py-4" style="background-color:#c39d5e;" v-if="showType === '0'">
      <h1 style="color:#FFF;" class="height-large"><strong>跃迁出国专业服务</strong></h1>
      <h3 style="color:#FFF;" class="height-large padding-bottom"><strong>一个电话，帮您更快移民：13764320113</strong></h3>
    </div>

    <div class=" text-center mt-3">
      <span style="font-size: 24px;"><strong>- 专业移民讲座 -</strong></span>
      <div>行业大咖主持，成功客户分享经验</div>
    </div>
    <div class="w-100 d-flex justify-content-center my-3 ">
      <div style="width: 1180px;" class="border-top live-box text-center py-3">
        <div>
          <strong>美国移民线下说明会</strong>
        </div>
        <div>预约电话：13764320113</div>
      </div>
    </div>

<!--    <div class="w-100 d-flex justify-content-center">-->
<!--        <div class="row" style="width: 1180px;">-->
<!--          <div class="col-4 d-flex flex-column align-items-center">-->
<!--            <img src="../assets/b1.jpg" alt="">-->
<!--            <p class="padding-large-left padding-big-top height-big">-->
<!--              <span class="text-large text-blue">跃迁I-526条件绿卡批准</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图万豪万怡酒店项目(618LP)</span><br>-->
<!--              <span class="icon-chevron-right"> CMB区域中心组A、组B、组1-组10，组18</span><br>-->
<!--              <span class="icon-chevron-right"> 佛蒙特枫林滑雪场项目</span><br>-->
<!--              <span class="icon-chevron-right"> 华盛顿O街市场I期、II期</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图海岸写字楼项目(1531LP)</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图第1大道写字楼I期、II期</span><br>-->
<!--              <span class="icon-chevron-right"> 洛杉矶万豪酒店I期、II期(901LP)</span><br>-->
<!--              <span class="icon-chevron-right"> 巴尔的摩港口项目</span><br>-->
<!--              <span class="icon-chevron-right"> 波特兰万豪套房酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 华盛顿希尔顿酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 旧金山猎人角新城组A-E</span><br>-->
<!--              <span class="icon-chevron-right"> 旧金山PROPER酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 波特兰凯悦酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 纽约世贸中心四季酒店项目</span><br>-->
<!--              <span class="icon-chevron-right"> 哈德逊城市广场II期-曼哈顿大厦项目</span><br>-->
<!--              <span class="icon-chevron-right"> 纽约第五大道万事达卡大厦项目</span><br>-->
<!--              <span class="icon-chevron-right"> 纽约华尔街1号</span><br>-->
<!--              <span class="icon-chevron-right"> 纽约中央公园壹号</span><br>-->
<!--              <span class="icon-chevron-right"> 纽约中央公园E59</span><br>-->
<!--              <span class="icon-chevron-right"> 波士顿四季酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 哈德逊城市广场III期</span><br>-->
<!--              <span class="icon-chevron-right">纽约时代广场万豪酒店</span><br>-->
<!--              <span class="icon-chevron-right">洛杉矶第五大道</span><br>-->
<!--              <span class="icon-chevron-right">纽约哈德逊河滨豪庭</span><br>-->
<!--              <span class="icon-chevron-right">纽约帝国万豪酒店</span><br>-->
<!--              <span class="icon-chevron-right">加州贝景湾</span><br>-->
<!--              <span class="icon-chevron-right">好莱坞星光天地精品酒店I期 - VI期</span><br>-->
<!--              <span class="text-large"><i class="text-blue">51</i>个项目，近<i class="text-blue">6000</i>个家庭</span>-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="col-4 d-flex flex-column align-items-center">-->
<!--            <img src="../assets/b2.jpg" alt="">-->
<!--            <p class="padding-large-left padding-big-top height-big">-->
<!--              <span class="text-large text-blue">跃迁I-829永久绿卡批准</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图万豪万怡酒店项目(618LP)</span><br>-->
<!--              <span class="icon-chevron-right"> CMB区域中心组A、组B、组1-组9</span><br>-->
<!--              <span class="icon-chevron-right"> 佛蒙特枫林滑雪场项目</span><br>-->
<!--              <span class="icon-chevron-right"> 华盛顿O街市场I期</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图海岸写字楼项目(1531LP)</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图第1大道写字楼I期、II期</span><br>-->
<!--              <span class="icon-chevron-right"> 巴尔的摩港口项目</span><br>-->
<!--              <span class="icon-chevron-right">洛杉矶万豪酒店I期、II期(901LP)</span><br>-->
<!--              <span class="icon-chevron-right">华盛顿希尔顿酒店</span><br>-->
<!--              <span class="icon-chevron-right">波特兰万豪套房酒店</span><br>-->
<!--              <span class="icon-chevron-right">好莱坞星光天地精品酒店I期</span><br>-->
<!--              <span class="icon-chevron-right">旧金山猎人角新城组A-E</span><br>-->
<!--              <span class="icon-chevron-right">旧金山PROPER酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 波特兰凯悦酒店</span><br>-->
<!--              <span class="icon-chevron-right">纽约世贸中心四季酒店项目</span><br>-->
<!--              <span class="text-large"><i class="text-blue">31</i>个项目，逾<i class="text-blue">2300</i>个家庭</span>-->
<!--            </p>-->
<!--          </div>-->
<!--          <div class="col-4 d-flex flex-column align-items-center">-->
<!--            <img src="../assets/b3.jpg" alt="">-->
<!--            <p class="padding-large-left padding-big-top height-big">-->
<!--              <span class="text-large text-blue">项目如期还款/分红</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图万豪万怡酒店项目(618LP)</span><br>-->
<!--              <span class="icon-chevron-right"> CMB区域中心组A、组B、组1-组6</span><br>-->
<!--              <span class="icon-chevron-right"> 佛蒙特枫林滑雪场项目</span><br>-->
<!--              <span class="icon-chevron-right"> 华盛顿O街市场I期</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图海岸写字楼项目(1531LP)</span><br>-->
<!--              <span class="icon-chevron-right"> 西雅图第1大道写字楼I期、II期</span><br>-->
<!--              <span class="icon-chevron-right"> 洛杉矶万豪酒店I期、II期(901LP)</span><br>-->
<!--              <span class="icon-chevron-right"> 波特兰万豪套房酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 波特兰凯悦酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 巴尔的摩港口项目</span><br>-->
<!--              <span class="icon-chevron-right"> 旧金山猎人角新城组A、组B、组C</span><br>-->
<!--              <span class="icon-chevron-right"> 华盛顿希尔顿酒店</span><br>-->
<!--              <span class="icon-chevron-right"> 圣何塞万豪酒店项目</span><br>-->
<!--              <span class="text-large"><i class="text-blue">24</i>个项目如期还款/分红</span>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--    </div>-->

  </div>

</template>
<script>
export default {
  name: 'LiveBox',
  props: {
    showType: {
      type: String,
      default: "0"
    }
  }
}
</script>
<style scoped>

.icon-chevron-right:before{
  font-family: element-icons!important;
  content: "\e6e0";
}
.text-blue, a.text-blue:link, a.text-blue:visited, .button.border-blue, .button.bg-blue-light, blockquote.border-blue strong, .pager.border-blue a, .pager-prev.border-blue, .pager-next.border-blue, .badge.bg-blue-light, .alert-blue {
  color: #FFF;
}
.text-large {
  font-size: 24px;
}
.swiper-slide img {

}
.live-box:hover{
  background-color: #f5f5f5;
}

</style>