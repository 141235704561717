<template>
  <div class="d-flex align-items-center flex-column">
    <img src="../assets/vanuatu/1.jpg" alt="" class="w-100">
    <img src="../assets/vanuatu/2.jpg" alt="" class="my-4">

    <div class="row w1180 mt-4" style="background-color:#5996b5;">
      <div class="col-3 p-0">
        <img src="../assets/vanuatu/vanuatu_02.jpg" class="w-100">
      </div>
      <div class="col-6 text-white p-6">
        <img src="../assets/vanuatu/vanuatu_05.jpg" class="">
        <div><span class="icon-circle"></span>&nbsp;&nbsp;所在洲：大洋洲</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;位置：太平洋西南侧，澳大利亚东侧</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;国土面积：约84万平方公里（83个岛屿）</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;首都：维拉港（Port Villa）</div>
        <img src="../assets/vanuatu/vanuatu_06.jpg" class="mt-4" >
        <div><span class="icon-circle text-white text-small"></span>&nbsp;&nbsp;英语、法语、比斯拉马语</div>
      </div><!--x6-->
      <div class="col-3 p-0">
        <img src="../assets/vanuatu/vanuatu_03.jpg" class="w-100">
      </div>
    </div>
    <div class="row w1180" style="background-color:#2dc7cf;">
      <div class="col-9 p-0">
        <img src="../assets/vanuatu/vanuatu_07.jpg" class="w-100">
      </div>
      <div class="col-3 text-white p-4 pt-5">
        <img src="../assets/vanuatu/vanuatu_08.jpg" class="mt-5">
        <div><span class="icon-circle"></span>&nbsp;&nbsp;约27万人</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;95%为原居民</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;其余为欧洲人丶其他太平洋岛民和亚洲人</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;瓦努阿图为基督教国家</div>
      </div>
    </div>
    <div class="row w1180" style="background-color:#43acf0;">
      <div class="col-3 p-0">
        <img src="../assets/vanuatu/vanuatu_04.jpg" class="w-100">
      </div>
      <div class="col-9 text-white p-4 pt-5">
        <img src="../assets/vanuatu/vanuatu_09.jpg" class="mt-5">
        <div><span class="icon-circle"></span>&nbsp;&nbsp;被国际组织四次(2006，2010，2013，2014)评选为“地球上最快乐的地方”；</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;在2009年世界银行的研究报告中被评为该地区“容易营商” (“Ease of Doing Business”)之最高评级（宽松的营商环境：体现在企业在当地遵循的政策法规下开设、经营、贸易、纳税、执行合约等方面所需的时间短，成本低）；</div>
        <div><span class="icon-circle"></span>&nbsp;&nbsp;2011年6月被国际经济合作与发展组织 (OECD) 评为国际“白名单”, “White List”成员（拥有透明的税收制度和合作的税收信息交换系统）。</div>
      </div>
    </div>

    <div class="w1180 d-flex justify-content-end">
      <img src="../assets/vanuatu/7.jpg" alt="" class="my-4">
    </div>

    <img src="../assets/vanuatu/8.jpg" alt="" class="my-4">
    <div class="w1180 d-flex my-4">
      <div style="width:90%">
        <p>瓦努阿图共和国，位于南太平洋西部，属热带海洋性气候，四季如夏，生态环境优美。 瓦努阿图政治稳定，实行一会民主制保障人权，尊重法制，实施普通法法律制度。四次被国际组织评为“全球快乐的国家”。 国家无个人入息税及公司所得税，企业增值税等，不会向公民在当地之外的收入、利息、赠与、财产及遗产等征税。被世界银行评为“容易营商”的高评级。被国际经济合作与发展组织 （OECD） 评为国际“白名单“国成员。 瓦努阿图护照含金量高，免签国家和地区超过100个，并且承认双重国籍。</p>
        <p>瓦努阿图捐献计划(VCP)：于二零一六年十二月十九日瓦努阿图共和国已根据《国籍法第112章》第13D条正式立法通过《2016年第220号国籍条例（捐献计划）法令》。该法令制定了捐献计划(Contribution Program)的细则，透过捐献计划筹集资金并吸纳高质素的新移民，推动瓦国经济发展。</p>
      </div>
    </div>


    <div class="row w1180">
      <div class="col-6">
        <img src="../assets/vanuatu/9.jpg" alt="">
      </div>
      <div class="col-6">
        <div class="d-flex justify-content-center w-100">
          <img src="../assets/vanuatu/10.jpg" alt="">
        </div>
        <div class="x12 margin-large-top mt-4 px-4">
          <p>1、年满18周岁（可携同配偶、18岁以下子女、18-25岁在读未婚子女和50岁以上父母提交申请。）;</p>
          <p>2、无犯罪记录;</p>
          <p>3、25万美金以上资产证明;</p>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<p>4、向瓦努阿图捐献计划捐款USD 80,000。</p>
        </div>
      </div>
    </div>

    <div class="row w1180 mt-4">

      <div class="col-6">
        <div class="d-flex justify-content-center w-100">
          <img src="../assets/vanuatu/11.jpg" alt="">
        </div>
        <div class=" mt-4">
          <p>1、完善的法律基础，荣誉公民，地位显赫;</p>
          <p>2、罕有机会，限额极少，瓦努阿图政府全力支持;</p>
          <p>3、无需登录、无居住要求;</p>
          <p>4、特快获批，约30天便可完成;</p>
          <p>5、一人提名、三代同行;</p>
          <p>6、无外汇管制、个人入息税、公司利得税、增值税和遗产税等;</p>
          <p>7、英联邦及联合国成员国，护照免签全球124个国家和地区，包括英、法、德、意、西等40个欧洲国家。</p>
        </div>
      </div>
      <div class="col-6">
        <img src="../assets/vanuatu/12.jpg" alt="">
      </div>
    </div>
    <div class="w-100 my-5 d-flex justify-content-center layout" >
      <img src="../assets/vanuatu/14.png" alt="" >
    </div>
    <img src="../assets/vanuatu/15.jpg" alt="" >
    <img src="../assets/vanuatu/16.jpg" alt="" >
    <img src="../assets/vanuatu/17.jpg" alt="" >
    <img src="../assets/vanuatu/18.jpg" alt="" >
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "vanuatu"
}
</script>

<style scoped>
.layout{
  background: url('../assets/vanuatu/13.jpg') no-repeat center center;
  background-size: cover;
}
.p-6{
  padding: 2rem 6rem;
}
.icon-circle:before {
  content: '●';
  font-size: 28px;
}
</style>