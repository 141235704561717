<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/rmh/1.jpg" alt="" class="w-100">
    <div class="w1180 d-flex align-items-center py-5">
      <img src="../assets/rmh/2.jpg" alt="" class="me-4">
      <p class="yh">
        美国时间2019年7月24日EB-5区域中心新规正式出台，将目标就业区(TEA)的投资提高到90万美元，非目标就业区的投资提高到180万美元，新规已于11月21日正式生效。
      </p>
    </div>
    <div class="d-flex justify-content-center w-100 py-4" style="background-color: #272727">
      <div class="w1180">
        <div class="row w-100 align-items-center">
          <div class="col-4">
            <hr style="color: #FFFFFF;opacity: 1">
          </div>
          <div class="col-4 text-center" style="font-size:42px;color:#80C6C9;">
            星光天地成功记录
          </div>
          <div class="col-4">
            <hr style="color: #FFFFFF;opacity: 1">
          </div>
        </div>
        <div class="row w-100 align-items-center text-white mt-4">
          <div class="col-2 ">
            <img src="../assets/rmh/3.jpg" alt="">
            <div class="text-center mt-3">
              1期 梦酒店 <br>
              获I-829永久绿卡批准
            </div>
          </div>
          <div class="col-2 ">
            <img src="../assets/rmh/4.jpg" alt="">
            <div class="text-center mt-3">
              2期 梦酒店<br>
              获I-526永久绿卡批准
            </div>
          </div>
          <div class="col-2 ">
            <img src="../assets/rmh/5.jpg" alt="">
            <div class="text-center mt-3">
              3期 汤普森酒店<br>
              获I-526永久绿卡批准
            </div>
          </div>
          <div class="col-2 ">
            <img src="../assets/rmh/6.jpg" alt="">
            <div class="text-center mt-3">
              4期 Tommie酒店 <br>
              获I-526永久绿卡批准
            </div>
          </div>
          <div class="col-2 ">
            <img src="../assets/rmh/7.jpg" alt="">
            <div class="text-center mt-3">
              5期 TAO餐厅<br>
              获I-526永久绿卡批准
            </div>
          </div>
          <div class="col-2 ">
            <img src="../assets/rmh/7.jpg" alt="">
            <div class="text-center mt-3">
              6期 Citizen News <br>
              获I-526永久绿卡批准
            </div>
          </div>

        </div>
        <div class="d-flex justify-content-center my-4" style="font-size:42px;color:#80C6C9;padding-bottom: 150px;">
          7期已获模范认证批准
        </div>
      </div>
    </div>
    <div style="width:1006px; margin-top:-150px;">
      <div class="d-flex text-white">
        <div class="bg2">
          <div style="width:335px; height:315px;padding:50px 32px 0;font-size:28px; line-height:42px;">
            项目相关的文件（商业计划、经济学就业报告等）已完全获得移民局审批通过
          </div>
        </div>
        <div class="bg1">
          <div style="width:335px; height:315px;padding:100px 32px 0;font-size:28px; line-height:42px;">
            I-526批准速度<br>
            远远快于一般项目
          </div>
        </div>
        <div class="bg2">
          <div style="width:335px; height:315px;padding:50px 32px 0;font-size:28px; line-height:42px;">
            移民局一贯对已经获得模范认证批准的目持“尊重”态度，即项目不受一般的政策变化影响
          </div>
        </div>
      </div>
    </div>

    <div class="row text-center align-items-center justify-content-center w1180 my-5">
      <div class="col-2">
        <hr>
      </div>
      <div class="col-6" style="font-size:42px;">
        美盛酒店·洛城梦想家
      </div>
      <div class="col-2">
        <hr>
      </div>
      <div class="col-12 text-start my-4">
        <p class="height-big">
          美盛酒店位于洛杉矶心脏地带，不仅坐拥活力洛城L.A. Live带来的所有设施，更尽享交通便利。酒店出门即为地铁蓝线PICO站，酒店住客前往其它商业设施极其便利。<br><br>
          洛杉矶，加州第1大城市，全美第2大城市，是美国经济、教育、文化和艺术中心。大洛杉矶地区拥有逾1800万人口，宜人的气候和多元的文化使洛杉矶成为华人热衷的定居地。<br><br>
          美盛酒店同时毗邻南加州的旅游胜地 -
          圣莫尼卡和马里布阳光明媚的海滩，充满活力和激情的好莱坞，以及世界知名的比华利山购物中心。在这里，住客可以真正享受一切，无论是放松、给自己充电还是计划下一次冒险
          - 穿越这座城市并感受这里的魅力。
        </p>
      </div>

      <div class="col-12 d-flex my-4 justify-content-around">
        <div class="t-tab-item">
          <span class="active">天使之城 - 洛杉矶</span>
        </div>
        <div class="t-tab-item">
          <span>傲人的旅游资源</span>
        </div>
        <div class="t-tab-item">
          <span class="active">繁荣的酒店市场</span>
        </div>
        <div class="t-tab-item">
          <span>明星钟爱置业之所</span>
        </div>
        <div class="t-tab-item">
          <span class="active">通往亚洲的门户</span>
        </div>
      </div>
    </div>

    <div class="overflow-hidden" style="width: 980px;">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center">
            <div class="b1 w-100 slide-box">
              <div class="mask w-100 h-100">
                <div class="text-center mb-3">洛杉矶会展中心</div>
                全美五大会展中心之一。每年承办350场大型活动，如E3（全美电子游戏大展）、WPC（微软全球开发者大会）等。市政府计划投入巨资，拓展洛杉矶会展中心，将其打造成全美重要的会展中心之一，将为当地带来大量酒店住房需求。
              </div>
            </div>
          </div>
          <div class="swiper-slide d-flex flex-column align-items-center">
            <div class="b2 w-100 slide-box">
              <div class="mask w-100 h-100">
                <div class="text-center mb-3">斯台普斯中心</div>
                活力洛城的斯台普斯中心(Staples
                Center)是全球知名大型体育及音乐场馆。每年举办超过250场活动，拥有近400万人次的游客。斯台普斯中心是NBA洛杉矶湖人队、NBA洛杉矶快船队、WNBA洛杉矶火花队、NHL洛杉矶国王队、AFL洛杉矶Avengers和NBDL洛杉矶防御者队的共同主场，也是NBA唯一的两支队共用的主场球馆。
              </div>
            </div>
          </div>
          <div class="swiper-slide d-flex flex-column align-items-center">
            <div class="b3 w-100 slide-box">
              <div class="mask w-100 h-100">
                <div class="text-center mb-3">活力洛城</div>
                美盛酒店毗邻活力洛城(L.A. Live)，这个耗资25亿美元打造的娱乐综合体，是全球知名的体育及音乐圣地。15家品牌餐馆，3
                间高档会所，影院，格莱美博物馆及其他地标场所云集于此。每年举办逾1,500场娱乐盛事，年访客超过1,500万人。
              </div>
            </div>
          </div>
          <div class="swiper-slide d-flex flex-column align-items-center">
            <div class="b4 w-100 slide-box">
              <div class="mask w-100 h-100">
                <div class="text-center mb-3">洛杉矶2028年奥运会</div>
                继1932年和1984年后，洛杉矶第三次获得夏季奥运会举办权。这座城市将有十年的时间来为有利的经济涌入做准备，同时，举办奥运会后也会持续增加许多效益收入，从而推动城市发展，使其弹升为更高排名的世界主导城市。活力洛城的斯台普斯中心将是洛杉矶奥运会主场馆之一，周边酒店需求强劲。
              </div>
            </div>
          </div>
          <div class="swiper-slide d-flex flex-column align-items-center">
            <div class="b5 w-100 slide-box">
              <div class="mask w-100 h-100">
                <div class="text-center mb-3">微软剧院</div>
                全球知名大型音乐及演出场所。微软剧院是举办各种音乐会和特别活动的官方选地，如全美音乐奖、黄金时段艾美奖、
                年度卓越体育表现奖 (ESPY)、黑人娱乐电视奖、美国人民选择奖、2010年和2011年MTV音乐录影带大奖、2014年MTV电影奖等。
              </div>
            </div>
          </div>
        </div>
        <!-- Add Arrows -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

    <img src="../assets/rmh/8.jpg" alt="" class="my-4">
    <div>READ MORE</div>
    <img src="../assets/rmh/9.jpg" alt="" class="my-4">
    <img src="../assets/rmh/10.jpg" alt="" class="my-4">
    <img src="../assets/rmh/11.jpg" alt="" class="my-4 w-100">

    <div class="w1180 position-relative" style="margin-top: -200px;margin-bottom: 300px;">
      <div class="w-100" style="background-color:#78d8d5;height: 400px ">

      </div>
      <div class="row w-100 position-absolute top-0">
        <div class="col-6 p-5">
          <div class="w-100">
            <img src="../assets/rmh/12.jpg" alt="" class="w-100">
          </div>
        </div>
        <div class="col-6 text-big">
          <div class="p-4">
            <h2 style="font-size:42px; line-height:67px;margin-top:50px;">
              星光天地美盛酒店
            </h2>
            <p class="height-big pt-4">
              美盛豪华酒店俯瞰斯台普斯中心和活力洛城L.A. Live，住客咫尺即达洛杉矶的精髓之地：充满活力的体育和娱乐中心、金融中心、繁华的艺术区、创意餐馆以及不拘一格的名品店。<br>
              项目将由凯悦或万豪酒店管理。这将是一个超豪华的综合项目，包含一栋奢华公寓、一栋五星级奢华酒店及一栋由历史地标建筑翻新增建的美盛豪华酒店，为住客提供便捷出入及充满活力的大都会生活方式。
            </p>
            <table style="margin-top:80px;">
              <tbody>
              <tr>
                <td width="165">单位投资额</td>
                <td>$180万美元(<span style="font-size:10px;">*根据11月21日执行的移民局新规，认购金额为180万</span>)</td>
              </tr>
              <tr>
                <td>基金发行费</td>
                <td>$5.5万美元</td>
              </tr>
              <tr>
                <td>EB-5贷款期</td>
                <td>5年</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <img src="../assets/rmh/13.jpg" alt="" class="my-4">
    <div class="d-flex justify-content-center" style="background-color: #78d8d5">
      <div class="w1180 layout">
        <div class="row text-white align-items-center justify-content-center py-5">
          <div class="col-2">
            <hr style="color: #ffffff;opacity: 1;">
          </div>
          <div class="col-6" style="font-size: 38px;">
            项目将由全球知名酒店集团管理
          </div>
          <div class="col-2">
            <hr style="color: #ffffff;opacity: 1;">
          </div>
          <div class="col-12  text-center my-4 height-big">
            睿利集团已收到凯悦及万豪两家公司的意向书，并正在两个品牌之间做出决定。<br>
            公寓项目及豪华酒店项目将成为万豪旗下的“白标”品牌酒店，或成为凯悦家族的一部分。
          </div>
          <div class="col-12 d-flex justify-content-center mb-5 mt-4">
            <img src="../assets/rmh/15.png" alt="">
          </div>
        </div>

      </div>

    </div>

    <div class="row text-center align-items-center justify-content-center w1180 my-5">
      <div class="col-2">
        <hr>
      </div>
      <div class="col-6" style="font-size:42px;">
        当历史与摩登在这里相遇
      </div>
      <div class="col-2">
        <hr>
      </div>
      <div class="col-12 text-center my-4">
        在美盛酒店，一段宁静而精致的生活正在静候您的到来。
      </div>
    </div>

    <div class="w1180 row justify-content-center">
      <div class="col-5 position-relative">
        <img src="../assets/rmh/16.jpg" alt="" class="">
        <div class="position-absolute p-5 height-big" style="top: 0;left: 0">
          300间客房 <br>
          1个大堂吧<br>
          2个会议室<br>
          1个屋顶游泳池<br>
          2个餐饮/夜生活场所
        </div>
        <div class="text-white position-absolute" style="top:228px;left: 40px;font-size: 13px; letter-spacing :-1px">
          READ MORE
        </div>
      </div>
      <div class="col-5 position-relative">
        <img src="../assets/rmh/17.jpg" alt="" class="">
        <div class="position-absolute height-big text-white" style="top: 30px;left: 40px;width: 200px;">
          屋顶泳池、烧烤架、餐厅、酒廊、酒吧、零售店以及跨层于屋顶、顶层阁楼及底层的爵士吧，将为城市创造一些活力的社交空间和生活方式场所。
        </div>
        <div class="text-white position-absolute" style="top:228px;left: 40px;font-size: 13px; letter-spacing :-1px">
          READ MORE
        </div>
      </div>
      <div class="col-5 position-relative mt-5">
        <img src="../assets/rmh/18.jpg" alt="" class="">
        <div class="position-absolute height-big text-white" style="top: 30px;left: 40px;width: 200px;">
          精致的酒店拥有优雅的特色现代设计及设施，包括度假式泳池、鸡尾酒吧、以及知名主厨入驻的餐厅。
        </div>
        <div class="text-white position-absolute" style="top:228px;left: 40px;font-size: 13px; letter-spacing :-1px">
          READ MORE
        </div>
      </div>
      <div class="col-5 position-relative mt-5">
        <img src="../assets/rmh/19.jpg" alt="" class="">
        <div class="position-absolute height-big " style="top: 30px;left: 40px;width: 200px;">
          美盛酒店的重建引起了洛杉矶娱乐圈风潮。老楼的翻新。睿利集团用他们的方式，表达了其对这栋近百年历史大楼的尊敬。
        </div>
        <div class="text-white position-absolute" style="top:228px;left: 40px;font-size: 13px; letter-spacing :-1px">
          READ MORE
        </div>
      </div>

    </div>


    <div class="d-flex justify-content-center w-100 my-5" style="background-color: #272727">
      <div class="w1180">
        <div class="row text-center align-items-center justify-content-center my-5">
          <div class="col-2">
            <hr style="color: #FFFFFF;opacity: 1;">
          </div>
          <div class="col-6 text-white" style="font-size:42px;">
            移民基金管理方和开发商
          </div>
          <div class="col-2">
            <hr style="color: #FFFFFF;opacity: 1;">
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-white my-4">
            <img src="../assets/rmh/20.jpg" alt="">
            <div class="x12" style="margin-top:50px;">
              <table class="" style="width:100%; text-align:center;">
                <tbody>
                <tr>
                  <td>$10亿</td>
                  <td>700+</td>
                  <td>705</td>
                  <td>$2.5亿</td>
                </tr>
                <tr style="color:#80C6C9;">
                  <td>开发项目</td>
                  <td>洛杉矶雇员</td>
                  <td>酒店客房</td>
                  <td>至今在中国募集的资金</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="text-start my-5">
              睿利集团(RELEVANT Group)，是大洛杉矶地区知名的酒店开发商，开发项目案值超过10亿美元。全球战略合作伙伴包括众多精品酒店和餐饮娱乐管理品牌：梦酒店集团、道(TAO)餐饮集团和凯悦酒店集团。
            </div>
            <img src="../assets/rmh/22.png" alt="" class="w-100">
          </div>
          <div class="col-6 text-white my-4">
            <div class="d-flex justify-content-center">
              <img src="../assets/rmh/21.jpg" alt="">
            </div>
            <div class="text-white" style="margin-top:50px;">
              <table style="width:100%; text-align:center;">
                <tbody>
                <tr>
                  <td>$1,000亿</td>
                  <td>$430亿</td>
                  <td>4,400家</td>
                  <td>550,000</td>
                </tr>
                <tr style="color:#80C6C9;">
                  <td>投资项目</td>
                  <td>管理资产</td>
                  <td>酒店资产</td>
                  <td>酒店客房</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="text-start my-5">
              柯罗尼资本(NYSE: CLNY)是全球第五大地产私募基金，并拥有丰富的酒店投资经验。柯罗尼资本拥有雅高等酒店集团，在全球投资了4,400家酒店资产，拥有超过55万间客房。
            </div>
            <img src="../assets/rmh/23.jpg" alt="" class="w-100">

          </div>
        </div>

      </div>
    </div>


    <div class="row text-center align-items-center justify-content-center w1180 my-5">
      <div class="col-2">
        <hr>
      </div>
      <div class="col-6" style="font-size:42px;">
        承袭前六期的成功记录
      </div>
      <div class="col-2">
        <hr>
      </div>
    </div>
    <div class="d-flex justify-content-center mb-5">
      <div class="position-relative d-flex flex-column align-items-center">
        <img src="../assets/rmh/rmh_25.jpg" alt="">
        <div class="position-absolute text-white text-center item">
          已获移民局<br>
          I-924<br>
          模范认证批准
        </div>
        <div class="item-btn ">成功记录</div>
      </div>
      <div class="position-relative d-flex flex-column align-items-center">
        <img src="../assets/rmh/rmh_26.jpg" alt="">
        <div class="position-absolute text-white text-center item">
          位于洛杉矶市中心<br>
          紧邻活力洛城和<br>
          洛杉矶会展中心
        </div>
        <div class="item-btn active">成功记录</div>
      </div>
      <div class="position-relative d-flex flex-column align-items-center">
        <img src="../assets/rmh/rmh_27.jpg" alt="">
        <div class="position-absolute text-white text-center item">
          世界知名投资<br>
          集团注资，洛杉矶<br>
          大型酒店开发商
        </div>
        <div class="item-btn">知名团队</div>
      </div>
      <div class="position-relative d-flex flex-column align-items-center">
        <img src="../assets/rmh/rmh_28.jpg" alt="">
        <div class="position-absolute text-white text-center item">
          延续1-6期<br>
          I-924/I-526/I-829<br>
          绿卡成功记录
        </div>
        <div class="item-btn active">成功记录</div>

      </div>
    </div>

    <div class="layout1 d-flex flex-column align-items-center w-100 py-4">
      <div class="row text-center align-items-center justify-content-center w1180 my-5">
        <div class="col-2">
          <hr style="color:#FFF;opacity: 1;">
        </div>
        <div class="col-6" style="font-size:32px;    color: #80C6C9;">
          睿利集团拥有多个名流喜爱的聚会场所
        </div>
        <div class="col-2">
          <hr style="color:#FFF;opacity: 1;">
        </div>
      </div>
      <div class="w1180 overflow-hidden">
        <div class="slide-container2">
          <div class="swiper-wrapper">
            <div class="swiper-slide d-flex flex-column align-items-center"
                 v-for="(item,index) in list" :key="index">
              <img :src="item" class="w-100"/>
            </div>
          </div>
          <!-- Add Pagination -->
          <div class="swiper-pagination2"></div>
          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
    <LiveBox/>
  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "rmh",
  data() {
    return {
      list: [
        require('../assets/rmh/t1.jpg'),
        require('../assets/rmh/t2.jpg'),
        require('../assets/rmh/t3.png'),
        require('../assets/rmh/t4.jpg'),
        require('../assets/rmh/t5.jpg'),
        require('../assets/rmh/t6.jpg'),
        require('../assets/rmh/t7.jpg'),
        require('../assets/rmh/t8.jpg'),
        require('../assets/rmh/t9.jpg'),
      ]
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      autoplay: {
        delay: 3000
      },
      centeredSlides: true,
      paginationClickable: true,
    });

    new Swiper('.slide-container2', {
      autoHeight: true,
      spaceBetween: 30,
      centeredSlides: true,
      autoplay: {
        delay: 2500,
      },
      pagination: {
        el: 'swiper-pagination2',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

  }
}
</script>
<style>
@import url("../../node_modules/swiper/swiper.min.css");

</style>
<style scoped>

.item-btn.active {
  background-color: #2E2E2E;
  color: #80C6C9;
}

.item-btn {
  position: absolute;
  bottom: 80px;
  color: #FFFFFF;
  font-size: 22px;
  line-height: 46px;
  padding: 5px 16px;
  border: solid 1px #80C6C9;
}

.item {
  line-height: 60px;
  width: 271px;
  top: 0;
  padding-bottom: 40px;
  font-size: 26px;
  padding-top: 176px;
}

td, th {
  padding: 0;
  vertical-align: middle;
  height: 44px;
}

.slide-box .mask {
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
  font-size: 18px;
  padding: 60px 30px;
}

.slide-box {
  padding: 90px 120px;
  height: 428px;
}


.b1 {
  background: url('../assets/rmh/b1.jpg') no-repeat center center;
  background-size: cover;
}

.b2 {
  background: url('../assets/rmh/b2.jpg') no-repeat center center;
  background-size: cover;
}

.b3 {
  background: url('../assets/rmh/b3.jpg') no-repeat center center;
  background-size: cover;
}

.b4 {
  background: url('../assets/rmh/b4.jpg') no-repeat center center;
  background-size: cover;
}

.b5 {
  background: url('../assets/rmh/b5.jpg') no-repeat center center;
  background-size: cover;
}

.t-tab-item {
  flex-grow: 1;
}

.t-tab-item span.active {
  background-color: #595757;
  color: #fff;
}

.t-tab-item span {
  border: solid 1px #595757;
  display: block;
  margin: 0 5px;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
}

.bg1 {
  background: url('../assets/rmh/rmh_3102.jpg') no-repeat center center;
  background-size: cover;
}

.bg2 {
  background: url('../assets/rmh/rmh_3103.jpg') no-repeat center center;
  background-size: cover;
}

.layout {
  background: url('../assets/rmh/14.jpg') no-repeat center center;
  background-size: cover;
}

.layout1 {
  background: url('../assets/rmh/24.jpg') no-repeat center center;
  background-size: cover;
}

.yh {
  background-image: url(../assets/rmh/rmh_03.jpg), url(../assets/rmh/rmh_04.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: left top, right bottom;
  font-size: 16px;
  padding: 0 40px;
  line-height: 30px;
}
</style>