<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/bfs/2.jpg" alt="" class="my-4">
    <div class="w1180 row my-4">
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/bfs/3.jpg" alt="">
        <dvi>
          世界名城 教育之都
        </dvi>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/bfs/4.jpg" alt="">
        <dvi>
          百年名门 信誉卓越
        </dvi>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/bfs/5.jpg" alt="">
        <dvi>
          华人之光 操刀主理
        </dvi>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/bfs/6.jpg" alt="">
        <dvi>
          奢华代表 誉满全球
        </dvi>
      </div>

    </div>
    <div class="d-flex w-100 justify-content-center py-4" style="background-color: #b5c2ae">
      <div class="w1180 d-flex flex-column align-items-center">
        <img src="../assets/bfs/7.jpg" alt="" class="my-4">
        <div class="w1180 row">
          <div class="col-6 ">
            <div class="w-100 d-flex  justify-content-center">
              <img src="../assets/bfs/14.jpg" alt="" class="m-0">
            </div>
            <p class="height-big margin-big-top"><br>美国的独立精神起源于波士顿，时至今日，依然闪现着耀眼的光芒。波士顿是世界伟大的城市，它的辉煌历史深深铭刻在时光中。波士顿孕育的创新精神和全球合作，吸引着一代又一代的精英。
            </p>
            <p class="height-big">
              如今，波士顿已成为全美的知识，教育，医疗和科技中心，拥有知名的研究机构，医院，预备高中和大学，包括哈佛，麻省理工，东北大学，波士顿学院与波士顿大学，吸引着世界各地的精英人才。</p>

          </div>
          <div class="col-6 row">
            <div class="col-4 p-1" v-for="(item,index) in list" :key="'list'+index">
              <img :src="item" class="w-100" alt="">
            </div>
          </div>
        </div>
        <img src="../assets/bfs/15.jpg" alt="" class="my-4">
      </div>
    </div>
    <img src="../assets/bfs/16.jpg" alt="" class="my-4">
    <img src="../assets/bfs/17.jpg" alt="" class="my-4">
    <div class="d-flex w-100 justify-content-center py-4" style="background-color:#b0bda9">
      <img src="../assets/bfs/18.jpg" alt="" class="my-4">
    </div>
    <img src="../assets/bfs/19.jpg" alt="" class="my-4">
    <div class="w1180 overflow-hidden my-4 position-relative">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               style="width: 377px"
               v-for="(item,index) in list2" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
      </div>
    </div>

    <img src="../assets/bfs/20.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-6 d-flex flex-column align-items-center">
        <img src="../assets/bfs/21.jpg" alt="" class="my-4">
        <p class="height-big text-center margin-big-top">
          美国EB-5知名经济学家Jeff Carr 负责波士顿四季酒店项目的<br>
          就业报告撰写工作<br>
          项目建成后将创造共计4,748个建筑就业机会<br>
          <font style="color:#14775a;">人均15个就业机会，1.5倍就业覆盖率</font>
        </p>
      </div>
      <div class="col-6 d-flex flex-column align-items-center">
        <img src="../assets/bfs/22.jpg" alt="" class="my-4">
        <p class="height-big text-center margin-big-top">
          波士顿四季酒店开业后也将产生大量运营就业，<br>
          可作为储备，为EB-5投资人提供双重绿卡就业保障。
        </p>
      </div>

    </div>
    <img src="../assets/bfs/23.jpg" alt="" class="my-4">
    <img src="../assets/bfs/24.jpg" alt="" class="my-4">
    <div class="my-4 text-center">
      <p>
					<span class="text-large"><strong>- <span class="pr">专业移民讲座</span> -</strong>
					</span><br> 行业大咖主持，成功客户分享经验
      </p>
    </div>
    <img src="../assets/bfs/25.jpg" alt="" class="my-4">
    <div class="w1180 row my-4">
      <div class="col-3">
        <img src="../assets/bfs/26.jpg" alt="" class="">
      </div>
      <div class="col-3">
        <img src="../assets/bfs/27.jpg" alt="" class="">
      </div>
      <div class="col-3">
        <img src="../assets/bfs/28.jpg" alt="" class="">
      </div>
      <div class="col-3">
        <img src="../assets/bfs/29.jpg" alt="" class="">
      </div>

    </div>
    <img src="../assets/bfs/30.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-6 ">
        <p class="height-big padding-large-top">百年名门卡朋特家族主持开发波士顿四季酒店项目。<br>
          1638年，家族先辈威廉.卡朋特乘坐Bevis号，从南安普顿来到新英格兰定居，扎根麻省，繁衍壮大。在美国独立战争中，卡朋特家族有超过300名男性后代参加，为美国击败英国，获得独立作出了巨大贡献，被誉为“英雄之家”。
        </p>
        <p class="height-big">Carpenter &amp; Company,
          Inc.的业务横跨金融投资和房地产开发等多个领域，已成功完成了许多极具影响力的项目。其中包括：旧金山瑞吉酒店与住宅，波士顿海滨威斯汀酒店，以及位于剑桥的查尔斯酒店与住宅。</p>
      </div>
      <div class="col-6 row">
        <div class="col-4 p-1" v-for="(item,index) in list3" :key="'list3'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
    </div>
    <img src="../assets/bfs/31.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-6 row">
        <div class="col-4 p-1" v-for="(item,index) in list4" :key="'list4'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
      <div class="col-6 ">
        <p class="height-big padding-large-top">波士顿四季酒店项目将由世界知名的建筑设计事务所Pei Cobb Freed &amp;
          Partners的传奇建筑师Henry Cobb主持设计，这无疑为波士顿带来前所未有的设计水准与艺术气息。</p>
        <p class="height-big">
          PCFB的创始人贝聿铭可谓家喻户晓，他是华人之光，他领导设计所参与设计了多个全球地标：巴黎卢浮宫前的玻璃金字塔、波士顿的汉考克大厦，洛杉矶的美国银行大厦和香港的中国银行大厦等等。</p>
      </div>
    </div>
    <img src="../assets/bfs/32.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-6 ">
        <p class="height-big padding-large-top">
          四季酒店的文化在世界各地广受青睐和尊敬，如今它将为波士顿带来无与伦比的下榻体验。一直以来，四季就是奢华的代名词，经典的风格与服务使它无愧于这传奇的名字。四季将在酒店和私人住宅的管理中贯彻其尊贵服务的理念和水准。私人住宅的住户将获得四季为高端的顾客提供的专属服务，并享用四季酒店的精品设施。</p>
      </div>
      <div class="col-6 row">
        <div class="col-4 p-1" v-for="(item,index) in list5" :key="'list5'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
    </div>
    <img src="../assets/bfs/34.jpg" alt="" class="my-4">

    <div class="w1180 row">
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/bfs/35.jpg" alt="" class="">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/bfs/36.jpg" alt="" class="">
      </div>
      <div class="col-6 mt-4 d-flex justify-content-center">
        <img src="../assets/bfs/37.jpg" alt="" class="">
      </div>
      <div class="col-6 mt-4 d-flex justify-content-center">
        <img src="../assets/bfs/38.jpg" alt="" class="">
      </div>


    </div>

    <LiveBox/>

  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "bfs",
  data() {
    return {
      list: [
        require('../assets/bfs/8.jpg'),
        require('../assets/bfs/9.jpg'),
        require('../assets/bfs/10.jpg'),
        require('../assets/bfs/11.jpg'),
        require('../assets/bfs/12.jpg'),
        require('../assets/bfs/13.jpg'),

      ],
      list1: [
        require('../assets/cpt/cpt_33.jpg'),
        require('../assets/cpt/cpt_35.jpg'),
        require('../assets/cpt/cpt_37.jpg'),
        require('../assets/cpt/cpt_39.jpg'),
        require('../assets/cpt/cpt_40.jpg'),
        require('../assets/cpt/cpt_41.jpg'),
        require('../assets/cpt/cpt_42.jpg'),
        require('../assets/cpt/cpt_43.jpg'),
      ],
      list2: [
        require('../assets/bfs/1/1.jpg'),
        require('../assets/bfs/1/2.jpg'),
        require('../assets/bfs/1/3.jpg'),
        require('../assets/bfs/1/4.jpg'),
        require('../assets/bfs/1/5.jpg'),
        require('../assets/bfs/1/6.jpg'),
        require('../assets/bfs/1/7.jpg'),
      ],
      list3: [
        require('../assets/bfs/2/1.jpg'),
        require('../assets/bfs/2/2.jpg'),
        require('../assets/bfs/2/3.jpg'),
        require('../assets/bfs/2/4.jpg'),
        require('../assets/bfs/2/5.jpg'),
        require('../assets/bfs/13.jpg'),
      ],
      list4: [
        require('../assets/bfs/3/1.jpg'),
        require('../assets/bfs/3/2.jpg'),
        require('../assets/bfs/3/3.jpg'),
        require('../assets/bfs/3/4.jpg'),
        require('../assets/bfs/3/5.jpg'),
        require('../assets/bfs/13.jpg'),
      ],
      list5: [
        require('../assets/bfs/4/1.jpg'),
        require('../assets/bfs/4/2.jpg'),
        require('../assets/bfs/4/3.jpg'),
        require('../assets/bfs/4/4.jpg'),
        require('../assets/bfs/4/5.jpg'),
        require('../assets/bfs/13.jpg'),
      ]
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      loop: true,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 4,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30
    });
  }
}
</script>

<style scoped>

</style>