<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/hymt/2.jpg" alt="" class="my-4">
    <div class="w1180">

      <p class="height-big">继7月份
        <span style="color: #0000ff;"><strong>获移民局模范认证批准</strong></span>后，
        <span
            style="color: #0000ff;"><strong>哈德逊城市广场II期——曼哈顿大厦首批投资客户成功在8月份获批I-526</strong></span>，远远快于美国移民局I-526的平均审理速度(16.7个月)，战胜排期，锁定绿卡！
      </p>

      <p class="height-big text-center">
        <img src="../assets/hymt/3.jpg" alt="" class="my-4">
        <br>I-526条件绿卡批准信</p>
      <p class="height-big">

        由于全套项目模板文件已获移民局批准，在哈德逊城市广场投资人提交I-526申请时，移民局将只会审核其个人资金来源文件。同时，移民局一贯对已经获得模范认证的项目持“尊重态度”，<span
          style="color: #0000ff;"><strong>即项目不受一般的政策变化影响</strong></span>。
      </p>

      <p class="height-big">再加上如今首批投资人I-526获批，哈德逊城市广场II期项目已是市面上绝无仅有的双保险项目-<span
          style="color: #0000ff;"><strong>“I-526&amp;I-924获批”</strong></span>。</p>

      <p class="height-big">
        这样的成绩显示了一个成熟项目的巨大优势，也凸显了跃迁在选择项目上的专业眼光。</p>
    </div>
    <img src="../assets/hymt/4.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/5.jpg" alt="">
        <div class="text-center my-3">
          纽约市长布隆伯格 <br>
          见证地铁7号线开工
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/6.jpg" alt="">
        <div class="text-center my-3">
          7号线新车站通车 <br>
          布隆伯格与瑞联主席试乘
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/7.jpg" alt="">
        <div class="text-center my-3">
          哈德逊城市广场10号竣工
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/8.jpg" alt="">
        <div class="text-center my-3">
          哈德逊城市广场30号竣工
        </div>
      </div>


    </div>
    <img src="../assets/hymt/9.jpg" alt="" class="my-4">
    <div class="my-4 row w1180">
      <div class="col-5">
        <p class="height-big">哈德逊城市广场(Hudson
          Yards)位于纽约曼哈顿中城区，占地超过11公顷(170市亩)，是曼哈顿岛上规模知名的待开发用地之一。其规划建设面积超过160万平方米，集办公大楼、购物中心、住宅、地铁站、公园以及文化娱乐设施于一体。</p>
        <p class="height-big">哈德逊城市广场是以纽约大都市区交通局(MTA)
          为主导，由全美知名私营房产开发商之一瑞联集团(RELATED)，联合加拿大牛津物业集团(OXFORD)
          共同建设的大型开发项目，项目预计总投资逾200亿美元。</p>
        <p class="height-big">
          哈德逊城市广场这样一个巨型开发项目，将为纽约带来大量就业机会，同时，通过独特的铁路堆场加修上盖的设计，项目也为严冬季节饱经海水倒灌灾害的纽约城添加了一重坚实的保护。因此，哈德逊城市广场直接关系美国国家利益，得到了包括参众两院、纽约市政府、大都市区交通局的大力支持。一期逾1,000位投资人已快速获批I-526条件绿卡，同时，也创下了平均7.6个月的批准速度，快于美国移民局平均处理时间16个月。 </p>
      </div>
      <div class="col-7">
        <img src="../assets/hymt/10.jpg" alt="">
      </div>
    </div>
    <img src="../assets/hymt/11.jpg" alt="" class="my-4">
    <img src="../assets/hymt/12.jpg" alt="" class="my-4">
    <div class="w1180 overflow-hidden my-4 position-relative">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               v-for="(item,index) in list" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center" style="background-color: #e9e9e9">
      <img src="../assets/hymt/13.jpg" alt="" class="">
    </div>
    <img src="../assets/hymt/14.jpg" alt="" class="my-4">

    <div class="w1180 row my-4">
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/15.jpg" alt="" class="w-100">
        <div class="text-center my-3">
          公共广场艺术雕塑
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/16.jpg" alt="" class="w-100">
        <div class="text-center my-3">
          精品美食馆
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/17.jpg" alt="" class="w-100" height="166">
        <div class="text-center my-3">
          空中俱乐部及公共室外观景台
        </div>
      </div>
      <div class="col-3 d-flex flex-column align-items-center">
        <img src="../assets/hymt/18.jpg" alt="" class="w-100" height="166">
        <div class="text-center my-3">
          商店与餐厅
        </div>
      </div>
    </div>
    <img src="../assets/hymt/19.jpg" alt="" class="my-4">
    <img src="../assets/hymt/20.jpg" alt="" class="my-4">
    <div class="w1180 overflow-hidden my-4 position-relative">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide d-flex flex-column align-items-center"
               v-for="(item,index) in list1" :key="index">
            <img :src="item" class="w-100"/>
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/hymt/22.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list2" :key="'list2'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
      <div class="col-5">
        <p class="height-big">瑞联集团(RELATED)，总部位于纽约，成立于1972年，迄今已逾40年，现已发展成为全美知名的私营开发商之一，是纽约开发行业的领头巨擘。
          在创始人Stephen M.
          Ross的带领下，瑞联集团的业务和办公室已遍及波士顿、芝加哥、洛杉矶、旧金山、佛罗里达、华盛顿、阿布扎比、伦敦、巴西圣保罗和上海。</p>
        <p class="height-big">瑞联集团的一些重要数据包括：<br>
          · 全球雇员超过3000人，旗下管理超过200亿美元的物业资产；<br>
          · 在全美 19 个州开发了超过 50,000 套高端住宅公寓；<br>
          · 仅纽约即拥有或管理50栋大楼，其中包括著名的时代华纳中心大楼（Time Warner Building）<br>
          · 旗下EB-5区域中心已招募超过2000名投资人，其中逾千位已获I-526条件绿卡批准，并创下平均7.6个月批准的快速获批记录。
        </p>
      </div>

    </div>
    <img src="../assets/hymt/23.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-5">
          <p class="height-big margin-large-top">牛津物业集团(OXFORD)，于1960年成立于加拿大，隶属于安大略市民退休基金(OMERS), 是加拿大，也是全球知名的物业集团之一，业务覆盖私人住宅、商业楼宇、综合楼宇及酒店开发等，在美国、英国均设有分部，雇员人数超过2,000名，旗下拥有或管理超过370亿美元的不动产。</p>
          <p class="height-big">牛津物业集团，以稳妥投资著称，在加拿大、美国和英国投资开发了多个著名项目，与瑞联集团在哈德逊城市广场项目上的联手，也必将使哈德逊城市广场成为纽约的经典地标。</p>
      </div>
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list3" :key="'list2'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
    </div>
    <LiveBox/>
  </div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "hymt",
  data() {
    return {
      list: [
        require('../assets/hymt/1/1.jpg'),
        require('../assets/hymt/1/2.jpg'),
        require('../assets/hymt/1/3.jpg'),
        require('../assets/hymt/1/4.jpg'),
        require('../assets/hymt/1/5.jpg'),
        require('../assets/hymt/1/6.jpg'),
      ],
      list1: [
        require('../assets/hymt/2/1.jpg'),
        require('../assets/hymt/2/2.jpg'),
        require('../assets/hymt/2/3.jpg'),
        require('../assets/hymt/2/4.jpg'),
        require('../assets/hymt/2/5.jpg'),
        require('../assets/hymt/2/6.jpg'),
        require('../assets/hymt/2/7.jpg'),
      ],
      list2: [
        require('../assets/hymt/3/1.jpg'),
        require('../assets/hymt/3/2.jpg'),
        require('../assets/hymt/3/3.jpg'),
        require('../assets/hymt/3/4.jpg'),
        require('../assets/hymt/3/5.jpg'),
        require('../assets/hymt/3/6.jpg'),
      ],
      list3: [
        require('../assets/hymt/4/1.jpg'),
        require('../assets/hymt/4/2.jpg'),
        require('../assets/hymt/4/3.jpg'),
        require('../assets/hymt/4/4.jpg'),
        require('../assets/hymt/4/5.jpg'),
        require('../assets/hymt/3/6.jpg'),
      ],
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 4,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30
    });
  }
}
</script>

<style scoped>

</style>