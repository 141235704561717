var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/1.jpg"),"alt":""}}),_c('img',{staticClass:"my-5",attrs:{"src":require("../assets/turkey/2.png"),"alt":""}}),_vm._m(0),_c('img',{staticClass:"my-5",attrs:{"src":require("../assets/turkey/3.png"),"alt":""}}),_vm._m(1),_c('img',{attrs:{"src":require("../assets/turkey/5.png"),"alt":""}}),_vm._m(2),_vm._m(3),_c('img',{attrs:{"src":require("../assets/turkey/8.png"),"alt":""}}),_vm._m(4),_c('img',{staticClass:"my-5",attrs:{"src":require("../assets/turkey/10.png"),"alt":""}}),_vm._m(5),_c('LiveBox')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 d-flex justify-content-around"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"src":require("../assets/turkey/r1.png"),"alt":""}}),_c('p',{staticClass:"top_item"},[_vm._v(" 高性价比 ")]),_c('div',[_vm._v(" 仅需40万美元 ")]),_c('div',[_vm._v(" 投资土耳其 ")])]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"src":require("../assets/turkey/r2.png"),"alt":""}}),_c('p',{staticClass:"top_item"},[_vm._v(" 一步到位拿护照 ")]),_c('div',[_vm._v(" 3-6个月政府审批 ")]),_c('div',[_vm._v(" 无移民监/无语言要求 ")]),_c('div',[_vm._v(" /无管理经验要求 ")])]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"src":require("../assets/turkey/r3.png"),"alt":""}}),_c('p',{staticClass:"top_item"},[_vm._v(" 身份私密 ")]),_c('div',[_vm._v(" 可更名 ")]),_c('div',[_vm._v(" 不显示曾用名 ")])]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"src":require("../assets/turkey/r4.png"),"alt":""}}),_c('p',{staticClass:"top_item"},[_vm._v(" 赴美跳板 ")]),_c('div',[_vm._v(" 可申请美国E-2签证 ")]),_c('div',[_vm._v(" 1年内登陆美国 ")])]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{attrs:{"src":require("../assets/turkey/r5.png"),"alt":""}}),_c('p',{staticClass:"top_item"},[_vm._v(" 无需资产申报 ")]),_c('div',[_vm._v(" 无需申报个人资金 ")]),_c('div',[_vm._v(" 来源和收入 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 d-flex justify-content-center layout py-5"},[_c('div',{staticClass:"swiper-container overflow-hidden",staticStyle:{"width":"960px"}},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/b1.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/b2.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/b3.png"),"alt":""}})]),_c('div',{staticClass:"swiper-slide d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/b4.png"),"alt":""}})])]),_c('div',{staticClass:"swiper-pagination"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center height-big"},[_vm._v(" 进入2000年后，土耳其房地产市场经历了一个快速发展的时期；"),_c('br'),_vm._v(" 2008年前后，土耳其房地产市场较为火热，房地产一度甚至占据了土耳其国民生产总值的 9%；"),_c('br'),_vm._v(" 近十年来，土耳其住宅市场房屋销量和以里拉标价的房屋售价均处在总体上升的趋势； ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-5"},[_c('img',{attrs:{"src":require("../assets/turkey/6.png"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/turkey/7.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 layout1"},[_c('div',{staticClass:"p-5 text-white text-start",staticStyle:{"margin-left":"35%"}},[_c('p',{staticClass:"height-big"}),_c('h2',[_vm._v(" | 美国跳板—E-2签证 ")]),_c('p'),_c('p',{staticClass:"height-big margin-large-top"},[_vm._v("土耳其公民有资格申请美国E-2投资者签证，签证有效期5年，可无限次续签，实现在美 合法长期居留。")]),_c('p',{staticClass:"height-big margin-large-top"},[_vm._v("- "),_c('strong',{staticStyle:{"color":"#fff5af"}},[_vm._v("高效审理，3个月获签，快速登陆美国")]),_vm._v("；"),_c('br'),_vm._v(" - 投资人21岁以下子女享免费公立中小学教育，读大学享州内学费待遇；（公立/私立/寄宿学校自由选择，"),_c('strong',{staticStyle:{"color":"#fff5af"}},[_vm._v("择校面更广，更低学费成本享更优质教育资源")]),_vm._v("）；"),_c('br'),_vm._v(" - 投资人的配偶可申请在美国境内任何地方就业；"),_c('br'),_vm._v(" - 投资人及其家属可在美国境内居留而无须针对全球收入报税；"),_c('br'),_vm._v(" - 只要商业活动持续满足相关要求，投资人即可续签美国E-2签证并在美国居留。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row w1180"},[_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"height-big text-big"},[_vm._v(" 2018年9月18日《土耳其国籍法第5901号修正案》，修改了获得公民身份和居留许可资格的投资金额，由之前的投资100万美元房产降至25万美元或等值里拉金额。")]),_c('p',{staticClass:"height-big text-big"},[_c('strong',[_vm._v("·")]),_vm._v(" 年满18周岁"),_c('br'),_c('strong',[_vm._v("·")]),_vm._v(" 配偶和未满18周岁的子女可随同申请"),_c('br'),_c('strong',[_vm._v("·")]),_vm._v(" 购买至少价值25万美金的不动产"),_c('br'),_c('strong',[_vm._v("·")]),_vm._v(" 3年后可出售 ")])]),_c('div',{staticClass:"col-6 p-3"},[_c('img',{staticClass:"w-100",attrs:{"src":require("../assets/turkey/11.png"),"alt":""}})])])
}]

export { render, staticRenderFns }