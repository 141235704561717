<template>
  <div>
    <img src="../assets/australiazt/1.jpg" alt="" class="w-100">
    <div class="layout d-flex justify-content-center w-100">
      <div style="width: 1180px;">
        <div class="py-4" style="margin-left: 40%;">
          <h1 style="color:#7A6A58">
            澳大利亚移民优势-总有一款适合您
          </h1>
          <p class="margin-large-top height-big f14">
            澳大利亚是一个高度发达的资本主义国家，首都为堪培拉。作为南半球经济发达的国家和全球第12大经济体、全球第四大农产品出口国，澳大利亚矿产资源丰富，多种矿产出口全球，因此被称为“坐在矿车上的国家”，同时也因绵羊数量和出口羊毛众多而被称为“骑在羊背上的国家”。澳大利亚人口高度都市化，近一半国民居住在悉尼和墨尔本两大城市。澳大利亚积极参与国际事务，是亚太经合组织的创始成员，也是联合国、20国集团、英联邦、太平洋安全保障条约、经济合作与发展组织及太平洋岛国论坛的成员。</p>

          <p class="height-big f14">澳大利亚是典型的移民国家，已先后有来自世界120个国家、140个民族的移民到澳大利亚谋生和发展。
            澳大利亚国土包括澳大利亚大陆，塔斯曼尼亚岛以及数个海外的岛屿，面积和美国本土相似，是世界上唯一一个国土覆盖整个大陆的国家。与其隔海相望的东南近邻是新西兰，西北是印度尼西亚，北边是巴布亚新几内亚、西巴布亚及东帝汶。
          </p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center w-100 my-4">
      <div class="w1180 d-flex flex-wrap justify-content-between">
        <div class="adly_zz bg1">
          <h2>移民大国</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
        <div class="adly_zz ">
          <h2>优厚福利</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
        <div class="adly_zz ">
          <h2>宜旅宜居</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
        <div class="adly_zz ">
          <h2>轻松易得</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
        <div class="adly_zz ">
          <h2>高成功率</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
        <div class="adly_zz bg2">
          <h2>一举两得</h2>
          <p class="f14 mt-3">1981年实行商业移民政<br>策，每年接受数十万移民</p>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center w-100 layout1 ">
      <div class="w1180 d-flex py-5 align-items-stretch justify-content-between">
        <div class="col-6 d-flex flex-column" style="padding:0 50px;">
          <img src="../assets/australiazt/6.jpg" class="w-100 aligncenter">
          <p class="flex-grow-1" style="background-color:#961F2B; color:#fff; line-height:30px; padding:10px 15px;">
            澳大利亚的教育在世界前100所大学排名中，有7所入围，教育水平与英美齐名。澳洲的教育体制为十二年义务教育，和中国一样，在教学内容上，公立和私立学校几乎没有什么不同。私立学校的资金来源广泛，财力雄厚，使得其师资力量强、教学环境好、教学设施先进等等。在学费方面，私立学校较之公立学校贵些。例如，公立学校高中十至十二年级的学费一般为1.2万澳元/年，而相应的私立学校一般为2万至3万澳元不等。
          </p>
        </div>
        <div class="col-6 d-flex flex-column" style="padding:0 50px;">
          <img src="../assets/australiazt/7.jpg" class="w-100 aligncenter">
          <p class="flex-grow-1" style="background-color:#961F2B; color:#fff; line-height:30px; padding:10px 15px;">
            澳大利亚实行全国健康保险制度，即医疗保健（Medicare）。国家以税收形式从国民收入中提取1.5％作为医疗基金。这种医疗保健制度包括支付患者在公立医院的医疗费或医院外治疗的大部分医疗费，以及医师门诊费。州和地区政府对医院、学校、口腔、产妇和婴儿保健计划、职业保健与服务、疾病控制和健康检查等负有主要管理责任。<br><br><br>
          </p>
        </div>
      </div>
    </div>

    <div class="my-4">
      <img src="../assets/australiazt/8.jpg" alt="" class="w-100">

      <div class="container text-big">
        <div class="row py-3">
          <div class="col-4 p-4">
            <h2>
              <span class="adly-index">01</span>
              <span class="adly-tit">年龄要求</span>
            </h2>
            <p class="mt-3 text-big height-big" style="font-size:18px">
              主申请人年龄55周岁以下，<br>
              随行子女低于18周岁，或者18周岁以上23周岁以下无经济来源的，或者23周岁有智力缺陷的
            </p>
          </div>
          <div class="col-4 p-4 adly-border">
              <h2><span class="adly-index">02</span><span class="adly-tit">家庭净资产</span></h2>
              <p class="mt-3 height-big" style="font-size:18px">
                过去2个财年，夫妻名下家庭净<br>
                资产不低于225万澳币
              </p>
          </div>
          <div class="col-4 p-4">
              <h2><span class="adly-index">03</span><span class="adly-tit">管理经验</span></h2>
              <p class="mt-3  height-big" style="font-size:18px">
                3年以上一个或多个成功的企业管理经验或投资经验
              </p>
          </div>
        </div>
        <hr style="border-top:#999 dashed 1px; height:0" class="margin-large-top">
        <div class="row line-big padding-large-top">
          <div class="col-4 p-4">

              <h2><span class="adly-index">04</span><span class="adly-tit">投资要求</span></h2>
              <p class="mt-3 height-big" style="font-size:18px">
                过去5年至少有1年在企业中拥有10%以上股份或拥有至少150万澳币的投资
              </p>
          </div>
          <div class="col-4 p-4 adly-border">
              <h2><span class="adly-index">05</span><span class="adly-tit">职业评估</span></h2>
              <p class="mt-3  height-big" style="font-size:18px">
                商业甄选系统（EOI）打分不低于<br>65分
              </p>
          </div>
          <div class="col-4 p-4">
              <h2><span class="adly-index">06</span><span class="adly-tit">政府债券</span></h2>
              <p class="mt-3 text-big height-big" style="font-size:18px">
                投资150万澳币到澳洲购买政府指定债券金。
              </p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/australiazt/10.jpg" alt="" class="w-100">
      <img src="../assets/australiazt/11.jpg" alt="" class="my-3">

      <div class="line-big mt-4 row">
        <div class="col-4">
          <div class="padding-large">
            <h2><span class="adly-index">01</span><span class="adly-tit">家庭净资产</span></h2>
            <p class="margin-large-top text-big height-big" style="font-size:18px">
              夫妻名下个人及家庭资产不低<br>于500万澳币
            </p>
          </div>
        </div><!--x4-->
        <div class="col-8 adly-border-l">
          <div class="padding-large">
            <h2><span class="adly-index">06</span><span class="adly-tit">投资要求</span></h2>
            <p class="margin-large-top text-big height-big" style="font-size:18px">
              投资500万澳币到澳洲指定投资产品：<br>
              1：至少50万澳币投资至风险投资／私人股本基金，此类基金专注初创和小型非上市企业；<br>
              2：至少150万澳币投资至合规基金，此类基金必须专注于投资在澳洲证券交易所(ASX)上市的新兴企业；<br>
              3：至少300万澳币投资至平衡类基金。
            </p>
          </div>
        </div>
      </div>

      <img src="../assets/australiazt/12.jpg" alt="" class="my-3 w-100">
      <img src="../assets/australiazt/13.jpg" alt="" class="my-3">
    </div>
    <img src="../assets/australiazt/14.jpg" alt="" class="my-3 w-100">

    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "australiazt"
}
</script>

<style scoped>
.padding-large {
  padding: 30px;
}

.padding-large-top {
  padding-top: 30px;
}
.adly-border{
  border-left:#999 dashed 1px;
  border-right:#999 dashed 1px;
}
.adly-border-l{
  border-left:#999 dashed 1px;
}
.adly-index{
  background-color:#958177;
  padding:7px;
  font-size:28px;
  font-weight:bold;
  color:#fff;
}
.adly-tit{
  color:#2D3670;
  padding-left:30px;
  font-weight:bold;
  font-size:28px;
}

.bg1 {
  background: url('../assets/australiazt/3.jpg') no-repeat;
  background-size: cover;
}

.bg2 {
  background: url('../assets/australiazt/4.jpg') no-repeat;
  background-size: cover;
}

.adly_zz {
  margin-bottom: 22px;
  width: 328px;
  height: 328px;
  background-color: #961F2B;
  text-align: center;
  vertical-align: middle;
  padding-top: 100px;
  color: #fff;
}

.layout {
  background: url('../assets/australiazt/2.jpg') no-repeat center center;
  background-size: cover;
}

.layout1 {
  background: url('../assets/australiazt/5.jpg') no-repeat center center;
  background-size: cover;
}
</style>