<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/hres/1.jpg" alt="" class="w-100">
    <div class="w-100 py-3 text-white d-flex justify-content-center top-0"
         :class="{'position-fixed':float}"
         ref="nav"
         style="background-color: #9e804a;font-size: 24px">
      <a href="#02" class="item">哈德逊河滨豪庭</a>
      <a href="#06" class="item">投资结构</a>
      <a href="#08" class="item">团队阵容</a>
      <a href="#36" class="item">十城盛大发布</a>
    </div>

    <div class="w1180 d-flex my-4" style="background-color: #f8f8f8;" id="02">
      <div class="col-3 d-flex justify-content-center align-items-center" style="background-color:#6a9ddf;">
        <img src="../assets/hres/2.jpg" alt="">
      </div>
      <div>
        <div class="p-2">
          <p class="height-big">哈德逊河滨豪庭是哈德逊城市广场配套楼宇的点睛之作。作为全美知名私营地产开发项目，哈德逊城市广场可以说是纽约耀眼的在建工程。因此，作为配套豪宅的纽约哈德逊河滨豪庭(Hudson
            Residences)，毫无疑问成为了全城期待的瑞联豪宅新作。</p>

          <p class="height-big">哈德逊河滨豪庭由两栋物业组成：云庭(515 West 18th St)和水庭(555 West 22nd
            St)，两栋物业均由瑞联集团主持规划。物业所属的切尔西区，长久以来就是纽约豪宅的代名词。这里及周边地区包含许多城市景点，方便住客前往纽约的轨交枢纽；同时，项目紧邻高线公园(High
            Line Park)、哈德逊河滨公园和哈德逊林荫道公园，与大自然近在咫尺。</p>

          <p class="height-big">
            值得一提的是，纽约地铁7号线延伸段-哈德逊城市广场站已经通车，这为纽约哈德逊河滨豪庭的住客再添便利。</p>
        </div>
      </div>
    </div>

    <img src="../assets/hres/3.jpg" alt="" class="">

    <div class="w1180 overflow-hidden position-relative my-4" style="height: 255px">
      <div class="position-absolute d-flex ani" style="top: 0;left:0">
        <img class="mx-1" :src="item" alt="" v-for="(item ,index) in list" :key="index">
        <img class="mx-1" :src="item" alt="" v-for="(item ,index) in list" :key="'listC'+index">
      </div>
    </div>
    <div class="w1180 d-flex align-items-center my-4" id="06" style="background-color:#e7e7e7 ">
      <div class="p-4" style="background-color:#6a9ddf ">
        <img src="../assets/hres/4.jpg" alt="">
      </div>
      <div class="flex-grow-1 h-100">
        <img src="../assets/hres/5.jpg" alt=" " class="w-100">
      </div>
    </div>
    <img src="../assets/hres/6.jpg" alt="" class="">


    <img src="../assets/hres/9.jpg" alt="" class="my-4">
    <img src="../assets/hres/10.png" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list2" :key="'list2'+index">
          <img :src="item" class="w-100" alt="">
        </div>

      </div>
      <div class="col-5">
        <p class="height-big">瑞联集团(RELATED)，总部位于纽约，成立于1972年，迄今已逾40年，现已发展成为美国知名私营开发商，是纽约开发行业的领头巨擘。
          在创始人Stephen M.
          Ross的带领下，瑞联集团的业务和办公室已遍及波士顿、芝加哥、洛杉矶、旧金山、佛罗里达、华盛顿、阿布扎比、伦敦、巴西圣保罗和上海。</p>
        <p class="height-big">
          · 全球雇员超过3000人，旗下管理着超过300亿美元的物业资产；<br>· 在全美19个州开发了超过50,000套高端住宅公寓；<br>
          · 仅纽约即拥有或管理超过60栋大楼，其中包括著名的纽约时代华纳中心(Time Warner Building)、纽约麦迪逊广场1号等；<br>
          · 旗下EB-5区域中心已招募数千位投资人，其中逾千位投资人已获I-526条件绿卡批准。</p>
      </div>
    </div>
    <div class="w1180 row my-4">
      <div class="col-6 d-flex flex-column align-items-center">
        <img src="../assets/hres/hres_18.png" alt="">
        <img src="../assets/hres/hres_20.png" alt="">
        <div class="row w-100 my-4">
          <div class="col-4 p-1" v-for="(item,index) in list3" :key="'list3'+index">
            <img :src="item" class="w-100" alt="">
          </div>
        </div>
      </div>
      <div class="col-6 d-flex flex-column align-items-center">
        <img src="../assets/hres/hres_19.png" alt="">
        <img src="../assets/hres/hres_21.png" alt="">
        <div class="row w-100 my-4">
          <div class="col-4 p-1" v-for="(item,index) in list4" :key="'list4'+index">
            <img :src="item" class="w-100" alt="">
          </div>
        </div>
      </div>


    </div>

    <LiveBox id="36"/>
  </div>
</template>

<script>
export default {
  name: "hres",
  mounted() {
    window.removeEventListener('scroll',this.handleScroll)
    window.addEventListener('scroll',this.handleScroll)
    this.top = this.$refs.nav.offsetTop
  },
  data() {
    return {
      float:false,
      top:0,
      list: [
        require('../assets/hres/hres1.jpg'),
        require('../assets/hres/hres2.jpg'),
        require('../assets/hres/hres3.jpg'),
        require('../assets/hres/hres4.jpg'),
        require('../assets/hres/hres7.jpg'),
        require('../assets/hres/hres8.jpg'),
        require('../assets/hres/hres9.jpg'),
      ],
      list2: [
        require('../assets/hres/hres_12.png'),
        require('../assets/hres/hres_13.png'),
        require('../assets/hres/hres_14.jpg'),
        require('../assets/hres/hres_15.png'),
        require('../assets/hres/hres_16.png'),
        require('../assets/hres/hres_17.jpg'),
      ],
      list3: [
        require('../assets/hres/hres_25.png'),
        require('../assets/hres/hres_26.png'),
        require('../assets/hres/hres_27.png'),
        require('../assets/hres/hres_31.png'),
        require('../assets/hres/hres_32.png'),
        require('../assets/hres/hres_33.png'),
      ],
      list4: [
        require('../assets/hres/hres_22.png'),
        require('../assets/hres/hres_23.png'),
        require('../assets/hres/hres_24.png'),
        require('../assets/hres/hres_28.png'),
        require('../assets/hres/hres_29.png'),
        require('../assets/hres/hres_30.png'),
      ]
    }
  },
  methods:{
    handleScroll(){
      if (document.documentElement.scrollTop >    this.top){
        this.float=  true
      }else{
        this.float=  false
      }
    }
  }
}
</script>

<style scoped>
.ani {
  animation-name: move;
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.ani:hover {
  animation-play-state: paused;
}

@keyframes move {
  0% {
    left: 0;
  }
  100% {
    left: -1871px;
  }
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.item {
  color: #FFFFFF;
  padding: 0 16px;

}

.item:nth-child(n+2) {
  border-left: 1px #FFF solid;
}
</style>