<template>
  <div>
<!--    <img src="../assets/eb5profile/easy01.jpg" alt="" width="100%">-->

    <div class="d-flex flex-column align-items-center" style="background-color: #ecebe9">

      <div class="line mt-4 mt-5 my-4">
        <div class="title-box">
          <img src="../assets/eb5profile/teb5_20.png">
          <span class="eb5-title">EB-5政策解读</span>
          <img src="../assets/eb5profile/teb5_21.png">
        </div>
      </div>

      <div class="d-flex bg-white my-3">
        <img src="../assets/eb5profile/eb5profile_03.jpg" class="img-responsive aligncenter" width="16%">
        <div class="x10" style="width: 900px">
          <p class="p-3">美国EB-5投资移民是指美国移民法中EB-5签证类别，全称 Employment Based Fifth
            Preference，是针对海外投资人所设立的移民类别。自1990年创设以来，EB-5法案历经三次修订，现已成为所有移民类别中，审批时间短、条件限制较少的一条移民捷径之一。</p>
          <p class="p-3">美国时间2022年3月15日EB-5区域中心计划正式重启，将目标就业区(TEA)的投资提高到80万美元，非目标就业区的投资提高到105万美元，新规已于正式生效。新政解读：<a
              href="/eb5newlaw">EB-5利好|2022美国投资移民新法生效</a></p>
        </div>
      </div>



      <img src="../assets/eb5profile/07.jpg" class="w1180">
      <div class="line mt-4 mt-5 my-4">
        <div class="title-box">
          <img src="../assets/eb5profile/teb5_20.png">
          <span class="eb5-title ">美国移民优势&移民后福利</span>
          <img src="../assets/eb5profile/teb5_21.png">
        </div>
      </div>
      <img src="../assets/eb5profile/10.jpg" class="w1180">

      <div class="line mt-4 mt-5 my-4">
        <div class="title-box">
          <img src="../assets/eb5profile/teb5_20.png">
          <span class="eb5-title ">美国EB-5申请流程</span>
          <img src="../assets/eb5profile/teb5_21.png">
        </div>
      </div>
      <img src="../assets/eb5profile/12.jpg" class="w1180 my-5">
    </div>




    <div style="height:20px;background-color: #ecebe9">
    </div>

    <div class="d-flex flex-column align-items-center">
      <div class="line  mt-5 my-4">
        <div class="title-box">
          <img src="../assets/eb5profile/teb5_20.png">
          <span class="eb5-title mx-5">美国EB-5申请通过率</span>
          <img src="../assets/eb5profile/teb5_21.png">
        </div>
      </div>
      <img src="../assets/n2.png">
    </div>

    <div class="d-flex flex-column align-items-center" style="background-color: #ecebe9">
      <div class="line  mt-5 my-4">
        <div class=" title-box">
          <img src="../assets/eb5profile/teb5_20.png">
          <span class="eb5-title mx-5">精品美国EB-5项目</span>
          <img src="../assets/eb5profile/teb5_21.png">
        </div>
      </div>
      <router-link to="/noodles">
        <img src="../assets/noodles/banner.jpg" class="w-100">
      </router-link>
    </div>

    <div style="background-color: #ecebe9">
      <div class="container text-big">

        <div class="line d-flex justify-content-center mt-4 py-4">
          <div class="title-box">
            <img src="../assets/eb5profile/teb5_20.png">
            <span class="eb5-title">美国EB-5年鉴</span>
            <img src="../assets/eb5profile/teb5_21.png">
          </div>
        </div>

        <div class="d-flex">
          <div class="x9">
            <div class="d-flex years">
              <div class="float-left left text-center">
                <div class="tit">2022</div>
                <span class="btn">2022新法生效</span>
              </div>
              <div class="right float-right">
                北京时间2022年3月15日，美国国会通过了《2022
                EB-5改革和诚信法案》，EB-5投资移民计划续法5年至2027年，同时，在投资额提高、审理优先级排序、配额分配等中国投资人关心的重要议题上，均给出明确立法。点击查看<a
                  href="/eb5newlaw">2022新法生效，美国EB-5投资移民新时代来临</a>
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2019</div>
                <span class="btn">新规出台 大幅涨价</span>
              </div>
              <div class="right float-right">
                美国当地时间7月24日在《联邦公报》上发布最终EB-5条款,新法规生效日期为2019年11月21日。最终的改革条款主要包括增加投资额：TEA地区投资额涨为90万美元；非TEA地区投资额涨为180万美元；政府将每5年根据城市消费指数调整投资金额。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2018</div>
                <span class="btn">优选项目 冲破排期</span>
              </div>
              <div class="right float-right">
                在美国当地时间2018年3月20日，美国EB-5投资移民行业协会IIUSA也正式发表声明，宣布EB-5法案将搭车3月23日的美国财政综合预算法案，继续延期到9月30日。EB-5申请人仍可以现行的50万美元投资额移民美国。冲破排期已经成为区域中心和客户关心的核心问题。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2015</div>
                <span class="btn">涨价/排期</span>
              </div>
              <div class="float-right right">
                随着EB-5申请数量的不断增加，美国年度签证配额面临着“供不应求”的窘境，并于2015年5月1日起开始了历史首次“排期”。随着两院的不断呼吁，美国EB-5涨价已经不可避免。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2011</div>
                <span class="btn">I-829永久绿卡获批</span>
              </div>
              <div class="float-right right">
                在I-526条件绿卡2年到期前3个月（即登陆21个月后），投资人需向移民局申请卸除条件，并换发10年无条件绿卡（即I-829）。根据移民局公布的数据，I-829批准率近99%,
                投资人大可放心。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2010</div>
                <span class="btn">区域中心激增</span>
              </div>
              <div class="float-right right">
                区域中心（Regional
                Center）并非政府机构，它是由移民局批准吸收海外资金用于EB-5投资的资本管理机构。2010年随着EB-5的走俏，区域中心数量井喷。截至2015年6月1日，区域中心已发展至676家。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2009</div>
                <span class="btn">TEA锐减</span>
              </div>
              <div class="float-right right">
                TEA-Target Employment Area，即目标就业区，指失业率为平均失业率1.5倍的区域。近年来，EB-5新增就业使美国TEA锐减。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">2008</div>
                <span class="btn">I-526批准骤增</span>
              </div>
              <div class="float-right right">
                2008年是 “吃螃蟹”的投资者欢呼雀跃的一年，EB-5里程碑I-526绿卡批准。I-526为两年条件绿卡，福利与公民相同，但资金不可撤离，项目必须运行，2年到期前换取无条件绿卡。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">1993</div>
                <span class="btn">通过区域中心法案</span>
              </div>
              <div class="float-right right">
                1993年，美国国会审议并通过增加区域中心（Regional Center）移民法案，投资额由100万美元降至50万美元，10个就业机会的创造由直接创造变为间接创造。
              </div>
            </div><!---years--->
            <div class="years">
              <div class="float-left left text-center">
                <div class="tit">1990</div>
                <span class="btn">确立EB-5临时法案</span>
              </div>
              <div class="float-right right">
                为了创造更多就业机会，刺激本土经济增长，美国国会确立了美国EB-5美国区域中心临时移民法案，要求申请人投资100万美元在美国创建新企业或直接购买企业，并创造10个直接就业机会，即可全家移民。
              </div>
            </div><!---years--->
          </div>
          <div class="x3 text-center text-white p-3" style="background-color:#005180;">
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_12.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> 目标就业区(TEA)批准信样本
            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_13.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> 区域中心批准信样本

            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_14.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> 国家利益优先批准信样本

            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_15.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> I-924模范认证批准信样本

            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_16.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> I-526条件绿卡批准信样本

            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_17.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> I-829永久绿卡批准信样本

            </div><!---x12--->
            <div class="x12 mt-4">
              <img src="../assets/eb5profile/teb5_18.jpg" class="img-responsive aligncenter">
              <span class="icon-caret-up"></span> 美国绿卡样本

            </div><!---x12--->
          </div>
        </div><!--line-big -->
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "eb5profile"
}
</script>

<style scoped>
.title-box{
  display: flex;
  width: 600px;
  align-items: center;
  justify-content: space-between;
}
.icon-caret-up:before {
  content: "▲";
}

.img-responsive {
  width: 260px;

}

.icon-caret-up {

}

.left {
  width: 25%;
}

.right {
  width: 70%;
}

.years {
  display: flex;

  border: solid 1px #759eb4;
  border-radius: 10px;
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.tit {
  font-size: 56px;
  font-weight: bold;
  color: #343434;
  line-height: 56px;
  margin-bottom: 5px;
}

.btn {
  background-color: #c6a363;
  border-radius: 5px;
  padding: 10px 15px;
  color: #fff;
}

.eb5-title {
  color: #005180;
  font-size: 35px;
  margin: 0 15px;
}
</style>