<template>
  <div class="w-100">
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="(item,index) in banner" :key="index">
        <router-link :to="item.url">
          <img :src="item.img" alt="" class="w-100"  >
        </router-link>
      </el-carousel-item>
    </el-carousel>


    <div class="d-flex flex-column align-items-center">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 在售项目 -</strong></span>
      </div>
      <div class="d-flex" style="width: 1300px;">
        <router-link tag="div" :to="item.url" class="m-3" v-for="(item,index) in list2" :key="index">
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large" v-html=" item.name "></strong>
              </p>
              <p v-html=" item.content "></p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>


      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 美国移民·跃迁优选 -</strong></span>
      </div>
      <div class="d-flex" style="width: 1300px;">
        <router-link tag="div" :to="item.url" class="m-3" v-for="(item,index) in list1" :key="index">
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large">{{ item.name }}</strong>
              </p>
              <p>{{ item.content }}</p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>


<!--      <div class=" text-center mt-3">-->
<!--        <span style="font-size: 24px;"><strong>- 过往项目 -</strong></span>-->
<!--      </div>-->
<!--      <div class="d-flex flex-wrap" style="width: 1300px;">-->
<!--        <router-link tag="div" :to="item.url" class="m-3" v-for="(item,index) in list" :key="index">-->
<!--          <div class="pic-box">-->
<!--            <img :src="getImg(index)" class="aligncenter img-responsive">-->
<!--            <div class="txt-box1">-->
<!--              <p>-->
<!--                <strong class="text-large">{{ item.name }}</strong>-->
<!--              </p>-->
<!--              <p>{{ item.content }}</p>-->
<!--              <p></p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
    </div>


  </div>
</template>

<script>
export default {
  name: "usa",
  data() {
    return {
      banner: [
        {
          img: require('../assets/001.jpg'),
          url: '/eb5newlaw',
        },
        {
          img: require('../assets/noodles/banner.jpg'),
          url: '/noodles',
        },
        {
          img: require('../assets/BurgerKing/banner.jpg'),
          url: '/burger_king',
        },
        {
          img: require('../assets/EB1A海报.jpg'),
          url: '/eb1a',
        },
        {
          img: require('../assets/美国E-2签证海报.jpg'),
          url: '/passport',
        },


      ],
      list: [
        {
          name: "洛杉矶星光天地7期",
          content: "承袭前6期成功记录，已获I-924模范认证批准",
          url: "/rmh",
        },
        {
          name: "好莱坞星光天地6期",
          content: "获美国移民局 I-924 模范认证批准",
          url: "/hd6",
        },
        {
          name: "休斯顿爱伦中心",
          content: "中美强强联手，打造精品绿卡项目",
          url: "/allen",
        },
        {
          name: "腾龙国际马会",
          content: "美国利益优先，I-526条件绿卡2个月获批",
          url: "/tiec",
        },
        {
          name: "纽约哈德逊河滨豪庭",
          content: "预期4%年化收益，获I-526 & I-924双批准",
          url: "/hres",
        }, {
          name: "中央公园壹号",
          content: "获I-526条件绿卡 及 I-924模范认证双批准",
          url: "/cpt",
        }, {
          name: "哈德逊城市广场3期",
          content: "获移民局I-526条件绿卡 & I-924模范认证双批准",
          url: "/hyp3",
        }, {
          name: "纽约帝国万豪酒店",
          content: "获美国移民局I-526批准",
          url: "/empirestatemarriot",
        }, {
          name: "波士顿四季酒店",
          content: "获I-526条件绿卡 及 I-924模范认证双批准",
          url: "/bfs",
        }, {
          name: "纽约时代广场万豪酒店",
          content: "酒店已开业，获美国移民局I-924模范认证批准！",
          url: "/tsm",
        }, {
          name: "加州贝景湾",
          content: "获美国移民局I-924模范认证批准！",
          url: "/obb",
        }, {
          name: "哈德逊广场2期",
          content: "获美国移民局I-526及I-924双批准",
          url: "/hymt",
        }
      ],
      list1: [
        {
          name: "2022移民法案新政出台",
          content: "乡村EB5项目将成为“香饽饽”",
          url: "/eb5newlaw",
          img: require('../assets/usa/jutou.jpg')
        },
        {
          name: "EB-5概述",
          content: "让你五分钟了解美国移民",
          url: "/eb5profile",
          img: require('../assets/usa/EB-5zsk.jpg')
        },
        {
          name: "美国移民",
          content: "如何选择合适的项目",
          url: "/America",
          img: require('../assets/usa/sprint-1.jpg')
        }
      ],
      list2: [
        // {
        //   name: "纳斯达克上市企业 <br>  Noodles & Company",
        //   content: "EB-5乡村项目 <br> 80万投资款 无排期 审批快",
        //   url: "/noodles",
        //   img: require('../assets/usa/改图21.jpg')
        // },
        {
          name: "汉堡王EB-1C项目",
          content: "无排期 最快45天 一步到位全家永绿",
          url: "/burger_king",
          img: require('../assets/usa/改图22.jpg')
        },
        {
          name: "美国杰出人才移民",
          content: "无需投资 费用低 周期短",
          url: "/eb1a",
          img: require('../assets/usa/改图23.jpg')
        },
      ],
    }
  }, methods: {
    getImg(i) {
      return require('../assets/usa/' + (i + 3) + '.jpg')
    },
    goBanner(id) {
      let list = ["/eb5newlaw", "/oneandonly", "/oneandonly", "/fsno", "/usa"]
      this.$router.push(list[id])
    }
  }
}
</script>

<style scoped>
.txt-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: bottom;
  color: #fff;
}

.pic-box {
  cursor: pointer;
  position: relative;
}

.text-large {
  font-size: 24px;
}

.pic-box:hover {
  box-shadow: 0 0 10px #000;
}

</style>