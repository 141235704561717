<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/hyp3/1.jpg" alt="" class="w-100">
    <img src="../assets/hyp3/2.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-4">
        <img src="../assets/hyp3/3.jpg" alt="" class="">
      </div>
      <div class="col-4">
        <img src="../assets/hyp3/4.jpg" alt="" class="">
      </div>
      <div class="col-4">
        <img src="../assets/hyp3/5.jpg" alt="" class="">
      </div>
      <div class="col-3">
        <img src="../assets/hyp3/6.jpg" alt="" class="w-100">
      </div>
      <div class="col-3">
        <img src="../assets/hyp3/7.jpg" alt="" class="w-100">
      </div>
      <div class="col-3">
        <img src="../assets/hyp3/8.jpg" alt="" class="w-100">
      </div>
      <div class="col-3">
        <img src="../assets/hyp3/9.jpg" alt="" class="w-100">
      </div>
    </div>
    <img src="../assets/hyp3/10.jpg" alt="" class="my-4">
    <img src="../assets/hyp3/11.jpg" alt="" class="my-4">
    <img src="../assets/hyp3/12.jpg" alt="" class="my-4">
    <img src="../assets/hyp3/13.jpg" alt="" class="my-4">
    <img src="../assets/hyp3/14.jpg" alt="" class="my-4">
    <img src="../assets/hyp3/15.jpg" alt="" class="my-4">
    <div class="w-100 d-flex justify-content-center" style="background-color: #d1d1d1">
      <img src="../assets/hyp3/16.jpg" alt="" class="my-4">
    </div>
    <img src="../assets/hyp3/18.jpg" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list" :key="'list'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>
      <div class="col-5">

        <p class="height-big">瑞联集团(RELATED)，总部位于纽约，成立于1972年，迄今已逾40年，现已发展成为美国知名私营开发商，是纽约开发行业的领头巨擘。
          在创始人Stephen M.
          Ross的带领下，瑞联集团的业务和办公室已遍及波士顿、芝加哥、洛杉矶、旧金山、佛罗里达、华盛顿、阿布扎比、伦敦、巴西圣保罗和上海。</p>
        <p class="height-big">
          · 全球雇员超过3000人，旗下管理着超过300亿美元的物业资产；<br>· 在全美19个州开发了超过50,000套高端住宅公寓；<br>
          · 仅纽约即拥有或管理超过60栋大楼，其中包括著名的纽约时代华纳中心(Time Warner Building)、纽约麦迪逊广场1号等；<br>
          · 旗下EB-5区域中心已招募数千位投资人，其中逾千位投资人已获I-526条件绿卡批准。</p>

      </div>
    </div>
    <img src="../assets/hyp3/25.jpg" alt="" class="my-4">
    <div class="w1180 row my-4">
      <div class="col-5">
        <p class="height-big margin-large-top">牛津物业集团(OXFORD)，于1960年成立于加拿大，隶属于安大略市民退休基金(OMERS), 是加拿大，也是全球知名的物业集团之一，业务覆盖私人住宅、商业楼宇、综合楼宇及酒店开发等，在美国、英国均设有分部，雇员人数超过2,000名，旗下拥有或管理超过370亿美元的不动产。</p>
        <p class="height-big">牛津物业集团，以稳妥投资著称，在加拿大、美国和英国投资开发了多个著名项目，与瑞联集团在哈德逊城市广场项目上的联手，也必将使哈德逊城市广场成为纽约的经典地标。</p>
      </div>
      <div class="col-7 row">
        <div class="col-4 p-1" v-for="(item,index) in list1" :key="'list1'+index">
          <img :src="item" class="w-100" alt="">
        </div>
      </div>

    </div>
    <LiveBox  />
  </div>
</template>

<script>
export default {
  name: "hyp3",
  data() {
    return {
      list: [
        require('../assets/hyp3/19.jpg'),
        require('../assets/hyp3/20.jpg'),
        require('../assets/hyp3/21.jpg'),
        require('../assets/hyp3/22.jpg'),
        require('../assets/hyp3/23.jpg'),
        require('../assets/hyp3/24.jpg'),
      ],
      list1: [
        require('../assets/hyp3/26.jpg'),
        require('../assets/hyp3/27.jpg'),
        require('../assets/hyp3/28.jpg'),
        require('../assets/hyp3/29.jpg'),
        require('../assets/hyp3/30.jpg'),
        require('../assets/hyp3/31.jpg'),
      ]
    }
  }
}
</script>

<style scoped>

</style>