let list = [
    {
        title: "一锤定音！希腊购房移民门槛翻倍提高！",
        date: "2022-09-22",
        img: require('@/assets/news/26.png'),
        content: "当地时间9月10日下午，希腊总理米佐塔基斯在第86届塞萨洛尼基国际博览会上突然宣布：为了增加希腊当地人的可用房地产，计划将希腊“黄金签证”*的购房门槛由原来…",
        id:26
    },
    {
        title: "6月份新数据，葡萄牙移民获批人数创疫情以来单月新高！",
        date: "2022-07-13",
        img: require('@/assets/news/25.png'),
        content: "葡萄牙近期，葡萄牙移民局(SEF)公布了新的黄金签证数据，在2022年6月份，共计155位主申请人和204位附属申请人获批葡萄牙黄金签证，获批总人数为359人，总投资额…",
        id:25
    },
    // {
    //     title: "涨价！土耳其护照办理门槛或将从25万美元涨到40万美元",
    //     date: "2022-04-15",
    //     img: require('@/assets/news/24.png'),
    //     content: "随着塞浦路斯护照暂停，葡萄牙黄金签证的变政，欧洲各国的移民政策在不停的快速变化中。作为这两年移民界的宠儿土耳其护照项目也传来了涨价消息。 据报…",
    //     id:24
    // },
    {
        title: "重磅利好！EB-5正式重启，5月排期新鲜出炉",
        date: "2022-04-15",
        img: require('@/assets/news/23.png'),
        content: "（图片来源于https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html) 美国国务院签证办公室（NVC）公布了2022年5月份的签证公告…",
        id:23
    },
    {
        title: "移民局计划加速审批，提“三管齐下”改进方案",
        date: "2022-04-01",
        img: require('@/assets/news/22.png'),
        content: "美国时间2022年3月29日，美国移民局（USCIS）在官网发布了一则公告称，旨在改善移民申请的效率，并提出了三项措施。分别是：加快速度处理积案、扩大加急申…",
        id:22
    },
    {
        title: "加拿大移民局公布2022-2024移民目标，预计接收新移民人数再创新高！",
        date: "2022-02-18",
        img: require('@/assets/news/21.jpg'),
        content: "英国内政部于当地时间2月17日正式宣布，基于国家安全原因考量，对所有新申请人关闭投资移民签证的申请通道，立即生效。英国内政部同时声明，对2008年该计划…",
        id:21
    },
    {
        title: "加拿大移民局公布2022-2024移民目标，预计接收新移民人数再创新高！",
        date: "2022-02-15",
        img: require('@/assets/news/20.png'),
        content: "百万移民计划，不止是“百万” 加拿大当地时间2月14日，联邦移民局终于公布了2022-2024的移民计划，未来三年将接收超过120万名新移民。2022年的登陆目标为…",
        id:20
    },
    {
        title: "马耳他永居获批数据公布，87%的申请人来自中国",
        date: "2022-02-10",
        img: require('@/assets/news/19.jpg'),
        content: "87%申请人来自中国 近期，The Residency Malta Agency（马耳他居留签证管理局）分享了2016年至今马耳永居计划项目新旧政的获批数据，共2,273名主申请人…",
        id:19
    },
    {
        title: "注意！日本再次延长“禁止外国人入境”政策！",
        date: "2022-01-25",
        img: require('@/assets/news/18.png'),
        content: "“禁止外国人入境”政策持续到2月底 近日，由奥密克戎变异株扩散，日本首相宣布将布禁止外国旅客入境项政策延长到2月底（除非有特殊原因或因人道主义问题…",
        id:18
    },
    {
        title: "2021年希腊移民签证数据：累计获批近3万人！",
        date: "2022-01-20",
        img: require('@/assets/news/17.png'),
        content: "获批人数累计近三万人 根据希腊移民局新发布的黄金签证数据： 截止到2021年12月31日，2021年希腊累计向1035组投资人发放了黄金居留签证。累计获批主…",
        id:17
    },
    {
        title: "加拿大“百万移民计划”进行中，1年吸纳超40万名新移民",
        date: "2021-12-28",
        img: require('@/assets/news/16.png'),
        content: "再创历史，加拿大吸纳40.1万新移民 当地时间2021年12月23日，加拿大移民局官宣，今年已经实现年度目标吸收了401000新移民，这是加拿大移民历史上第二次达到4…",
        id:16
    },
    {
        title: "【爱尔兰投资移民】别担心！爱尔兰司法部再次宣布：移民许可延期！",
        date: "2021-12-27",
        img: require('@/assets/news/15.png'),
        content: "爱尔兰移民许可 现已统一延期到2022年5月31日 继上次爱尔兰司法部将移民及短期停留签证的居留许可统一延期至2022年1月15日之后，爱尔兰司法部部长 He…",
        id:15
    },
    {
        title: "希腊黄金签证项目审批效率大幅提升",
        date: "2021-11-22",
        img: require('@/assets/news/14.jpg'),
        content: "根据移民政策部（Migration Policy Ministry）的所发布的新数据显示，从今年年初到10月底，共签发了747张。希腊黄金签证发放率正在上升，仅上个月就发放了1…",
        id:14
    },
    {
        title: "埃尔多安：土耳其肯定会跻身世界前十大经济体行列",
        date: "2021-11-02",
        img: require('@/assets/news/13.png'),
        content: "10月23日，土耳其总统雷杰普·塔伊普·埃尔多安(Recep Tayyip Erdogan)表示，土耳其一定会实现成为世界十大经济体之一的目标。 埃尔多安：土耳其肯定会跻…",
        id:13
    },
    {
        title: "爱尔兰房产市场投资总额达35亿——内附进度更新",
        date: "2021-10-28",
        img: require('@/assets/news/12.jpg'),
        content: "爱尔兰市场前景持续被看好 得益于爱尔兰政府在疫苗上的大力推进，以及财政和货币支持政策，爱尔兰的经济表现令人鼓舞，市场前景持续看好。 在欧盟，…",
        id:12
    },
    {
        title: "这个黄金签证，又火了！获批量激增超300%",
        date: "2021-10-18",
        img: require('@/assets/news/11.jpg'),
        content: "在后疫情时代，越来越多的国际投资者将目光投向希腊，盛行多年的“黄金签证”计划，再度受到全球瞩目。 获批人数再创新高 根据希腊移民局发布的黄金签…",
        id:11
    },
    {
        title: "你一定想不到，去年有30万人拿到这个地方的永居",
        date: "2021-10-09",
        img: require('@/assets/news/10.jpg'),
        content: "近期，日本的出入国在留管理厅网站上发布了一项数据显示，2020年日本“在留外国人人数”数量大增，共有30万以上中国地区的申请人拿到了日本“永久居留”。 …",
        id:10
    },
    {
        title: "爱尔兰经济复苏远超预期，年底将恢复到疫情前的水平！",
        date: "2021-10-09",
        img: require('@/assets/news/9.jpg'),
        content: "爱尔兰经济复苏超预期 爱尔兰在近日发布的《季度公报》中称，爱尔兰经济或将在今年年底恢复到疫情前的水平。 爱尔兰央行也预测，今年的涡轮增压式增…",
        id:9
    },
    {
        title: "8月｜希腊入境规定再更新",
        date: "2021-08-23",
        img: require('@/assets/news/8.jpg'),
        content: "2021年8月19日至8月27日期间，以下新规适用于所有飞往希腊的国际航班： 自8月19日至8月27日6时，第三国（非欧盟国家）公民仍禁止进入希腊。 欧盟和申…",
        id:8
    },
    {
        title: "加拿大安省雇主担保移民",
        date: "2021-07-01",
        img: require('@/assets/news/7.jpg'),
        content: "项目简介： 2020 年 7 月 9 日，安省移民局官网公布安省雇主试点项目开始接受申请，为期两年，额外提供 150 个名额指定给安省的 3 个试点区域（城市）：…",
        id: 7
    },
    {
        title: "清北捷径”华侨生联考成绩出炉啦！超低分数线！提前部署才能吃上“热豆腐”！",
        date: "2021-06-24",
        img: require('@/assets/news/6.png'),
        content: " 预科线280分！ 本科线300分！ 一本线400分！ 艺术和体育的特长生 甚至低到了200分！ 2021 华侨生联考！    录取较低分数线-新鲜出炉啦！ 通往…",
        id: 6
    },
    {
        title: "想全面了解EB-1A杰出人才移民？看这篇文章就够了",
        date: "2021-05-06",
        img: require('@/assets/news/5.jpg'),
        content: "职业移民第一类优先（First Preference Employment Based Immigration，简称EB-1）又称“杰出人才”移民，是美国职业类移民申请中的一类，是美国移民法专门为在艺…",
        id: 5
    },
    {
        title: "葡萄牙移民局大变身，审理提速指日可待！",
        date: "2021-04-21",
        img: require('@/assets/news/4.png'),
        content: "葡萄牙移民局正式变身 葡萄牙政府部长理事会在《共和国日报》公布一项决议，外国人和边境服务局（SEF）于4月15日起正式进行重组，此前的SEF被外国人和难民…",
        id: 4
    },
    {
        title: "马耳他永居正式开启新时代",
        date: "2021-03-30",
        img: require('@/assets/news/3.jpg'),
        content: "马耳他项目作为欧洲老牌黄金签证项目之一，多年来颇受高净值人群青睐，去年，该项目即将迎来重大变动的消息不胫而走，此后便一直牵动人心。在今年1月12日，…",
        id: 3
    },
    {
        title: "加拿大“百万移民计划”进行中，1月迎来近25000名新移民",
        date: "2021-03-24",
        img: require('@/assets/news/2.jpg'),
        content: "在疫情期间，加拿大也没有停止接纳移民的步伐，“3年百万移民计划”也正在进行中。但由于疫情的影响和限制，加拿大去年仅接受了18.4万新移民，远未实现34.1万…",
        id: 2
    },
    {
        title: "加拿大安省企业家移民，投资20万加元起",
        date: "2020-12-30",
        img: require('@/assets/news/1.jpg'),
        content: "加拿大发布《2021-2023年移民计划》，准备接收有史以来超高人数的新移民，在未来3年吸纳超过123万移民： 2021年：预计接收永久居民40.1万 2022年：预…",
        id: 1
    },
]


export default list