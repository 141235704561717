<template>
  <div>
    <img src="../assets/nz/1.jpg" alt="" class="w-100">

    <div class="d-flex justify-content-center pb-4" style="margin-top: 80px; background-color:#78830b;">
      <div class="w1180 d-flex">
        <img src="../assets/nz/2.png" alt="" style="margin-top: -64px;">
        <div>
          <div class="x7 text-white ms-4" style="margin-top:50px;">
            <p>新西兰，又译纽西兰，是一个资本主义国家，政治体制实行君主立宪制混合英国式议会民主制，现为英联邦成员国之一 。
              新西兰是大洋洲美丽的国家之一，总计约有30%的国土为保护区。拥有3项世界遗产、14个国家公园、3座海洋公园、数百座自然保护区和生态区。
              新西兰的福利制度相当优越，全民免费医疗、住房补贴、失业救济、特殊群体救助、教育补贴应有俱有。政府关心着公民的各项生活，生老病死无一不照顾得非常细致。
              新西兰的教育沿袭了英式的教育体系，立足于培养学生的创造力和创新能力，以实用、现代、合乎需要在世界上享有盛名，新西兰高等学府拥用优秀的学术教育水平，许多专业居于世界领先地位，新西兰的学位在世界上是好的。</p>
            <p><br>新西兰不仅拥有举世无双的美景，同时世界银行也将新西兰列为世界上方便营商的国家之一，新西兰经济成功地从农业为主，转型为具有国际竞争力的工业化自由市场经济。
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="w1180 row py-4">
        <div class="col-9 margin-large-top">
          <p>
            1)、免费公立医疗(包括医药费、检查费、住院费、聘请中文翻译费等所有费用由国家负责)，到家庭医生看普通病症收门诊费，但六岁以下儿童无论公立还是私立医疗由国家负责，所有费用(包括药费全免)。贫困者看家庭医生减收门诊费，每样药至多只收3元，多余费用由国家负责。16岁以下孩子免费牙医治疗。</p>
          <p>
            2)、生育免费，从怀孕到生产的所有费用由国家负责，政府鼓励生育，除一次性奖励一笔金钱外，每生一个孩子由政府每周补贴养育费直至18岁。18周岁以上的孩子可以独立生活，享受青年人津贴。</p>
          <p>
            3)、免费中、小学教育，国立大学只需交象征性的低学费，定居两年后可以享受学生津贴，其金额需根据学生家庭人数，住房费用等补贴，足够全家生活开销。</p>
          <p>4)、定居两年享受失业救济金等新西兰公民享受的所有福利。</p>
          <p>5)、定居10年满65岁以上可以享受政府养老金。</p>
          <p>
            6)、成为新西兰公民后到所有欧美发达国家免签证，可以到澳大利亚定居、工作，享受除失业救济外的所有澳大利亚公民应享受的福利，可以到英国工作，退休后可以到英国、荷兰、比利时等国居住(退休金由新西兰政府支付)。</p>
        </div>
        <div class="col-3">
          <img src="../assets/nz/3.jpg" alt="" class="mt-5">
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="w1180 d-flex flex-column align-items-center py-4">
        <img src="../assets/nz/4.jpg" alt="">
        <img src="../assets/nz/5.jpg" alt="" class="my-4">

        <div class="line-big mt-4 w-100 row">
          <div class="col-6">
            <p><br>新西兰投资移民分为普通和高额投资两种，目前普通投资移民每年配额300名，以获批居留签为准。投资300万纽币四年即可获得新西兰永久绿卡，每年的申请者中80%均来自中国。</p>
            <p><br><span style="color:white;background-color:#fe7900;border-radius:8px;height:24px;line-height:24px;">&nbsp;签证类型&nbsp;</span>  ：临时居民<br><span style="color:white;background-color:#fe7900;border-radius:8px;height:24px;line-height:24px;">&nbsp;居住要求&nbsp;</span>  ：投资四年的后三年每年居住146天<br><span style="color:white;background-color:#fe7900;border-radius:8px;height:24px;line-height:24px;">&nbsp;税务&nbsp;</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ：非全球申报</p>
          </div>
          <div class="col-6 p-4 margin-large-top" style="background-color:#e8e8e8">
            <div class="x12"><img src="../assets/nz/nz_06.jpg" class="img-responsive alignleft"></div>
            <div class="x12 margin-large-top">
              <p>1.主申请人年龄65岁以下，拥有3年以上的经商管理经验</p>
              <p>2.主申请人雅思3分以上（G类）</p>
              <p>3.家庭资产300万纽币以上，证明资产来源的合法性</p>
              <p>4.投资300万纽币到新西兰4年，投资范围包括政府及企业债券、企业股权或房地产开发等</p>
              <p>5.主申请人在投资四年期内的最后三年每年住满146天</p>
            </div>
          </div>
        </div>
        <div class="line-big w-100 mt-5 row">
          <div class="col-6">
            <div class="x12"><img src="../assets/nz/nz_07.jpg" class="img-responsive alignleft"></div>
            <div class="x12 margin-large-top">
              <p>◆&nbsp;&nbsp;150万人民币资产，3年以上管理经验，雅思4分，3-6个月即可获批</p>
              <p>◆&nbsp;&nbsp;5年高管经验 或3年持股25%以上之股东管理经验</p>
              <p>◆&nbsp;&nbsp;综合打分满足120分，并递交与管理经验相关的商业计划书</p>
              <p>◆&nbsp;&nbsp;投资至少20万新币于当地创业并解释其合法来源，如果入股现有企业，持股不少于25%</p>
              <p>◆&nbsp;&nbsp;满足雅思平均4分之基本英语要求</p>
            </div>
          </div>
          <div class="col-6 text-center text-white">
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>签约、资料制作、递交至新西兰移民局</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>移民局审核客户资料签发12个月工作签证</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;border-radius:10px"><span>持有工作签证在新西兰投资创业<br>12个月内申请24个月工作签证</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>成功创业满2年申请居留权</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>持有居留权满2年申请永久居留权</span></div>
            <div class="x12 margin-top text-black text-small"><p>注：整体周期4-7个月获工作签证，2年半-3年获居留权，4年半-5年获永久居留权。</p></div>
          </div>

        </div>


        <div class="line-big w-100 mt-5 row">
          <div class="col-6 text-center text-white">
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>签约、资料制作、递交至新西兰移民局</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>移民局审核客户资料签发12个月工作签证</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;border-radius:10px"><span>持有工作签证在新西兰投资创业<br>满6个月后申请居留权</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>成功创业2年后申请永久居留权</span></div>
            <div class="x12 margin-top text-black text-small"><p>注：整体周期4-7个月获工作签证，12-16个月获居留权，2年半-3年获永久居留权。</p></div>
          </div>
          <div class="col-6 margin-large-top">
            <div class="x12"><img src="../assets/nz/nz_08.jpg" class="img-responsive alignleft"></div>
            <div class="x12 margin-large-top">
              <p>◆&nbsp;&nbsp;150万人民币资产，3年以上管理经验，雅思4分，3-6个月即可获批</p>
              <p>◆&nbsp;&nbsp;5年高管经验 或3年持股25%以上之股东管理经验</p>
              <p>◆&nbsp;&nbsp;综合打分满足120分，并递交与管理经验相关的商业计划书</p>
              <p>◆&nbsp;&nbsp;投资至少20万新币于当地创业并解释其合法来源，如果入股现有企业，持股不少于25%</p>
              <p>◆&nbsp;&nbsp;满足雅思平均4分之基本英语要求</p>
            </div>
          </div>
        </div>


        <div class="line-big mt-5 w-100 row">
          <div class="col-6">
            <div class="x12"><img src="../assets/nz/nz_09.jpg" class="img-responsive alignleft"></div>
            <div class="x12 margin-large-top">
              <p>◆&nbsp;&nbsp;年龄65岁以下，拥有3年以上经商管理经验；</p>
              <p>◆&nbsp;&nbsp;300万纽币以上家庭资产，解释300万纽币的合法来源；</p>
              <p>◆&nbsp;&nbsp;英语雅思3分；</p>
              <p>◆&nbsp;&nbsp;在新西兰投资300万纽币4年，后三年每年居住146天。</p>
            </div>
            <div class="x12 margin-large-top"><img src="../assets/nz/nz_10.jpg" class="img-responsive alignleft"></div>
          </div>
          <div class="col-6 text-center text-white">
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>签约、资料制作、递交至新西兰移民局</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>移民局审核客户资料，签发邀请函</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>递交全套资料至移民局</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>移民局审核资料，签发原则性批复</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;border-radius:10px"><span>申请人12个月内将投资款转到新西兰<br>完成投资，期间可以申请工作签证</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>完成投资后申请居留权</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>获得居留权后申请永久居留权</span></div>
            <div class="x12 margin-top text-black text-small"><p>注：整体周期10-18个月获居留权，5年-6年获永久居留权。</p></div>
          </div>
        </div>

        <div class="line-big w-100 mt-5 row">
          <div class="col-6 text-center text-white">
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>签约、资料制作、递交至新西兰移民局</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>移民局审核资料签发原则性批复</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;border-radius:10px"><span>申请人12个月内将投资款转到新西兰<br>完成投资后申请居留权</span></div>
            <div class="x12 margin-top margin-bottom"><img src="../assets/nz/nz_12.jpg" class="img-responsive aligncenter"></div>
            <div style="clear:both"></div>
            <div style="background-color:#78830b;width:63%;margin:0 auto;height:40px;line-height:40px;border-radius:10px"><span>获得居留权后申请永久居留权</span></div>
            <div class="x12 margin-top text-black text-small"><p>注：整体周期8-16个月获居留权，4年-5年获永久居留权。</p></div>
          </div>
          <div class="col-6 margin-large-top">
            <div class="x12"><img src="../assets/nz/nz_11.jpg" class="img-responsive alignleft"></div>
            <div class="x12 margin-large-top">
              <p>◆&nbsp;&nbsp;无英语，经商和年龄要求；</p>
              <p>◆&nbsp;&nbsp;1000万纽币以上家庭资产，并解释其合法来源；</p>
              <p>◆&nbsp;&nbsp;在新西兰投资1000万纽币3年，后两年每年居住44天。</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 justify-content-center d-flex" style="background-color:#307096;">

        <div class="py-4 layout w1180" >
          <img src="../assets/nz/7.png" alt="" class="mx-3 ">
          <div class="row w-100 mt-1">
            <div class="col-6">
              <div class="x12 text-white" style="padding-left:25px;">
                <p>1.免费登陆辅导；</p>
                <p>2.免费协助甲方开立新西兰澳新银行(ANZ)个人账户；</p>
                <p>3.免费推荐并可协助甲方预订首次登陆时入住的酒店；</p>
                <p>4.免费赠送新西兰手机卡一张；</p>
                <p>5.首次登陆时提供免费接机服务1次；</p>
                <p>6.免费协助甲方翻译国内驾照；</p>
                <p>7.免费协助甲方办理新西兰驾照；</p>
                <p>8.免费协助甲方购车；</p>
                <p>9.免费协助甲方申请个人税号(IRD No.)；</p>
                <p>10.免费协助甲方报名英语课程；</p>
                <p>11.根据甲方子女年龄和所在校区免费提供相应的学校信息；</p>
                <p>12.免费推荐华人医生；</p>
              </div>
            </div>
            <div class="col-6 text-white">
              <p>13.免费协助甲方购买私人医疗保险；</p>
              <p>14.免费推荐房产中介陪同甲方考察房产；</p>
              <p>15.免费安排ANZ银行客户经理为甲方介绍商业贷款和房产贷款流程；</p>
              <p>16.免费协助甲方联系当地会计师；</p>
              <p>17.免费协助甲方联系当地律师；</p>
              <p>18.免费推荐资深会计师事务所为甲方办理公司注册等手续；</p>
              <p>19.免费为甲方提供访问签证转签或延期的咨询服务；</p>
              <p>20.免费为甲方父母探亲提供咨询服务；</p>
              <p>21.免费提供入籍申请咨询服务。</p>
            </div>
          </div>
      </div>
    </div>

    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "nz"
}
</script>

<style scoped>
.layout{
  background:url('../assets/nz/6.jpg') no-repeat;
}
</style>