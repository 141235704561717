<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/empirestatemarriot/2.jpg" alt="" class="my-4">
    <div class="d-flex justify-content-center w-100" style="background-color: #173287">
      <img src="../assets/empirestatemarriot/3.jpg" alt="" class="">
    </div>
    <div class="d-flex justify-content-center w-100" style="background-color: #112472">
      <img src="../assets/empirestatemarriot/4.jpg" alt="" class="">
    </div>
    <div class="d-flex flex-column align-items-center w-100" style="background-color: #ededed">
      <img src="../assets/empirestatemarriot/5.jpg" alt="" class="">
      <img src="../assets/empirestatemarriot/6.jpg" alt="" class="">
    </div>
    <div class="w1180 row my-4">
      <div class="col-6">
        <img src="../assets/empirestatemarriot/7.jpg" alt="" class="my-4">
        <h2 class="height-big margin-big-top">纽约酒店市场</h2>
        <p><br>过去20年来，纽约始终是来自世界各地的游客们较为钟爱的旅游城市，同时金融中心的地位，也使得纽约成为商务客必经之地;酒店需求更是水涨船高，金融危机以来，纽约市的客房入住率平衡上升，并在2014年创造了89.4%的惊人记录。
        </p>
        <p class="height-big">
          纽约中城区一直以来，凭借优越的地理位置，无论是商务出行，还是休闲旅游，中城区都是个高端游客的必选下榻之地。</p>
        <p class="height-big">旺盛的酒店市场，杰出的地理位置，为纽约帝国万豪酒店带来了让人充满信心的盈利前景。</p>

      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/8.jpg" alt="" class="">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/9.jpg" alt="" class="">
      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/10.jpg" alt="" class="">
      </div>
    </div>
    <img src="../assets/empirestatemarriot/11.jpg" alt="" class="my-4">
    <img src="../assets/empirestatemarriot/12.jpg" alt="" class="">
    <div class="d-flex flex-column align-items-center w-100" style="background-color: #112472">
      <img src="../assets/empirestatemarriot/13.jpg" alt="" class="">
    </div>

    <div class="w1180 row my-4">
      <div class="col-6">
        <img src="../assets/empirestatemarriot/14.jpg" alt="" class="">
        <p class="height-big margin-big-top"><br>David
          Lichtenstein于1988年创立盛石集团，目前担任董事长兼首席执行官，致力于实现质量、诚信和价值的较高标准。Lichtenstein先生在房地产行业的领导能力和成功把握房地产机遇、时间和市场的能力在业内享有盛誉，
          也得到了政府机构的高度认可,同时兼任多个公共职务：</p>
        <p class="height-big">-纽约市市长任命Lichtenstein为纽约经济发展公司（NYCEDC）董事会成员<br>-房地产资本政策咨询委员会的联合主席/纽约不动产委员会理事会成员<br>
          -公民预算委员会信托人/纽约经济俱乐部&amp;房地产圆桌会议成员<br>
          -布鲁金斯学会经济研究委员会成员 / 托罗学院和大学系统信托人<br>
          -纽约医学院的董事会监督委员会成员
        </p>
        <p class="height-big">David Lichtenstein先生对纽约帝国万豪酒店项目充满信心，因此为EB-5投资人提供了私人还款担保，这也为EB-5
          投资人的资金提供了额外保障。</p>

      </div>
      <div class="col-6 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/15.jpg" alt="" class="">
      </div>
    </div>
    <div class="w1180 row my-4">
      <div class="col-5 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/17.jpg" alt="" class="">
      </div>
      <div class="col-7">
        <img src="../assets/empirestatemarriot/16.jpg" alt="" class="">

        <p><br>通过收购喜达屋酒店，万豪一跃成为世界一流酒店品牌。其在120个国家拥有近6000家酒店、110万个房间，以及7500万忠诚客户。<br>万豪集团以其强大的品牌声誉，全球庞大的订房系统及庞大的万豪礼赏忠诚客户，为旗下酒店的成功奠定坚实基础。
        </p>
        <p class="height-big margin-big-top">万豪MOXY酒店<br>万豪MOXY是万豪集团新的生活理念主题酒店。一个新颖具有创意的品牌，结合了时尚的设计和优秀的服务，兼具价格实惠的优势。
        </p>
      </div>
    </div>
    <div class="w1180 row my-4">
      <div class="col-7">
        <img src="../assets/empirestatemarriot/18.jpg" alt="" class="">
        <p class="height-big">
          全美较为赚钱的道(TAO)餐厅将负责纽约帝国万豪酒店顶楼餐饮酒吧的餐馆。道(TAO)主营TAO亚洲高档餐厅、LAVO意大利高档餐厅、夜总会及泳池酒吧，在纽约曼哈顿、拉斯维加斯“威尼斯人”大酒店均设有分店，系精品餐饮的代表，成为超级明星、时尚人士的喜爱。</p>
        <p class="height-big">全美较赚钱单体餐厅前十名中，道(TAO)旗下餐厅囊括四席，堪称奇迹！</p>
      </div>
      <div class="col-5 d-flex justify-content-center">
        <img src="../assets/empirestatemarriot/19.jpg" alt="" class="">
      </div>

    </div>
    <img src="../assets/empirestatemarriot/20.jpg" alt="" class="my-4">
    <img src="../assets/empirestatemarriot/21.jpg" alt="" class="my-4">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "empirestatemarriot"
}
</script>

<style scoped>

</style>