<template>
  <div class="w-100">
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="item in banner" :key="item">
        <router-link :to="item.url">
          <img :src="item.img" alt="" class="w-100">
        </router-link>
      </el-carousel-item>
    </el-carousel>


    <div class="d-flex flex-column align-items-center">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 护照投资 -</strong></span>
        <div>上市必备，资产配制，全球通行，尽享英德法公民福利</div>
      </div>
      <div class="d-flex flex-wrap" style="width: 1300px;">
        <router-link tag="div" :to="item.url" class="m-3" v-for="(item,index) in list" :key="index">
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large">{{ item.name }}</strong>
              </p>
              <p>{{ item.content }}</p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <LiveBox/>
  </div>
</template>
<script>
export default {
  name: "usa",
  data() {
    return {
      banner: [
        {
          img: require('../assets/passport/1.jpg'),
          url: '/turkey'
        },
        {
          img: require('../assets/passport/2.jpg'),
          url: '/grenada'
        },
      ],
      list: [
        {
          name: "格林纳达护照投资",
          content: "先批准再投资，低成本获得美国E2小绿卡",
          url: "/grenada",
          img: require('../assets/13.jpg')
        },
        // {
        //   name: "土耳其护照投资",
        //   content: "25万美元购房，全家护照，英美跳板",
        //   url: "/turkey",
        //   img: require('../assets/passport/turkey.jpg')
        // },
        {
          name: "圣基茨护照投资",
          content: "上市必备、资产配置、全球通行",
          url: "/stkitts",
          img: require('../assets/passport/sjc-fm.jpg')
        },
        {
          name: "安提瓜护照投资",
          content: "加勒比优质度假房源，身份、投资、回购三不误！",
          url: "/antigua",
          img: require('../assets/passport/atg-fm.jpg')
        },

        {
          name: "瓦努阿图护照投资",
          content: "全球快乐，容易经商的国家",
          url: "/vanuatu",
          img: require('../assets/passport/wnat-fm.jpg')
        }
      ],
    }
  }, methods: {
    getImg(i) {
      return require('../assets/usa/' + (i + 3) + '.jpg')
    }, goBanner(id) {
      let list = ["/turkey", "/canadazt", "/canadazt", "/maltazt", "/usa"]
      this.$router.push(list[id])
    }
  }
}
</script>

<style scoped>
.txt-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: bottom;
  color: #fff;
}

.pic-box {
  cursor: pointer;
  position: relative;
}

.text-large {
  font-size: 24px;
}

.pic-box:hover {
  box-shadow: 0 0 10px #000;
}

</style>