<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/ukzt/1.jpg" alt="" class="w-100">
    <img src="../assets/ukzt/2.jpg" alt="">
    <img src="../assets/ukzt/3.jpg" alt="">
    <img src="../assets/ukzt/4.jpg" alt="">
    <img src="../assets/ukzt/5.jpg" alt="">
    <img src="../assets/ukzt/6.jpg" alt="">
    <img src="../assets/ukzt/7.jpg" alt="">
    <img src="../assets/ukzt/8.jpg" alt="">
    <div class="d-flex justify-content-center mt-5">
      <div class="tab-btn" :class="{'active' : tab === 0}" @click="tab=0">英国首席代表签证</div>
      <div class="tab-btn" :class="{'active' : tab === 1}" @click="tab=1">英国创新者签证</div>
    </div>
    <div class="w-100 d-flex align-items-center flex-column" style=" background: #f1f1f3;" v-if="tab===0">
      <img src="../assets/ukzt/9.jpg" alt="">
      <img src="../assets/ukzt/10.jpg" alt="">
      <img src="../assets/ukzt/11.jpg" alt="">
      <img src="../assets/ukzt/12.jpg" alt="">
    </div>
    <div class="w-100 d-flex align-items-center flex-column" style=" background: #f1f1f3;" v-if="tab===1">
      <img src="../assets/ukzt/13.jpg" alt="">
      <img src="../assets/ukzt/14.jpg" alt="">
      <img src="../assets/ukzt/15.jpg" alt="">
      <img src="../assets/ukzt/16.jpg" alt="">
    </div>

    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "ukzt",
  data() {
    return {
      tab: 0
    }
  }
}
</script>

<style scoped>
.tab-btn {
  cursor: pointer;
  width: 230px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.tab-btn.active {
  background: #f1f1f3;
}
</style>