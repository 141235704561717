<template>
  <div class="d-flex justify-content-center w-100 py-5">
    <div class="d-flex w1180 flex-wrap align-items-stretch">
      <div class="box m-3" v-for="(item , index) in list" :key="index" v-show="hideNew(item.id)">
        <router-link tag="div" :to="'/news/'+item.id" class="title">
          {{ item.title }}
        </router-link>
        <div class="date">
          {{ item.date }}
        </div>
        <div class="d-flex justify-content-center">
          <img :src="item.img" alt="/" height="220" style="max-width: 96%">
        </div>
        <div class=" w-100">
          <span v-html="item.content"></span>
          <a :href="'/news/'+item.id">&gt;&gt;详情</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import news from "@/news";

export default {
  name: "Cases",
  data() {
    return {
      list: news
    }
  },
  methods: {
    hideNew(id) {
      let hideList = [24, 13]
      return hideList.findIndex(item => item === id) === -1
    }
  }
}
</script>

<style scoped>
.box {
  border-radius: 4px;
  border: 1px #ddd solid;
  padding: 12px;
  width: 30%;
}

.title {
  width: 80%;
  cursor: pointer;
  font-size: 1.4rem;
  color: #428bca;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.title:hover {
  text-decoration: underline;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>