<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/filiaestate/1.jpg" alt="" class="w-100">
    <div class="position-relative my-4">
      <img src="../assets/filiaestate/2.png" alt="">
      <div class="num">01</div>
    </div>
    <div style="color:#8E624B; font-size:40px; text-align:center; margin-top:20px; font-weight:bold;">
      一万个去希腊买度假屋的理由
    </div>
    <div
        style="font-size:28px;color:#595757; border:solid 1px #A8A8A8; text-align:center; width:470px; padding:10px; margin:30px auto 0;">
      备受全球投资者青睐
    </div>

    <img src="../assets/filiaestate/3.jpg" alt="">
    <img src="../assets/filiaestate/4.jpg" alt="">
    <img src="../assets/filiaestate/5.jpg" alt="">
    <img src="../assets/filiaestate/6.jpg" alt="">
    <img src="../assets/filiaestate/7.jpg" alt="">
    <div class="layout w-100 d-flex justify-content-center my-4">
      <div class="w1180 row">
        <div class="col-7 text-white">
          <h2 style="padding-left:100px; font-size:100px; line-height:140px; margin-top:200px;">
            希腊
          </h2>
          <p style="padding-left:100px; font-size:40px; padding-top:20px; padding-bottom:20px;">
            —度假屋房产的瑰宝
          </p>
          <img src="../assets/filiaestate/9.png" class="img-responsive" style="padding-left:100px;">
        </div>
        <div class="col-5">
          <div style="background-color:#fff; color:#595757; padding:40px 20px 20px;">
            <p class="height-big">
              Algean
              Property出具的2018年地中海高端度假屋租金收益率报告表明，2018年整个地中海地区的度假屋房产市场持续走高，蓬勃发展，其中希腊的表现尤为突出，年均租金收益率排名前10的榜单中，希腊囊括8席。
            </p>
            <p class="height-big">
              其中，希腊第一大岛克里特岛独夺两席，且其售价上升趋势明显。
            </p>
          </div>

          <table class="v-table">
            <tbody>
            <tr class="odd">
              <td colspan="4">*2018年平均总租金收益率 -TOP10</td>
            </tr>
            <tr>
              <td>国家和地区</td>
              <td>销售价格<br>（€/平方米）</td>
              <td>每周租金<br>（€）</td>
              <td>平均<br>总收益率</td>
            </tr>
            <tr class="odd">
              <td>Mykonos(GR)</td>
              <td>6,500</td>
              <td>11,500</td>
              <td>8.5%</td>
            </tr>
            <tr>
              <td>Santorini (GR）</td>
              <td>6,000</td>
              <td>8,000</td>
              <td>6.4%</td>
            </tr>
            <tr class="odd">
              <td>Paros (GR)</td>
              <td>4,000</td>
              <td>5,250</td>
              <td>6.3%</td>
            </tr>
            <tr>
              <td>Tunisia (TN)</td>
              <td>1,000</td>
              <td>1,250</td>
              <td>6.0%</td>
            </tr>
            <tr class="odd">
              <td>Skiathos (GR)</td>
              <td>4,250</td>
              <td>5,250</td>
              <td>6.3%</td>
            </tr>
            <tr>
              <td>Rhodes (GR)</td>
              <td>3,250</td>
              <td>3,700</td>
              <td>5.5%</td>
            </tr>
            <tr class="odd">
              <td>Porto Heli (GR)</td>
              <td>4,500</td>
              <td>5,000</td>
              <td>5.3%</td>
            </tr>
            <tr>
              <td>Dalmatian Coast (CR)</td>
              <td>4,500</td>
              <td>4,750</td>
              <td>5.1%</td>
            </tr>
            <tr class="odd">
              <td>**Elounda (GR)</td>
              <td>5,500</td>
              <td>5,500</td>
              <td>4.8%</td>
            </tr>
            <tr>
              <td>**Chalkidiki (GR)</td>
              <td>4,250</td>
              <td>4,250</td>
              <td>4.8%</td>
            </tr>
            <tr class="odd">
              <td>**Chania (GR)</td>
              <td>4,000</td>
              <td>4,000</td>
              <td>4.8%</td>
            </tr>
            </tbody>
          </table>
          <p style="font-size:14px; color:#000; margin-top:10px;">
            *2018年高端度假屋租金收益率报告，Algean Property **Elounda、Chalkidiki、Chania租金收益率并列第9
          </p>
        </div>
      </div>
    </div>
    <div class="layout2 d-flex flex-column align-items-center w-100 py-4">
      <div class="position-relative my-4">
        <img src="../assets/filiaestate/2.png" alt="">
        <div class="num">02</div>
      </div>
      <div class="my-3 text-white" style=" font-size:40px; font-weight:bold;">
        希腊房产
      </div>
      <div class="my-2 text-white"
          style="font-size:28px;border:solid 1px #A8A8A8; text-align:center; width:470px; padding:10px;" >
        让下一代获得欧洲绿卡的投资机会
      </div>
      <div class="w1180 d-flex justify-content-between my-4">
        <img src="../assets/filiaestate/14.png" alt="" width="305" height="417">
        <img src="../assets/filiaestate/15.png" alt="" width="305" height="417">
        <img src="../assets/filiaestate/16.png" alt="" width="305" height="417">
      </div>
    </div>


    <div class="position-relative my-4">
      <img src="../assets/filiaestate/2.png" alt="">
      <div class="num">03</div>
    </div>
    <div style="color:#8E624B; font-size:40px; text-align:center; margin-top:20px; font-weight:bold;">
      希腊房产
    </div>
    <div
        style="font-size:28px;color:#595757; border:solid 1px #A8A8A8; text-align:center; width:470px; padding:10px; margin:30px auto 0;">
      经济上升期,投资好时机
    </div>
    <div class="text-center w1180 my-4">
      希腊房产市场走势,希腊公寓价格2020年第一季度和第二季度的 <br>
      同比增幅分别为6.5%和4.1%，在2020年第三季度，首都雅典公寓价格同比上涨5.6%。经济保持增长态势，正值投资好时机!
    </div>

    <img src="../assets/filiaestate/17.png" alt="" width="50%">

    <div class="text-center w1180 my-4">
      希腊每年吸引众多国际游客到当地旅游。<br>
      主要的来源国有德国、英国、法国、俄罗斯。 其中以色列、阿拉伯联合酋长国、黎巴嫩和中国。<br>
      其中值得投资的几大领域有：
    </div>

    <img src="../assets/filiaestate/18.png" alt="" width="50%" class="my-4">
    <div style="background-color: #79c6c0;font-size: 18px;width: 50%" class="p-4 text-white text-center">
      中 房 产 对 比
    </div>

    <img src="../assets/filiaestate/19.png" alt="" width="50%" class="my-4">

    <div class="position-relative my-4">
      <img src="../assets/filiaestate/2.png" alt="">
      <div class="num">04</div>
    </div>
    <div style="color:#8E624B; font-size:40px; text-align:center; margin-top:20px; font-weight:bold;">
      宽松的移民政策
    </div>
    <div
        style="font-size:28px;color:#595757; border:solid 1px #A8A8A8; text-align:center; width:470px; padding:10px; margin:30px auto 0;">
      认可未成年人具备申请人资格
    </div>

    <img src="../assets/filiaestate/20.png" alt=""  class="my-4 w-100">


  </div>
</template>

<script>
export default {
  name: "filiaestate"
}
</script>

<style scoped>

.odd {
  background-color: #1E4580;
  color: #fff;
}

.v-table {
  width: 100%;
  margin-top: 30px;
  text-align: center;
  color: #000000;
  line-height: 35px;
}

.v-table td {
  vertical-align: middle
}

.num {
  width: 103px;
  height: 103px;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  color: #fff;
  position: absolute;
  top: 25px;
}

.layout {
  background: url("../assets/filiaestate/8.jpg");
  background-size: cover;
}
.layout2 {
  background: url("../assets/filiaestate/10.jpg");
  background-size: cover;
}
</style>