<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/BurgerKing/banner.jpg" alt="" class="w-100">
  <div class="w1180">
    <img src="../assets/BurgerKing/1.jpg" alt="">
    <img src="../assets/BurgerKing/2.jpg" alt="">
    <img src="../assets/BurgerKing/3.jpg" alt="">
    <img src="../assets/BurgerKing/4.jpg" alt="">
    <img src="../assets/BurgerKing/5.jpg" alt="">
    <img src="../assets/BurgerKing/6.jpg" alt="">
  </div>
</div>
</template>

<script>
export default {
  name: "BurgerKing"
}
</script>

<style scoped>

</style>