<template>
  <div class="d-flex align-items-center flex-column">
    <img src="../assets/por/1.png" alt="" class="w-100">
       <img src="../assets/por/2.jpg" alt="">
       <img src="../assets/por/3.jpg" alt="">
       <img src="../assets/por/4.jpg" alt="">
       <img src="../assets/por/5.jpg" alt="">
       <img src="../assets/por/6.jpg" alt="">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "por"
}
</script>

<style scoped>

</style>