import VueRouter from 'vue-router'
import Index from '@/components/Index'
import Vue from 'vue'
import eb5profile from "@/components/eb5profile";
import usa from "@/components/usa";
import oneandonly from "@/components/oneandonly";
import fsno from "@/components/fsno";
import europe from "@/components/europe";
import irelandiip from "@/components/irelandiip";
import maltazt from "@/components/maltazt";
import por from "@/components/por";
import spainzt from "@/components/spainzt";
import ukzt from "@/components/ukzt";
import hot from "@/components/hot";
import canadazt from "@/components/canadazt";
import japan from "@/components/japan";
import singapore from "@/components/singapore";
import australiazt from "@/components/australiazt";
import nz from "@/components/nz";
import passport from "@/components/passport";
import turkey from "@/components/turkey";
import stkitts from "@/components/stkitts";
import antigua from "@/components/antigua";
import Dominica from "@/components/Dominica";
import vanuatu from "@/components/vanuatu";
import rmh from "@/components/rmh";
import hd6 from "@/components/hd6";
import allen from "@/components/allen";
import tiec from "@/components/tiec";
import hres from "@/components/hres";
import cpt from "@/components/cpt";
import hyp3 from "@/components/hyp3";
import empirestatemarriot from "@/components/empirestatemarriot";
import bfs from "@/components/bfs";
import tsm from "@/components/tsm";
import obb from "@/components/obb";
import hymt from "@/components/hymt";
import eb5newlaw from "@/components/eb5newlaw";
import America from "@/components/America";
import Cases from "@/components/Cases";
import News from "@/components/News";
import House from "@/components/House";
import filiaestate from "@/components/filiaestate";
import AboutUs from "@/components/AboutUs";
import eb1a from "@/components/eb1a";
import cases from "@/cases";
import grenada from "@/components/grenada";
import BurgerKing from "@/components/BurgerKing";
import noodles from "@/components/noodles";

Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


const router = new VueRouter({
    mode: 'history',
    scrollBehavior(ro, form, savedPositon) {
        if (savedPositon) {
            return savedPositon
        }
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: "/",
            name: 'Index',
            component: Index,
        },
        {
            path: "/eb5profile",
            name: 'eb5profile',
            component: eb5profile,
        },
        {
            path: "/usa",
            name: 'usa',
            component: usa,
        },
        {
            path: "/oneandonly",
            name: 'oneandonly',
            component: oneandonly,
        },
        {
            path: "/fsno",
            name: 'fsno',
            component: fsno,
        },
        {
            path: "/europe",
            name: 'europe',
            component: europe,
        },
        {
            path: "/irelandiip",
            name: 'irelandiip',
            component: irelandiip,
        },
        {
            path: "/maltazt",
            name: 'maltazt',
            component: maltazt,
        },
        {
            path: "/por",
            name: 'por',
            component: por,
        },
        {
            path: "/spainzt",
            name: 'spainzt',
            component: spainzt,
        },
        {
            path: "/ukzt",
            name: 'ukzt',
            component: ukzt,
        },
        {
            path: "/hot",
            name: 'hot',
            component: hot,
        },
        {
            path: "/canadazt",
            name: 'canadazt',
            component: canadazt,
        },
        {
            path: "/japan",
            name: 'japan',
            component: japan,
        },
        {
            path: "/singapore",
            name: 'singapore',
            component: singapore,
        },
        {
            path: "/australiazt",
            name: 'australiazt',
            component: australiazt,
        },
        {
            path: "/nz",
            name: 'nz',
            component: nz,
        },
        {
            path: "/passport",
            name: 'passport',
            component: passport,
        },
        {
            path: "/turkey",
            name: 'turkey',
            component: turkey,
        },
        {
            path: "/stkitts",
            name: 'stkitts',
            component: stkitts,
        },
        {
            path: "/antigua",
            name: '安提瓜',
            component: antigua,
        },
        {
            path: "/Dominica",
            name: '多米尼克',
            component: Dominica,
        },
        {
            path: "/vanuatu",
            name: '瓦努阿图',
            component: vanuatu,
        },
        {
            path: "/rmh",
            name: '洛杉矶星光天地7期',
            component: rmh,
        },
        {
            path: "/hd6",
            name: '好莱坞星光天地6期',
            component: hd6,
        },
        {
            path: "/allen",
            name: '休斯顿艾伦中心',
            component: allen,
        },
        {
            path: "/tiec",
            name: '腾龙国际马会',
            component: tiec,
        },
        {
            path: "/hres",
            name: '纽约哈德逊河滨豪庭',
            component: hres,
        },
        {
            path: "/cpt",
            name: '中央公园壹号',
            component: cpt,
        },
        {
            path: "/hyp3",
            name: '哈德逊城市广场3期',
            component: hyp3,
        },
        {
            path: "/empirestatemarriot",
            name: '纽约帝国万豪酒店',
            component: empirestatemarriot,
        },
        {
            path: "/bfs",
            name: '波士顿四季酒店',
            component: bfs,
        },
        {
            path: "/tsm",
            name: '纽约时代广场万豪酒店',
            component: tsm,
        },
        {
            path: "/obb",
            name: '加州贝景湾',
            component: obb,
        },
        {
            path: "/hymt",
            name: '哈德逊广场2期',
            component: hymt,
        },
        {
            path: "/eb5newlaw",
            name: '2022美国移民新法生效',
            component: eb5newlaw,
        },
        {
            path: "/America",
            name: '美国移民大全',
            component: America,
        },
        {
            path: "/eb1a",
            name: '美国杰出人才移民',
            component: eb1a,
        },
        {
            path: "/filiaestate",
            name: '希腊翡翠庄园',
            component: filiaestate,
        },
        {
            path: "/burger_king",
            name: '希腊翡翠庄园',
            component: BurgerKing,
        },
        {
            path: "/Cases",
            name: '经典案例',
            component: Cases,
        },
        {
            path: "/noodles",
            name: '面条',
            component: noodles,
        },
        {
            path: "/news",
            name: '移民资讯',
            component: News,
        },
        {
            path: "/house",
            name: '购房移民',
            component: House,
        },
        {
            path: "/AboutUs",
            name: '关于我们',
            component: AboutUs,
        },
        {
            path: "/grenada",
            name: '格林纳达',
            component: grenada,
        }

    ]
})


cases.sort(function (item1, item2) {
    let a = new Date(item1.date)
    let b = new Date(item2.date)
    return b - a
})

cases.forEach(function (info, index) {
    info.index = index
    import('@/components/Cases/' + info.id + '.vue').then(module => {
        router.addRoute({name: '经典案例' + index, path: "/cases/" + info.id, component: module.default, meta: info})
    })
})


for (let i = 0; i < 26; i++) {
    let id = 1 + i
    import('@/components/News/News' + id + '.vue').then(module => {
        router.addRoute({name: '移民资讯' + i, path: "/news/" + id, component: module.default})
    })
}

for (let i = 0; i < 5; i++) {
    let id = 1 + i
    import('@/components/House/' + id + '.vue').then(module => {
        router.addRoute({name: '购房移民' + i, path: "/house/" + id, component: module.default})
    })
}


export default router