<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/europe/3.jpg" alt="" class="w-100">
    <img src="../assets/spainzt/2.jpg" alt="">
    <img src="../assets/spainzt/3.jpg" alt="">
    <img src="../assets/spainzt/4.jpg" alt="">
    <img src="../assets/spainzt/5.jpg" alt="">
    <img src="../assets/spainzt/6.jpg" alt="">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "spainzt"
}
</script>

<style scoped>

</style>