<template>
  <div id="app" class="flex flex-column w-100" style="height: 100vh">
    <HeaderNav/>
    <transition name="transitionRouter" mode="out-in">
      <router-view/>
    </transition>
    <FooterUs/>
  </div>
</template>
<script>
import FooterUs from "@/components/FooterUs";
import HeaderNav from "@/components/HeaderNav";

export default {
  name: 'App',
  components: {FooterUs, HeaderNav},
  mounted() {

  },
}
</script>

<style>
.transitionRouter-enter, .transitionRouter-leave-to{
  opacity: 0;
}
.transitionRouter-enter-to, .transitionRouter-leave{
  opacity: 1;
}
.transitionRouter-enter-active, .transitionRouter-leave-active{
  transition: all 0.3s;
}


html {
  font-family: Arial, 微软雅黑;
  background-color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.w1180 {
  width: 1180px;
}

.f14 {
  font-size: 14px;
}

.height-big {
  line-height: 30px;
}

body {
  color: #333;
  font-size: 14px;
}
</style>
