<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/obb/1.jpg" alt="" class="w-100">
    <img src="../assets/obb/2.png" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-5">
        <img src="../assets/obb/3.jpg" alt="" class="">
      </div>
      <div class="col-7">
        <img src="../assets/obb/4.png" alt="" class="">
        <p class="height-big">模范认证 -
          是指区域中心将一个成熟项目的全套项目文件作为I-526申请范本递交移民局作提前审批。一旦获得移民局模范认证批准，就意味着移民局认可了商业计划、经济学就业报告、资金招募备忘录等全部项目文件，并批准了项目可创造的就业人数，以及可招募的投资者数量，所以投资者无需再担忧就业是否足够等问题。在以后每个投资者的I-526审批中，移民局将不再审核项目文件，仅审核与投资者个人身份及资金来源相关的文件。</p>
        <img src="../assets/obb/5.png" alt="" class="">
        <p class="height-big">能够获得模范认证批准的项目非常罕有，对于投资人而言，是一个绿卡的快速通道。与其它普通的
          项目相比，获得了模范认证批准的项目具备以下优势：<br>
          · 项目相关的文件（商业计划，经济学就业报告等）已完全获得移民局审批通过；<br>
          · I-526批准速度远远快于一般项目；<br>
          · 移民局一贯对已经获得模范认证批准的项目持“尊重”态度，即项目不受一般的政策变化影响。</p>
      </div>
    </div>
    <img src="../assets/obb/6.jpg" alt="" class="my-4">
    <img src="../assets/obb/7.png" alt="" class="my-4">
    <div class="d-flex justify-content-center w-100 py-5" style="background-color: #efefef">
      <div class="w1180 row ">
        <div class="col-5">
          <div class="d-flex justify-content-center mb-4"><img src="../assets/obb/8.jpg" alt="" class=""></div>
          <p class="height-big margin-large-top">金门国际是旧金山湾区知名的EB-5区域中心，区域中心的主要负责人均为旧金山市府和加州政府原高层官员。
          </p>
          <p class="height-big">
            金门国际自成立之初，就以“造福湾区”为其宗旨。他们植根于旧金山湾区，更着眼于湾区的未来；在金门国际的带领下，猎人角，这一美国历史上知名的城市改造项目，已成为旧金山炙手可热的全新社区。</p>
          <p class="height-big">
            如今，他们将焦点放在了海湾对岸的奥克兰贝景湾，在金门国际的引领下，奥克兰贝景湾必将成为湾区下一个标杆之作。</p>

        </div>
        <div class="col-7 row">
          <div class="col-4 p-1" v-for="(item,index) in list" :key="'list'+index">
            <img :src="item" class="w-100" alt="">
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/obb/9.png" alt="" class="my-4">
    <div class="w1180 row">
      <div class="col-7">
        <img src="../assets/obb/10.png" alt="" class="">
        <p class="height-big margin-big-top">
          贝景湾项目座落于奥克兰河口，与旧金山隔海相望。这一充满活力的全新综合房地产开发项目将重建滨水工业区，使其成为富有吸引力的区域景点。项目总占地65英亩，包括3000多套住宅和占地32英亩的公园及公共空间，湿地恢复，新建码头，以及约20万平方英尺（两万平方米）的零售和商业空间。住户可通过社区内广阔的公园及公共空间享受近在咫尺的海滩。这里理想的地理位置尽揽旧金山海湾及城市夜景，而多样化的文化、商业和休闲活动选择更将使其成为湾区新的城市一景。</p>
      </div>
      <div class="col-5 d-flex justify-content-center">
        <img src="../assets/obb/11.jpg" alt="" class="">
      </div>
    </div>
    <div class="w1180 row mt-5">
      <div class="col-7">
        <img src="../assets/obb/12.png" alt="" class="">
        <p class="height-big mt-4">* 根据著名经济学家迈克尔埃文斯博士就业研究报告，基于 RIMS II
          建筑开支产生的就业机会，未包含经营就业。因此，只要建筑工程完工，投资人的永久绿卡即可确保。这将显著加快I-526条件绿卡和I-829永久绿卡的审批速度。</p>
      </div>
      <div class="col-5 d-flex justify-content-center">
        <div>
          <img src="../assets/obb/13.png" alt="" class="">
        </div>
      </div>
    </div>
    <img src="../assets/obb/14.png" alt="" class="my-4">
    <div class="w-100 d-flex flex-column align-items-center py-5" style="background-color: #efefef">
      <div class="row w1180">
        <div class="col-7">
          <img src="../assets/obb/15.png" alt="" class="mb-4">
          <p class="height-big margin-large-top">
            Signature开发集团总部位于加利福尼亚州奥克兰市，是北加州知名的房地产公司之一。20多年以来，Signature开发集团及其相关公司在开发高端综合功能项目、住宅总体规划社区、新住宅街区以及商业零售物业上拥有良好的业绩记录。Signature集团已在加利福尼亚州北部开发住宅、办公、零售等房地产项目达2,000多万平方英尺（约186万平方米）。</p>
          <p class="height-big">2006开始，Signature开发集团受奥克兰市政府委托，牵头主持奥克兰旧码头的改造工程，并逐步将其打造成奥克兰知名的城市开发项目
            -贝景湾(Brooklyn Basin)。</p>
        </div>
        <div class="col-5 row">
          <div class="col-6">
            <img src="../assets/obb/16.jpg" alt="" class="">
          </div>
          <div class="col-6">
            <img src="../assets/obb/17.jpg" alt="" class="">
          </div>
          <div class="col-6">
            <img src="../assets/obb/18.jpg" alt="" class="">
          </div>
          <div class="col-6">
            <img src="../assets/obb/19.jpg" alt="" class="">
          </div>
        </div>
      </div>
      <div class="row w1180 mt-4">
        <div class="col-5 ">
          <img src="../assets/obb/obb25.jpg" class="img-responsive">
        </div>
        <div class="col-7">
          <p class="height-big">
            泽信控股集团成立于1994年，是中国一家集商业和住宅房地产项目所有、开发及管理于一体的综合性公司。泽信公司总部位于北京，已经发展成为在中国5个城市拥有1,200名员工的公司。自成立以来，泽信已经开发商业和住房项目达6,500万平方英尺（约604万平方米），并且每年仍在开发1,070万平方英尺（约99.4万平方米）的房地产项目。泽信控股被授予地产开发和物业管理的国家一级资质证明。</p>
          <p class="height-big">2013年，在加州州长Brown阁下的引荐下，泽信控股作为股权合作伙伴，参与投资贝景湾。</p>
        </div>
      </div>
    </div>
    <img src="../assets/obb/20.jpg" alt="" class="w-100">
    <img src="../assets/obb/21.jpg" alt="" class="w-100">
<!--    <img src="../assets/obb/22.jpg" alt="" class="my-4">-->
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "obb",
  data() {
    return {
      list: [
        require('../assets/obb/1/1.jpg'),
        require('../assets/obb/1/2.jpg'),
        require('../assets/obb/1/3.jpg'),
        require('../assets/obb/1/4.jpg'),
        require('../assets/obb/1/5.jpg'),
        require('../assets/obb/1/6.jpg'),
      ]
    }
  }
}
</script>

<style scoped>

</style>