<template>
  <div>
    <div style="background-color: #f2f2f2;" class="py-4 d-flex flex-column align-items-center">
      <div class="d-flex w-100 justify-content-center" style="max-width: 1200px">
        <div class="mx-3 text-center">
          <div class="f_btn border-main icon-pencil-square-o">
            <i class="el-icon-chat-line-square"></i>
          </div>
          <br>
          <div style=" font-size:1.5em;line-height:2.5em;">联系我们</div>
          <div class="my-2" style="font-size: 14px">垂询电话:13764320113</div>
          <div class="my-2" style="font-size: 14px">地址:上海市虹口区四川北路88号星荟中心 T2 709室</div>
<!--          <div>沪公安备案110101010101010号 沪ICP备09007479</div>-->
        </div><!--x3 -->
      </div>
      <div class="line margin-big-top mt-5">
        <div class="x12 text-center" style=" font-size: 14px;">
          *网站友情提醒： 投资有风险。本网站涉及之所有投资细节(如：收益、退出等)均以实际签署之投资协议为准。移民申请之程序均以相关目的国家之法律法规为准。
        </div>
      </div>
    </div>
    <div class="py-2">
      <div class="line text-center">
        <div class="x12">
          联系我们 上海市虹口区四川北路88号 星荟中心 T2 709室<br>
          ©1997-2022 <a href="/">跃迁出国</a><br>
<!--          <img  src="../assets/gaba.png"> 沪公安备11010102002019号-->
          <a href="https://beian.miit.gov.cn/">沪ICP备2022032044号-1</a></div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'FooterUs',
  computed: {
    ...mapState(['baseData']),
    baseUrl: () => {
      return process.env.VUE_APP_NAME_URL
    }
  },
}
</script>

<style>
.f_btn.border-main:hover {
  color: #c39d5e;
  border-color: #c39d5e;
  background-color: #fff;
}

.f_btn.border-main {
  color: #fff;
  background-color: #c39d5e;
}

.f_btn {
  border: solid 1px #c39d5e;
  background: transparent;
  border-radius: 50%;
  padding: 15px;
  margin: 0;
  display: inline-block;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1) 0s;
  font-size: 24px;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 30px;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>
