<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/japan/1.jpg" alt="" class="w-100">
    <img src="../assets/japan/2.jpg" alt="" class="w-100">
    <img src="../assets/japan/3.jpg" alt="" class="w-100">
    <img src="../assets/japan/4.jpg" alt="" class="w-100">
    <img src="../assets/japan/5.jpg" alt="" class="w-100">
    <img src="../assets/japan/6.jpg" alt="" class="w-100">
    <img src="../assets/japan/7.jpg" alt="" class="w-100">
    <img src="../assets/japan/8.jpg" alt="" class="w-100">
    <div class="d-flex justify-content-center w-100" style="background-color: #2f2f39">
      <img src="../assets/japan/9.jpg" alt="" width="1180">
    </div>
    <img src="../assets/japan/10.jpg" alt="" class="w-100">
    <img src="../assets/japan/11.jpg" alt="" class="w-100">
    <img src="../assets/japan/12.jpg" alt="" width="1180">
    <img src="../assets/japan/13.jpg" alt="" width="1180">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "japan"
}
</script>

<style scoped>

</style>