<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/tsm/1.jpg" alt="" class="w-100">
  <img src="../assets/tsm/2.jpg" alt="" class="my-4">
  <div class="w1180">
    <p><strong>纽约时代广场万豪酒店，座落于纽约市曼哈顿中城区第七大道485号，由美国地产控股集团-盛石集团(Lightstone Group)负责开发。</strong></p>
    <p><strong>时代广场万豪酒店建成后，高17层，包含612间万豪精品客房，酒店全部餐饮服务，包括餐厅及云顶会所，将交由美国知名道(TAO)餐饮集团运营；杰出的地理位置赋予了帝国万豪酒店绚丽的曼哈顿天极美景和无限的盈利空间。</strong></p>
    <p><strong>建成后，万豪集团将负责酒店的全面运营，使用旗下高端精品品牌－MOXY酒店。万豪集团对项目前景充满信心，并破例为项目投资了6450万美元，这创造了万豪集团的历史。</strong></p>
    <p><strong>盛石集团，拥有丰富的开发经验及优异的行业信誉，2014-2016获得标准普尔A+与穆迪A1的信用评级；杰出的行业信誉获得了合作方的认可，与其它普通的项目相比，获得了模范认证批准的纽约时代广场万豪酒店项目更显优势，如今盛大开业，对于投资人而言，更是喜上加喜，选择纽约时代广场万豪酒店项目的投资者，所有就业已经产生，永久绿卡已是囊中之物！。</strong></p>
    <p></p>
  </div>
  <div class="w-100 d-flex justify-content-center" style="background-color: #b2a68c">
    <img src="../assets/tsm/3.jpg" alt="" class="">
  </div>
  <div class="w-100 d-flex justify-content-center" style="background-color: #494039">
    <img src="../assets/tsm/4.jpg" alt="" class="">
  </div>
  <div class="w-100 d-flex justify-content-center" style="background-color: #ececec">
    <img src="../assets/tsm/5.jpg" alt="" class="">
  </div>
  <img src="../assets/tsm/6.jpg" alt="" class="">
  <img src="../assets/tsm/7.jpg" alt="" class="">
  <img src="../assets/tsm/8.jpg" alt="" class="mt-4">
  <div class="w-100 d-flex justify-content-center" style="background-color: #494039">
    <img src="../assets/tsm/9.jpg" alt="" class="">
  </div>
  <img src="../assets/tsm/10.jpg" alt="" class="my-4">
  <img src="../assets/tsm/11.jpg" alt="" class="my-4">
  <img src="../assets/tsm/12.jpg" alt="" class="my-4">
  <img src="../assets/tsm/13.png" alt="" class="my-4">
  <div class="w1180 overflow-hidden my-4">
    <div class="swiper-container position-relative">
      <div class="swiper-wrapper">
        <div class="swiper-slide d-flex flex-column align-items-center"
             style="width: 377px"
             v-for="(item,index) in list" :key="index">
          <img :src="item" class="w-100"/>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Swiper, {Autoplay} from "swiper";

export default {
  name: "tsm",
  data() {
    return {
      list: [
        require('../assets/tsm/1/1.jpg'),
        require('../assets/tsm/1/2.jpg'),
        require('../assets/tsm/1/3.jpg'),
        require('../assets/tsm/1/4.jpg'),
        require('../assets/tsm/1/5.jpg'),
        require('../assets/tsm/1/6.jpg'),
        require('../assets/tsm/1/7.jpg'),
      ],
    }
  },
  mounted() {
    Autoplay
    new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 2000
      },
      slidesPerView: 4,
      centeredSlides: true,
      paginationClickable: true,
      spaceBetween: 30
    });
  }
}
</script>

<style scoped>

</style>