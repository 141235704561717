var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/empirestatemarriot/2.jpg"),"alt":""}}),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/empirestatemarriot/11.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/empirestatemarriot/12.jpg"),"alt":""}}),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/empirestatemarriot/20.jpg"),"alt":""}}),_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/empirestatemarriot/21.jpg"),"alt":""}}),_c('LiveBox')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center w-100",staticStyle:{"background-color":"#173287"}},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-center w-100",staticStyle:{"background-color":"#112472"}},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center w-100",staticStyle:{"background-color":"#ededed"}},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/5.jpg"),"alt":""}}),_c('img',{attrs:{"src":require("../assets/empirestatemarriot/6.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 row my-4"},[_c('div',{staticClass:"col-6"},[_c('img',{staticClass:"my-4",attrs:{"src":require("../assets/empirestatemarriot/7.jpg"),"alt":""}}),_c('h2',{staticClass:"height-big margin-big-top"},[_vm._v("纽约酒店市场")]),_c('p',[_c('br'),_vm._v("过去20年来，纽约始终是来自世界各地的游客们较为钟爱的旅游城市，同时金融中心的地位，也使得纽约成为商务客必经之地;酒店需求更是水涨船高，金融危机以来，纽约市的客房入住率平衡上升，并在2014年创造了89.4%的惊人记录。 ")]),_c('p',{staticClass:"height-big"},[_vm._v(" 纽约中城区一直以来，凭借优越的地理位置，无论是商务出行，还是休闲旅游，中城区都是个高端游客的必选下榻之地。")]),_c('p',{staticClass:"height-big"},[_vm._v("旺盛的酒店市场，杰出的地理位置，为纽约帝国万豪酒店带来了让人充满信心的盈利前景。")])]),_c('div',{staticClass:"col-6 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/8.jpg"),"alt":""}})]),_c('div',{staticClass:"col-6 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/9.jpg"),"alt":""}})]),_c('div',{staticClass:"col-6 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/10.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center w-100",staticStyle:{"background-color":"#112472"}},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/13.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 row my-4"},[_c('div',{staticClass:"col-6"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/14.jpg"),"alt":""}}),_c('p',{staticClass:"height-big margin-big-top"},[_c('br'),_vm._v("David Lichtenstein于1988年创立盛石集团，目前担任董事长兼首席执行官，致力于实现质量、诚信和价值的较高标准。Lichtenstein先生在房地产行业的领导能力和成功把握房地产机遇、时间和市场的能力在业内享有盛誉， 也得到了政府机构的高度认可,同时兼任多个公共职务：")]),_c('p',{staticClass:"height-big"},[_vm._v("-纽约市市长任命Lichtenstein为纽约经济发展公司（NYCEDC）董事会成员"),_c('br'),_vm._v("-房地产资本政策咨询委员会的联合主席/纽约不动产委员会理事会成员"),_c('br'),_vm._v(" -公民预算委员会信托人/纽约经济俱乐部&房地产圆桌会议成员"),_c('br'),_vm._v(" -布鲁金斯学会经济研究委员会成员 / 托罗学院和大学系统信托人"),_c('br'),_vm._v(" -纽约医学院的董事会监督委员会成员 ")]),_c('p',{staticClass:"height-big"},[_vm._v("David Lichtenstein先生对纽约帝国万豪酒店项目充满信心，因此为EB-5投资人提供了私人还款担保，这也为EB-5 投资人的资金提供了额外保障。")])]),_c('div',{staticClass:"col-6 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/15.jpg"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 row my-4"},[_c('div',{staticClass:"col-5 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/17.jpg"),"alt":""}})]),_c('div',{staticClass:"col-7"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/16.jpg"),"alt":""}}),_c('p',[_c('br'),_vm._v("通过收购喜达屋酒店，万豪一跃成为世界一流酒店品牌。其在120个国家拥有近6000家酒店、110万个房间，以及7500万忠诚客户。"),_c('br'),_vm._v("万豪集团以其强大的品牌声誉，全球庞大的订房系统及庞大的万豪礼赏忠诚客户，为旗下酒店的成功奠定坚实基础。 ")]),_c('p',{staticClass:"height-big margin-big-top"},[_vm._v("万豪MOXY酒店"),_c('br'),_vm._v("万豪MOXY是万豪集团新的生活理念主题酒店。一个新颖具有创意的品牌，结合了时尚的设计和优秀的服务，兼具价格实惠的优势。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w1180 row my-4"},[_c('div',{staticClass:"col-7"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/18.jpg"),"alt":""}}),_c('p',{staticClass:"height-big"},[_vm._v(" 全美较为赚钱的道(TAO)餐厅将负责纽约帝国万豪酒店顶楼餐饮酒吧的餐馆。道(TAO)主营TAO亚洲高档餐厅、LAVO意大利高档餐厅、夜总会及泳池酒吧，在纽约曼哈顿、拉斯维加斯“威尼斯人”大酒店均设有分店，系精品餐饮的代表，成为超级明星、时尚人士的喜爱。")]),_c('p',{staticClass:"height-big"},[_vm._v("全美较赚钱单体餐厅前十名中，道(TAO)旗下餐厅囊括四席，堪称奇迹！")])]),_c('div',{staticClass:"col-5 d-flex justify-content-center"},[_c('img',{attrs:{"src":require("../assets/empirestatemarriot/19.jpg"),"alt":""}})])])
}]

export { render, staticRenderFns }