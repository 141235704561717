<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/turkey/1.jpg" alt="" class="w-100">
  <img src="../assets/turkey/2.png" alt="" class="my-5">
  <div class="w1180 d-flex justify-content-around">
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/turkey/r1.png" alt="">
      <p class="top_item">
        高性价比
      </p>
      <div>
        仅需40万美元
      </div>
      <div>
        投资土耳其
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/turkey/r2.png" alt="">
      <p class="top_item">
        一步到位拿护照
      </p>
      <div>
        3-6个月政府审批
      </div>
      <div>
        无移民监/无语言要求
      </div>
      <div>
        /无管理经验要求
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/turkey/r3.png" alt="">
      <p class="top_item">
        身份私密
      </p>
      <div>
        可更名
      </div>
      <div>
        不显示曾用名
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/turkey/r4.png" alt="">
      <p class="top_item">
        赴美跳板
      </p>
      <div>
        可申请美国E-2签证
      </div>
      <div>
        1年内登陆美国
      </div>
    </div>
    <div class="d-flex flex-column align-items-center">
      <img src="../assets/turkey/r5.png" alt="">
      <p class="top_item">
        无需资产申报
      </p>
      <div>
        无需申报个人资金
      </div>
      <div>
        来源和收入
      </div>
    </div>
  </div>
  <img src="../assets/turkey/3.png" alt="" class="my-5">
  <div class="w1180 d-flex justify-content-center layout py-5" >
    <div class="swiper-container overflow-hidden" style="width: 960px;">
      <div class="swiper-wrapper">
        <div class="swiper-slide d-flex flex-column align-items-center">
             <img src="../assets/turkey/b1.png" alt="" class="w-100">
        </div>
        <div class="swiper-slide d-flex flex-column align-items-center">
             <img src="../assets/turkey/b2.png" alt="" class="w-100">
        </div>
        <div class="swiper-slide d-flex flex-column align-items-center">
             <img src="../assets/turkey/b3.png" alt="" class="w-100">
        </div>
        <div class="swiper-slide d-flex flex-column align-items-center">
             <img src="../assets/turkey/b4.png" alt="" class="w-100">
        </div>
      </div>
      <!-- Add Pagination -->
      <div class="swiper-pagination"></div>
    </div>
  </div>

  <img src="../assets/turkey/5.png" alt="">
  <div class="text-center height-big">
    进入2000年后，土耳其房地产市场经历了一个快速发展的时期；<br>
    2008年前后，土耳其房地产市场较为火热，房地产一度甚至占据了土耳其国民生产总值的 9%；<br>
    近十年来，土耳其住宅市场房屋销量和以里拉标价的房屋售价均处在总体上升的趋势；
  </div>
  <div class="my-5">
    <img src="../assets/turkey/6.png" alt="">
    <img src="../assets/turkey/7.png" alt="">
  </div>

  <img src="../assets/turkey/8.png" alt="">
  <div class="w1180 layout1">
    <div class="p-5 text-white text-start" style="margin-left: 35%;">
      <p class="height-big"></p><h2>
      | 美国跳板—E-2签证
    </h2><p></p>
      <p class="height-big margin-large-top">土耳其公民有资格申请美国E-2投资者签证，签证有效期5年，可无限次续签，实现在美
        合法长期居留。</p>
      <p class="height-big margin-large-top">- <strong style="color:#fff5af;">高效审理，3个月获签，快速登陆美国</strong>；<br>
        - 投资人21岁以下子女享免费公立中小学教育，读大学享州内学费待遇；（公立/私立/寄宿学校自由选择，<strong style="color:#fff5af;">择校面更广，更低学费成本享更优质教育资源</strong>）；<br>
        - 投资人的配偶可申请在美国境内任何地方就业；<br>
        - 投资人及其家属可在美国境内居留而无须针对全球收入报税；<br>
        - 只要商业活动持续满足相关要求，投资人即可续签美国E-2签证并在美国居留。
      </p>
    </div>
  </div>
  <img src="../assets/turkey/10.png" alt="" class="my-5">
  <div class="row w1180">
    <div class="col-6">
      <p class="height-big text-big">
        2018年9月18日《土耳其国籍法第5901号修正案》，修改了获得公民身份和居留许可资格的投资金额，由之前的投资100万美元房产降至25万美元或等值里拉金额。</p>
      <p class="height-big text-big"><strong>·</strong> 年满18周岁<br>
        <strong>·</strong> 配偶和未满18周岁的子女可随同申请<br>
        <strong>·</strong> 购买至少价值25万美金的不动产<br>
        <strong>·</strong> 3年后可出售
      </p>
    </div>
    <div class="col-6 p-3">
      <img src="../assets/turkey/11.png" alt="" class="w-100">
    </div>
  </div>

  <LiveBox/>
</div>
</template>

<script>
import Swiper ,{ Autoplay,Pagination } from "swiper";


export default {
  name: "turkey",
  mounted(){
    Autoplay
    Pagination
    new Swiper('.swiper-container', {
      pagination: '.swiper-pagination',
      autoplay:{
        delay:2000
      }
    });
  }
}
</script>

<style scoped>
@import url("../../node_modules/swiper/swiper.min.css");

.top_item {
  font-weight: bold;
  color: #cf2c24;
}
.layout{
  background:url("../assets/turkey/4.png");
}
.layout1{
  background:url("../assets/turkey/9.png");
  background-size: cover;
}
</style>