<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/noodles/banner.jpg" alt="" class="w-100">
    <img src="../assets/noodles/1.png" alt=""  width="1180">
    <img src="../assets/noodles/2.png" alt=""  width="1180">
    <img src="../assets/noodles/3.png" alt=""  width="1180">
    <img src="../assets/noodles/4.png" alt=""  width="1180">
    <img src="../assets/noodles/5.png" alt=""  width="1180">
    <img src="../assets/noodles/6.png" alt=""  width="1180">
    <img src="../assets/noodles/7.png" alt=""  width="1180">
    <img src="../assets/noodles/8.png" alt=""  width="1180">
    <img src="../assets/noodles/9.png" alt=""  width="1180">
    <img src="../assets/noodles/10.png" alt=""  width="1180">

    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "noodles"
}
</script>

<style scoped>

</style>