<template>
  <div class="w-100">
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="(item,index) in banner" :key="index">
        <img :src="item" alt="" class="w-100" @click="goBanner(index)">
      </el-carousel-item>
    </el-carousel>


    <div class="d-flex flex-column align-items-center">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 热门欧洲目的地 -</strong></span>
        <div>项目简介、核心对比、优势凸显</div>
      </div>
      <div class="d-flex flex-wrap" style="width: 1300px;">
        <router-link  tag="div" :to="item.url" class="m-3" v-for="(item,index) in list" :key="index">
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large">{{ item.name }}</strong>
              </p>
              <p>{{ item.content }}</p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <LiveBox/>
  </div>
</template>
<script>
export default {
  name: "usa",
  data() {
    return {
      banner: [
        require('../assets/hot/1.jpg'),
        require('../assets/hot/2.jpg'),
        require('../assets/hot/3.jpg'),
      ],
      list: [
        {
          name: "加拿大安省雇主担保移民",
          content: "免雅思，多职位可选，加拿大移民快车道",
          url: "/canadazt",
          img: require('../assets/hot/4.jpg')
        },
        {
          name: "加拿大联邦自雇移民",
          content: "无投资/创业/居住要求，一步到位获获枫叶卡",
          url: "/canadazt",
          img: require('../assets/hot/5.jpg')
        },
        {
          name: "日本房产投资",
          content: "预期7%年回报*，快速移居日本",
          url: "/japan",
          img: require('../assets/hot/6.jpg')
        },
        {
          name: "新加坡投资移民",
          content: "先获批后投资，一步到位拿永居",
          url: "/Singapore",
          img: require('../assets/hot/7.jpg')
        },
        // {
        //   name: "马来西亚第二家园",
        //   content: "英联邦教育，15万马币投资，3个月快速批准",
        //   url: "/Malaysiazt",
        //   img: require('../assets/hot/8.jpg')
        // },
        // {
        //   name: "加拿大魁省投资移民",
        //   content: "一次性投资35万加币，全家移民加拿大",
        //   url: "/quebec",
        //   img: require('../assets/hot/9.jpg')
        // },
        // {
        //   name: "加拿大萨省提名项目",
        //   content: "无语言/年龄/学历要求，审核速度快",
        //   url: "/ca_sas",
        //   img: require('../assets/hot/10.jpg')
        // },
        // {
        //   name: "加拿大投资移民",
        //   content: "资产要求低，投资方式灵活，审核速度快",
        //   url: "/ca_nb",
        //   img: require('../assets/hot/11.jpg')
        // },
        // {
        //   name: "加拿大曼尼托巴省投资移民",
        //   content: "资产要求低，申请门槛低，投资灵活",
        //   url: "/manitoba",
        //   img: require('../assets/hot/12.jpg')
        // },
        {
          name: "澳大利亚",
          content: "世界公认的宜居城市，4个月即可快速移民",
          url: "/australiazt",
          img: require('../assets/hot/13.jpg')
        },
        {
          name: "新西兰",
          content: "享二国（澳州）福利，移民创业二不误",
          url: "/nz",
          img: require('../assets/hot/14.jpg')
        },
      ],
      list2: [
        // {
        //   name: "加拿大萨斯克彻温省提名",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/ca_sasljs",
        //   img: require('../assets/hot/15.jpg')
        // },
        // {
        //   name: "加拿大曼尼托巴省提名",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/manitobazc",
        //   img: require('../assets/hot/16.jpg')
        // },
        // {
        //   name: "加拿大投资移民",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/ca_nbjs",
        //   img: require('../assets/hot/15.jpg')
        // },
        {
          name: "澳大利亚投资移民",
          content: "安全的投资模式，理想的澳洲移民！",
          url: "/australiazt",
          img: require('../assets/hot/17.jpg')
        },
        {
          name: "新西兰投资移民",
          content: "新西兰投资移民，移民创业两不误！",
          url: "/nz",
          img: require('../assets/hot/18.jpg')
        },
        // {
        //   name: "新加坡投资移民",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/singapore",
        //   img: require('../assets/hot/19.jpg')
        // },
        // {
        //   name: "韩国投资移民",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/korea",
        //   img: require('../assets/hot/20.jpg')
        // },
        // {
        //   name: "马来西亚投资移民",
        //   content: "政策介绍 / 办理流程 / 费用清单",
        //   url: "/Malaysia",
        //   img: require('../assets/hot/21.jpg')
        // },
      ]
    }
  },
  methods: {
    getImg(i) {
      return require('../assets/usa/' + (i + 3) + '.jpg')
    },
    goBanner(id) {
      let list = ["/australiazt", "/canadazt", "/canadazt", "/maltazt", "/usa"]
      this.$router.push(list[id])
    }
  },
}
</script>

<style scoped>
.txt-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: bottom;
  color: #fff;
}

.pic-box {
  cursor: pointer;
  position: relative;
}

.text-large {
  font-size: 24px;
}

.pic-box:hover {
  box-shadow: 0 0 10px #000;
}

</style>