<template>
  <div class="w-100">
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="(item,index) in banner" :key="index">
        <router-link :to="item.url">
          <img :src="item.img" alt="">
        </router-link>

      </el-carousel-item>
    </el-carousel>
    <div class="d-flex justify-content-center py-2" style="background-color:#f2f2f2;">
      <router-link class="item1" tag="div" to="/usa">美国</router-link>
      <router-link class="item1" tag="div" to="/canadazt">加拿大</router-link>
      <router-link class="item1" tag="div" to="/filiaestate">希腊</router-link>
      <router-link class="item1" tag="div" to="/maltazt">马耳他</router-link>
      <router-link class="item1" tag="div" to="/irelandiip">爱尔兰</router-link>
      <router-link class="item1" tag="div" to="/por">葡萄牙</router-link>
      <router-link class="item1" tag="div" to="/japan">日本</router-link>
      <router-link class="item1" tag="div" to="/spainzt">西班牙</router-link>
<!--      <router-link class="item1" tag="div" to="/turkey">土耳其</router-link>-->
      <router-link class="item1" tag="div" to="/ukzt">英国</router-link>
      <router-link class="item1" tag="div" to="/australiazt">澳大利亚</router-link>
      <router-link class="item1" tag="div" to="/nz">新西兰</router-link>
      <router-link class="item1" tag="div" to="/stkitts">圣基茨</router-link>
      <router-link class="item1" tag="div" to="/antigua">安提瓜</router-link>
      <router-link class="item1" tag="div" to="/vanuatu">瓦努阿图</router-link>
    </div>

    <div class="d-flex align-items-center flex-column">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 移民热点 -</strong></span>
        <div> 经典成功案例，专业移民资讯</div>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <div class="item2">
          <el-carousel height="455px">
            <el-carousel-item v-for="(item,index) in banner2" :key="index">
              <router-link :to="item.url">
                <img :src="item.img" alt="" class="w-100" >
              </router-link>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="item2">
          <div>
            <div class="pic-box position-relative">
              <router-link to="/eb1a" target="_blank"><img src=../assets/eb1a.jpg class="img-responsive"
                                                         alt="美国投资移民"></router-link>
              <div class="txt-box">
                EB-1A杰出人才移民 获批快 排期短
              </div>
              <!--txt-box-->
            </div>
            <!--pic-box-->
            <div class="mt-3 d-flex justify-content-between">
              <div class="float-left text-big">经典案例</div>
              <router-link to="/Cases" tag="div" class="float-right" style="cursor:pointer;">更多&gt;</router-link>
            </div>
            <hr class="margin-top am-margin-bottom-0">
            <div class="i-list">
              <router-link tag="div" :to="'/cases/'+cases[item].id" class="icon-reorder" v-for="item in 5" :key="item">
                <span :title="cases[item].title">
                  <i class="el-icon-s-unfold"/> {{ cases[item].title }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
        <div class="item2">
          <div>
            <div class="pic-box position-relative">
              <a href="/burger_king" target="_blank"><img src=../assets/small-humbuger.jpg class="img-responsive"
                                                       alt="美国投资移民"></a>
              <div class="txt-box">
                无排期 最快45天 一步到位全家永绿
              </div>
              <!--txt-box-->
            </div>
            <!--pic-box-->
            <div class="mt-3 d-flex justify-content-between">
              <div class="float-left text-big">移民资讯</div>
              <router-link tag="div" to="/news" class="float-right" style="cursor:pointer;">更多&gt;</router-link>
            </div>
            <hr class="margin-top am-margin-bottom-0">
            <div class="i-list">
              <router-link tag="div" :to="'/news/'+news[item].id" class="icon-reorder" v-for="item in 5" :key="item">
                <span :title="news[item].title">
                  <i class="el-icon-s-unfold"/> {{ news[item].title }}
                </span>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex flex-column align-items-center  py-4 my-4" style="background-color:#c39d5e;">
        <h1 style="color:#FFF;" class="height-large"><strong>跃迁出国专业服务</strong></h1>
        <h3 style="color:#FFF;" class="height-large padding-bottom"><strong>一个电话，帮您更快移民：13764320113</strong>
        </h3>
      </div>
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 热门项目 -</strong></span>
        <div> 通过风险评估体系，延续成功经验</div>
      </div>

      <div class="d-flex flex-wrap justify-content-center" style="max-width: 1330px">
        <router-link tag="div" :to="item.url" class="pic-box m-3" v-for="(item,index) in list" :key="index">
          <img :src="item.img" class="img-responsive" alt="美国移民">
          <div class="txt-box1">
            <div style="font-size: 24px;color: #FFFFFF">{{ item.title }}</div>
            <div style=" color: #FFFFFF">{{ item.content }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div style="background-color: #f2f2f2" class="py-4">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 欧洲购房移民 -</strong></span>
        <div>真实房源 一房一价</div>
      </div>

      <div class="d-flex justify-content-center flex-wrap mt-3">
        <div class="pic-box m-3" style="width: 380px;" v-for="(item,index) in list2" :key="index">
          <img :src="item.img" class="w-100">
          <img src="../assets/insale.png" class="sale-icon" style="position:absolute;top: 0;right: 0">
          <div class="text-center py-3 bg-white">
            <div v-for="content in item.content" :key="content">
              {{ content }}
            </div>
            <router-link class="mt-3 info-btn" :to="item.url">了解详情</router-link>
          </div>
        </div>
      </div>
    </div>

    <LiveBox show-type="1"/>
  </div>
</template>

<script>
import LiveBox from "@/components/LiveBox";
import Cases from "@/cases";
import news from "@/news";

export default {
  name: 'HelloWorld',
  props: {},
  components: {LiveBox},
  data() {
    return {
      cases: Cases,
      news: news,
      banner: [
        {
          img: require('../assets/001.jpg'),
          url: '/eb5newlaw',
        },
        // {
        //   img: require('../assets/noodles/banner.jpg'),
        //   url: '/noodles',
        // },
        {
          img: require('../assets/BurgerKing/banner.jpg'),
          url: '/burger_king',
        },
        {
          img: require('../assets/EB1A海报.jpg'),
          url: '/eb1a',
        },
        {
          img: require('../assets/美国E-2签证海报.jpg'),
          url: '/passport',
        },
        // {
        //   img: require('../assets/002.jpg'),
        //   url: '/oneandonly',
        // },
        //
        // {
        //   img: require('../assets/003.jpg'),
        //   url: '/oneandonly',
        // },
        {
          img: require('../assets/004.jpg'),
          url: '/filiaestate',
        },
        {
          img: require('../assets/008.jpg'),
          url: '/aboutus',
        },
      ],
      banner2: [

        {
          img: require('../assets/l1.jpg'),
          url: '/por',
        },
        {
          img: require('../assets/l2.jpg'),
          url: '/passport',
        },
        {
          img: require('../assets/l3.jpg'),
          url: '/eb1a',
        },
      ],
      list: [
        {
          title: "美国移民",
          content: "通过风险评估体系，延续美国移民成功经验",
          url: "/usa",
          img: require('../assets/01.jpg')
        },
        {
          title: "葡萄牙购房移民",
          content: " 1人申请，3代移民，2000成功案例坚实保障",
          url: "/por",
          img: require('../assets/09.jpg')
        },
        {
          title: "格林纳达护照投资",
          content: "先批准再投资，低成本获得美国E2小绿卡",
          url: "/grenada",
          img: require('../assets/13.jpg')
        },
        {
          title: "加拿大安省雇主担保移民",
          content: "门槛低，一人申请，全家移民！",
          url: "/canadazt",
          img: require('../assets/02.jpg')
        },
        {
          title: "爱尔兰投资移民",
          content: "先批准后投资，1份投资，3重身份",
          url: "/irelandiip",
          img: require('../assets/03.jpg')
        },
        {
          title: "马耳他移民",
          content: "购房租房2选1，一步到位永久拘留",
          url: "/maltazt",
          img: require('../assets/04.jpg')
        },
        {
          title: "希腊购房移民",
          content: "25万欧起，3代欧盟绿卡",
          url: "/filiaestate",
          img: require('../assets/05.jpg')
        },
        // {
        //   title: "土耳其护照投资",
        //   content: "40万美元购房，全家护照，英美跳板",
        //   url: "/turkey",
        //   img: require('../assets/06.jpg')
        // },
        {
          title: "英联邦圣基茨护照",
          content: "15万美元捐赠，3个月获全家护照",
          url: "/stkitts",
          img: require('../assets/07.jpg')
        },
        {
          title: "日本房产投资",
          content: "年预期收益7%*，全家安居享福利！",
          url: "/japan",
          img: require('../assets/08.jpg')
        },

        {
          title: "西班牙购房移民",
          content: "50万欧购旺铺，全家移民欧盟，预期8%年收益！",
          url: "/spainzt",
          img: require('../assets/10.jpg')
        },
        {
          title: "安提瓜护照投资",
          content: "加勒比优质度假房源，身份、投资、回购三不误！",
          url: "/antigua",
          img: require('../assets/11.jpg')
        },
        // {
        //   title: "英国企业家移民",
        //   content: " 5万英镑起投资，快速移民英国",
        //   url: "/ukzt",
        //   img: require('../assets/12.jpg')
        // },
      ],
      list2: [
        // {
        //   img: require('../assets/h1.jpg'),
        //   url: "/filiaestate",
        //   content: ['项目名称：希腊翡丽庄园', '物业类型：爱琴海私家泳池别墅', '真实包租']
        // },
        // {
        //   img: require('../assets/h2.jpg'),
        //   url: "/house/1",
        //   content: ['项目区域：希腊雅典南部', '项目面积：120平方米', '真实包租']
        // },
        // {
        //   img: require('../assets/h3.jpg'),
        //   url: "/house/2",
        //   content: ['项目区域：希腊雅典北部', '项目面积：67平方米', '真实包租']
        // },
        {
          img: require('../assets/f11.jpg'),
          url: "/house/3",
          content: ['项目区域：希腊雅典北部', '项目面积：89平方米起', '项目类型：2022年全新楼盘', '限时抢购']
        },
        {
          img: require('../assets/f22.jpg'),
          url: "/house/4",
          content: ['项目区域：西班牙巴塞罗那市中心', '项目面积：116平方米', '项目类型：精品公寓', '真实包租']
        },
        {
          img: require('../assets/f33.jpg'),
          url: "/house/5",
          content: ['项目区域：西班牙巴塞罗那', '项目面积：310平方米', '项目类型：联排别墅', '真实包租']
        },
      ]
    }
  },
  methods: {
    getImg(index) {
      if (index < 9) return require('../assets/0' + (index + 1) + '.jpg')
      return require('../assets/' + (index + 1) + '.jpg')
    },
    goBanner(id) {
      let list = ["/eb5newlaw", "/oneandonly", "/oneandonly", "/filiaestate", "/aboutus",
        "/canadazt"]
      this.$router.push(list[id])
    },
    goBanner2(id) {
      let list = ["/filiaestate", "/canadazt", "/oneandonly"]
      this.$router.push(list[id])
    }
  }


}
</script>
<style scoped>
a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.info-btn:hover {
  background-color: #f5f5f5;
}

.info-btn {
  color: #428bca;
  text-decoration: none;
  border: solid 1px #ddd;
  background: transparent;
  border-radius: 4px;
  font-size: 14px;
  padding: 6px 15px;
  margin: 0;
  display: inline-block;
  line-height: 20px;
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1) 0s;
}

.icon-reorder {
  cursor: pointer;
  margin: 6px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.txt-box1 {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pic-box {
  cursor: pointer;
  position: relative;
}

.pic-box:hover {
  box-shadow: 0 0 10px #000;
}

.txt-box {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-indent: 20px;
  color: #fff;
  background: rgba(0, 0, 0, 0.6);
}

.item2 {
  width: 400px;
  height: 455px;
  margin: 0 16px;
}

.item1 {
  cursor: pointer;
  padding-right: 18px;
  color: #333333;
}

.item1:hover {
  text-decoration: underline;
}

.banner-box img {
  height: 100%;
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
