<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/passport/2.jpg" alt="" class="w-100">
  <div class="w1180 d-flex flex-column align-items-center">
    <img src="../assets/grenada/1.jpg" alt="" class="">
    <img src="../assets/grenada/2.jpg" alt="" class="">
    <img src="../assets/grenada/3.jpg" alt="" class="">
    <img src="../assets/grenada/4.jpg" alt="" class="">
    <img src="../assets/grenada/5.jpg" alt="" class="">
    <img src="../assets/grenada/6.jpg" alt="" class="">
    <img src="../assets/grenada/7.jpg" alt="" class="">
  </div>
</div>
</template>

<script>
export default {
  name: "grenada"
}
</script>

<style scoped>

</style>