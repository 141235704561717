<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/eb5newlaw/1.jpg" alt="" class="w-100">
    <div class="w1180 row my-4">
      <div class="col-4">
        <img src="../assets/eb5newlaw/2.jpg" alt="" class="w-100">
      </div>
      <div class="col-8">
        <div class="w-100 align-items-end" style="font-size: 3rem;font-weight: bold">
          美国投资移民EB-5新规落地<br>
          中国投资人的重大利好
        </div>
        <div class="mt-4 position-relative" style="font-size: 15px">
          2022年3月15日，拜登总统签署了EB-5改革与诚信法案，作为《2022年综合拨款法案》(公法117-103)的一部分，该法案为EB-5移民签证类别和区域中心项目创造了新的要求。根据区域中心项目，移民签证的有效期截止到2027年9月30日。<br>
          同时，法案将EB-5的投资款调整为80/105万美元。EB-5的标准投资款为105万美元，投资于目标就业区（TEA地区）的项目可以只投资80万美元。TEA地区定义为：<br>
          <br>
          1、乡村项目（根据最近的人口普查数据，常住人口少于20,000）<br>
          2、城市高失业率地区（失业率是平均失业率的150%）<br>
          <br>
          本次的法案为EB-5预留3,200张签证配额，分别分配给：<br>
          1、乡村项目20%，即2,000张签证配额<br>
          2、城市高失业率地区10%，即1,000张签证配额<br>
          3、国家基建项目2%，即200张签证配额<br>
          4、如果当年预留的签证名额没有完全使用，未使用的名额可继续保留至下一财年，在同样类别的EB-5项目中使用；若第二年的签证预留名额依旧没有使用完毕，未使用的名额将释放给所有EB-5申请人使用。
          <img src="../assets/eb5newlaw/logo.png" alt="" class="position-absolute" style="height: 200px;right: 20px;bottom: -20px">
        </div>
      </div>
    </div>
    <img src="../assets/eb5newlaw/newlaw_06.jpg" alt="" class="w1180">

    <div class="my-4 p-2" style="border: 1px solid #000">
      <div>一步到位直接拿绿卡· 子女拥有好教育· 全家乐享自由地</div>
    </div>
    <div class="w1180 d-flex justify-content-center p-4" style="background-color: #c8a062">
      <img src="../assets/eb5newlaw/9.png" alt="" width="500">
    </div>
    <div class="w1180 d-flex my-4" style="background-color: #dad5d1">
      <img src="../assets/eb5newlaw/10.jpg" alt="" class="w-25">
      <div class="w-25 p-3   height-big">无论是从子女教育角度，还是从最终获得的绿卡属性来看， EB-5投资移民都拥有当之无愧的含金量
      </div>
      <img src="../assets/eb5newlaw/11.jpg" alt="" class="w-25">
      <div class="w-25 p-3   height-big">
        投资额：80万美元起 <br>
        绿卡属性：直接获批美国绿卡 <br>
        主申请人：14 岁以上子女可独立申请 <br>
        办理周期：乡村项目约2年
      </div>
    </div>
    <img src="../assets/eb5newlaw/newlaw_10.jpg" alt="" class="my-4 w1180">

    <div class="layout2 d-flex flex-column align-items-center w1180">
      <div class="mt-5 p-2" style="border: 1px solid  #c8a062">
        <div class=" ">一步到位直接拿绿卡· 子女拥有好教育· 全家乐享自由地</div>
      </div>
      <img src="../assets/eb5newlaw/title.png" alt="" width="500" class="my-4">
      <div class="w1180 row my-4">
        <div class="col-3 ">
          <div class="w-100 h-100 bg-white">
            <img src="../assets/eb5newlaw/17.jpg" alt="" class="w-100">
            <div class="p-4 ">
              <h2 class="text-center">周期调整</h2>
              <div class="line-red"></div>
              <p>此次法案将EB-5区域中心计划的投资额调整至80万美元，同时规定自2027.1.1起，每5年根据过去5年CPI增幅，相应提高投资额。
              </p>
            </div>
          </div>
        </div>
        <div class="col-3 ">
          <div class="w-100 h-100 bg-white">
            <img src="../assets/eb5newlaw/18.jpg" alt="" class="w-100">
            <div class="p-4 ">
              <h2 class="text-center">签证预留</h2>
              <div class="line-red"></div>
              <p>新法根据项目属性，明确了各类项目的申请人可以获得的签证预留名额 <br>
                乡村项目：20%<br>
                高失业地区10%<br>
                基建项目：2%<br>
                这也意味着，优质乡村项目将成为市场稀缺型产品
              </p>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="w-100 h-100 bg-white">
            <img src="../assets/eb5newlaw/19.jpg" alt="" class="w-100">
            <div class="p-4 ">
              <h2 class="text-center">审理优先</h2>
              <div class="line-red"></div>
              <p>
                新法首次引入了审理优先级排名概念，移民局将在审理时根据该排名，依照顺序，进行投资人的申请审理。<br>
                第一优先： 乡村项目<br>
                第二优先：国土安全部自行确定
              </p>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="w-100 h-100 bg-white">
            <img src="../assets/eb5newlaw/20.jpg" alt="" class="w-100">
            <div class="p-4 ">
              <h2 class="text-center">诚信措施</h2>
              <div class="line-red"></div>
              <p>
                国土安全部将制定严格的区域中心监管和诚信条例规定， 投资人也可更放心的投资美国。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src="../assets/eb5newlaw/newlaw_14.jpg" alt="" class="w1180">
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "eb5newlaw"
}
</script>

<style scoped>
.tit {
  font-size: 24px;
  font-weight: bold;
}

.circle {
  width: 250px;
  height: 250px;
  margin: 0 auto;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 50%;
  background-color: #ac0113;
}

.num {
  font-size: 32px;
  font-weight: bold;
  margin-top: 15px;
}

.content {
  margin-top: 40px;
  line-height: 30px;
  font-size: 18px;
}

.inner {
  border: solid #fff 2px;
  width: 230px;
  height: 230px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
}

.line-red {
  width: 50px;
  height: 5px;
  background-color: #ac0113;
  margin: 10px auto 20px;
}

.item {
  padding: 10px 0;
  color: #FFFFFF;
  position: absolute;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 125px;
  width: 200px;
}

.layout {
  background: url("../assets/eb5newlaw/6.png");
  background-size: cover;
}

.layout2 {
  background-color: #dad5d1;
  background-size: cover;
}

.layout3 {
  background: url("../assets/eb5newlaw/23.jpg");
  background-size: cover;
}

.layout1 {
  background: url("../assets/eb5newlaw/7.png");
  background-size: cover;
  height: 567.44px;
  width: 983px;
  position: relative;
}
</style>