<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/America/1.jpg" alt="" class="w-100">
    <div class="w-100 d-flex flex-column align-items-center py-4" style="background-color: #f0ebe5">
      <img src="../assets/America/2.jpg" alt="" class="">
      <div class="w1180 row my-4">
        <div class="col-3 p-0" v-for="item in 8" :key="item">
          <img :src=" require('../assets/America/'+(item+2)+'.jpg')" alt="" class="w-100">
        </div>
      </div>
      <img src="../assets/America/11.jpg" alt="" class="my-4">


    </div>
    <div class="d-flex justify-content-center w-100 layout">
      <div class="w1180 row">
        <div class="col-4 p-4">
          <img src="../assets/America/13.jpg" alt="" class="w-100">
          <div class="d-flex flex-column align-items-center p-4 position-relative">
            <p style="color:#f0bd47;font-size:25px;">EB-5投资移民</p>
            <div class="text-start position-absolute" style="left: 0;top: 78px">
              <p style=" margin-bottom:10px">资金要求</p>
              <p style=" margin-bottom:10px">商业背景</p>
              <p style=" margin-bottom:10px">职业要求</p>
              <p style="margin-bottom:10px">办理周期</p>
            </div>
            <div class="text-center"  >
              <p style=" margin-bottom:10px"> 80/105万美元</p>
              <p style=" margin-bottom:10px"> 不需要</p>
              <p style=" margin-bottom:10px"> 无</p>
              <p style="margin-bottom:10px"> 3年</p>
            </div>
          </div>
        </div>
        <div class="col-4 p-4">
          <img src="../assets/America/14.jpg" alt="" class="w-100">
          <div class="d-flex flex-column align-items-center p-4">
            <p style="color:#f0bd47;font-size:25px;">EB-1A专业杰出人才移民</p>
            <div class="text-center">
                <p style="margin-bottom:10px">无</p>
                <p style="margin-bottom:10px">不需要</p>
                <p style="margin-bottom:10px">专业领域领先人物</p>
                <p style="margin-bottom:10px">6-12个月</p>
            </div>
          </div>
        </div>
        <div class="col-4 p-4">
          <img src="../assets/America/15.jpg" alt="" class="w-100">
          <div class="d-flex flex-column align-items-center p-4">
            <p style="color:#f0bd47;font-size:25px;">EB-1C跨国公司高管移民</p>
            <div class="text-center">
                 <p style="margin-bottom:10px">20万美元起</p>
                <p style="margin-bottom:10px">需要</p>
                <p style="margin-bottom:10px">无</p>
                <p style="margin-bottom:10px">12-18个月</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link tag="div" to="/noodles" class="w-100">
      <img src="../assets/noodles/banner.jpg" alt="" class="w-100">
    </router-link>


    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "America"
}
</script>

<style scoped>
.layout{
  background-color:#f3efea;
  background-size: cover;
}
</style>