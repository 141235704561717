<template>
  <div class="w-100">
    <el-carousel class="banner-box" height="30vw">
      <el-carousel-item v-for="(item,index) in banner" :key="index">
        <router-link :to="item.url">
          <img :src="item.img" alt="" class="w-100">
        </router-link>
      </el-carousel-item>
    </el-carousel>


    <div class="d-flex flex-column align-items-center">
      <div class=" text-center mt-3">
        <span style="font-size: 24px;"><strong>- 热门欧洲目的地 -</strong></span>
        <div>项目简介、核心对比、优势凸显</div>
      </div>
      <div class="d-flex flex-wrap" style="width: 1300px;">
        <router-link  tag="div" :to="item.url" class="m-3" v-for="(item,index) in list" :key="index">
          <div class="pic-box">
            <img :src="item.img" class="aligncenter img-responsive">
            <div class="txt-box1">
              <p>
                <strong class="text-large">{{ item.name }}</strong>
              </p>
              <p>{{ item.content }}</p>
              <p></p>
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <LiveBox />
  </div>
</template>

<script>
import LiveBox from "@/components/LiveBox";

export default {
  name: "europe",
  components: {LiveBox},

  data() {
    return {
      banner: [
        {
          img:require('../assets/europe/1.jpg'),
          url:'/por'
        },
        {
          img:require('../assets/europe/2.jpg'),
          url:'/filiaestate'
        },
        {
          img:require('../assets/europe/3.jpg'),
          url:'/spainzt'
        },
      ],
      list:[
        {
          name:"葡萄牙购房移民",
          content:"1人申请，3代移民，海量成功案例坚实保障",
          url:"/por",
          img:require('../assets/europe/10.jpg')
        },
        {
          name:"西班牙购房移民",
          content:"5-10年带租约 全家欧洲身份",
          url:"/spainzt",
          img:require('../assets/europe/9.jpg')
        },

        {
          name:"希腊购房移民",
          content:"25万欧购房，3代欧洲绿卡，5.2%年预期收益",
          url:"/filiaestate",
          img:require('../assets/europe/5.jpg')
        },
        {
          name:"爱尔兰投资移民",
          content:"先批准后投资，1份投资，3重身份",
          url:"/irelandiip",
          img:require('../assets/europe/4.jpg')
        },

        {
          name:"马耳他移民",
          content:"买房租房2选1，1人申请4代移民",
          url:"/maltazt",
          img:require('../assets/europe/8.jpg')
        },
        {
          name:"英国首席代表签证",
          content:"低成本、低门槛，1人申请，全家移民",
          url:"/ukzt",
          img:require('../assets/europe/6.jpg')
        },



      ],
      list2:[
        {
          name:"爱尔兰投资移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/irelandiip",
          img:require('../assets/europe/11.jpg')
        },
        {
          name:"希腊购房移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/filiaestate",
          img:require('../assets/europe/12.jpg')
        },
        {
          name:"马耳他移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/maltazt",
          img:require('../assets/europe/13.jpg')
        },
        {
          name:"葡萄牙购房移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/por",
          img:require('../assets/europe/14.jpg')
        },
        {
          name:"西班牙购房移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/spainzt",
          img:require('../assets/europe/15.jpg')
        },
        {
          name:"英国投资移民",
          content:"国家介绍 / 办理流程 / 费用清单",
          url:"/ukzt",
          img:require('../assets/europe/16.jpg')
        },
      ],
    }
  },methods:{
    getImg(i){
      return require('../assets/usa/' + (i + 3) +'.jpg')
    },
    goBanner(id){
      let list= ["/eu","/filiaestate","/maltazt","/maltazt","/usa"]
      this.$router.push(list[id])
    }
  }
}
</script>

<style scoped>
.txt-box1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: bottom;
  color: #fff;
}
.pic-box {
  cursor: pointer;
  position: relative;
}
.text-large {
  font-size: 24px;
}
.pic-box:hover {
  box-shadow: 0 0 10px #000;
}

</style>