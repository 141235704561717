<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/canadazt/1.jpg" alt="" class="w-100">
    <img src="../assets/canadazt/2.jpg" alt="" class="">
    <img src="../assets/canadazt/3.jpg" alt="" class="w-100">
    <img src="../assets/canadazt/4.jpg" alt="" class="w-100">
    <img src="../assets/canadazt/5.jpg" alt="" class="w-100">
    <img src="../assets/canadazt/6.jpg" alt="" class="w-100">
    <img src="../assets/canadazt/7.jpg" alt="" class="my-4">
    <div class="d-flex justify-content-between" style="width: 1180px;">
      <div class="t2 text-center" v-for="(item,index) in tabs" :key="index" @click="tab = index">
        <div class="city-tit" :class="{'city-hover':tab === index}">{{ item }}</div>
      </div>
    </div>
    <div class="row justify-content-between w-100 my-4" style="max-width: 1180px;" v-if="tab === 0">
      <div class="col-7">
        <div class="wordbox  d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            1
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>资产要求</strong></div>
            <div class="wordbox-content">无资产及投资要求，无需解释资金来源
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            2
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>门槛低</strong></div>
            <div class="wordbox-content">无语言要求，无需面试，全程在国内递交申请</div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            3
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>速度快</strong></div>
            <div class="wordbox-content">6个月快速移居加拿大，一人申请，全家移民</div>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <img src="../assets/canadazt/canadat_11.jpg" class="w-100">
      </div>
    </div>
    <div class="row justify-content-between w-100 my-4" style="max-width: 1180px;" v-if="tab === 1">
      <div class="col-7">
        <div class="wordbox  d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            1
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>资产要求</strong></div>
            <div class="wordbox-content">拥有超过200万加币的净资产
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            2
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>管理经验</strong></div>
            <div class="wordbox-content">最近五年中有两年以上企业管理经验</div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            3
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>投资要求</strong></div>
            <div class="wordbox-content">- 融资预计:35万加币 (不返还)</div>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <img src="../assets/canadazt/canadat_11.jpg" class="w-100">
      </div>
    </div>
    <div class="row justify-content-between w-100 my-4" style="max-width: 1180px;" v-if="tab === 2">
      <div class="col-7">
        <div class="wordbox  d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            1
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>资产要求</strong></div>
            <div class="wordbox-content">夫妻净资产至少达到50万加币，且资产的80%经第三方机构认证是合法所得
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            2
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>管理经验</strong></div>
            <div class="wordbox-content">近十年中有三年以上管理经验，高管、股东、个体户均可申请</div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            3
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>投资意愿</strong></div>
            <div class="wordbox-content">
              <div>a) 愿意在萨斯卡通或里贾纳投资30万加元、萨省其他地区投资20万加元</div>
              <div>b) 至少拥有1/3的公司股权并参与日常经营，或者投资100万加元合股并参与到日常管理当中</div>
              <div>c) 在萨斯卡通或里贾纳投资，必须提供两个非亲属、本地人就业岗位（如果购买一个当地企业必须保留原有员工数）
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <img src="../assets/canadazt/canadat_12.jpg" class="w-100">
      </div>
    </div>
    <div class="row justify-content-between w-100 my-4" style="max-width: 1180px;" v-if="tab === 3">
      <div class="col-7">
        <div class="wordbox  d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            1
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>资产要求</strong></div>
            <div class="wordbox-content">
              主申请人或夫妻双方名下至少有50万加元净资产， 且能证明其财产来源合法
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            2
          </div>
          <div class="ms-3">
            递交申请前五年中至少有三年的高级管理经验或成功的企业经营经验
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            3
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>其它要求</strong></div>
            <div class="wordbox-content">
              对曼省进行至少5个工作日的商务考察，必须是在EOI递交之前12个月内进行的工签获批后，申请人需在登陆加拿大的24个月内按要求在曼省创办或者购买企业，曼尼托巴首都地区（MCR）至少投资250,000加元，MCR地区之外至少150,000加币;
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <img src="../assets/canadazt/canadat_14.jpg" class="w-100">
      </div>
    </div>
    <div class="row justify-content-between w-100 my-4" style="max-width: 1180px;" v-if="tab === 4">
      <div class="col-7">
        <div class="wordbox  d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            1
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>申请前五年有至少两年艺术、文化、体育方面的工作经验：</strong></div>
            <div class="wordbox-content">
              <div>①参加过世界级的文化或体育活动，或；</div>
              <div>②有艺术、文化、体育行业自雇经验（依靠自己在艺术、文化或体育方面的才能或作品获得主要收入）。</div>
              <div>* 可以是上述两类经验各一年，或者每种经验两年</div>
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            2
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>创业要求</strong></div>
            <div class="wordbox-content">
              申请者必须有意向，有能力将来去加拿大创立文体方面的事业，且至少能为申请者自己创造就业机会（自雇），且能够为加拿大的文化、体育行业创造重大贡献。
            </div>
          </div>
        </div>
        <div class="wordbox d-flex">
          <div class="wordbox-num float-left" style="line-height: 80px;">
            3
          </div>
          <div class="ms-3">
            <div class="wordbox-tit"><strong>其它要求</strong></div>
            <div class="wordbox-content">
              项目评分标准满分为100，达到35 分即可递交申请。 有足够的资金可以支持自己和随行家庭人员抵加之后一年的生活开支及未来发展计划。
              <br>全家通过体检，无犯罪记录。
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 p-0">
        <img src="../assets/canadazt/aidehuawangzidao.jpg" class="w-100">
      </div>
    </div>
    <img src="../assets/canadazt/11.jpg" alt="" class="my-4">
    <img src="../assets/canadazt/12.jpg" alt="" class="my-4">
    <LiveBox/>
  </div>
</template>

<script>
import Swiper ,{ Autoplay } from "swiper";

export default {
  name: "canadazt",
  mounted() {
    Autoplay
    this.mySwiper = new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        delay: 3000,
      },
    });
  },
  data() {
    return {
      tab: 0,
      tabs: ["安省雇主担保移民", "魁北克省投资移民", "萨斯喀彻温省提名", "曼尼托巴商业移民", "联邦自雇移民"]
    }
  }
}
</script>

<style scoped>
@import url("../../node_modules/swiper/swiper.min.css");

.swiper-container {
  width: 100%;
  height: 293px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;

}

.layout {
  background: url('../assets/canadazt/14.jpg') no-repeat center center;
  background-size: cover;
}

.wordbox-content {
  font-size: 14px;
}

.wordbox {
  background-color: #f5f7f6;
  margin-bottom: 8px;
  padding: 10px;
}

.wordbox-num {
  font-size: 48px;
  border-right: solid 2px #ccc;
  padding: 0 15px;
}

.t2 {
  width: 220px;
}

.city-hover {
  background-color: #ff9c00 !important;
  color: white;
}

.city-tit {
  cursor: pointer;
  background-color: #bbd0e5;
  padding: 10px 0;
}
</style>