<template>
  <div>
    <img src="../assets/oneandonly/001.jpg" alt="" class="w-100">
    <div class="d-flex flex-column align-items-center position-relative" style="background: #eee;">
      <div class="shu"></div>
      <div style="    color: #b1824a;font-size: 30px;font-weight: bold">绿卡快速获批</div>
      <div>机构级美国乡村EB-5投资移民项目</div>

      <div class="bg-white p-4 d-flex shadow mt-5" style="width: 1000px;">
        <div class="px-3">
          <div class="padding-big">
            <p class="height-big" style="color:#555;">
              2022年3月15日，美国国会通过了《EB-5
              改革和诚信法案》，该法案不仅成功将美国EB-5法案延续5年至2027年，同时，明确了配额的归属事宜。在最终的配额分配中，乡村地区TEA项目成为了大赢家——不仅独享每年2000个配额，且将获得移民局的优先审理，这宣告了美国乡村地区EB-5投资移民项目黄金时代的来临。
            </p>
            <hr class="margin-large-top">
            <p style="" class="height-large text-big text-brown text-center margin-large-top">
              黄石俱乐部III期 · 唯逸奢华度假村项目<br> 机构级乡村EB-5项目
              <br> 投资人将获得优先配额和快速审理
            </p>
          </div>
        </div>
        <div class="x3">
          <img src="../assets/oneandonly/002.jpg" class="w-100">
          <p class="text-center" style="font-size: 14px">
            唯逸奢华度假村<br>机构级乡村EB-5项目
          </p>
        </div>
      </div>

      <div class="shu"></div>
      <div style="    color: #b1824a;font-size: 30px;font-weight: bold">复制经典·延续成功</div>
      <div>黄石俱乐部再添压轴力作</div>
      <img src="../assets/oneandonly/003.jpg" alt="" class="my-4" style="width:1180px">
      <div class="d-flex">
        <img src="../assets/oneandonly/0041.jpg" alt="">
        <img src="../assets/oneandonly/0042.jpg" alt="">
        <img src="../assets/oneandonly/0043.jpg" alt="">
      </div>
      <div style="width: 1035px;height: 1px;background-color: #ddd;position:absolute;bottom: 21px">
      </div>
      <div class="d-flex mt-3" style="width: 1035px;font-size: 14px;z-index: 2">
        <div class="d-flex flex-column align-items-center flex-grow-1">
          <div class="h3">I期-黄石俱乐部</div>
          <div>I-526绿卡已获批</div>
          <div>EB-5本息已返还</div>
          <div>豪宅已售罄</div>
          <div class="box"></div>
        </div>
        <div class="d-flex flex-column align-items-center flex-grow-1">
          <div class="h3">II期-蒙太奇酒店</div>
          <div>I-526绿卡已获批</div>
          <div>酒店已开业</div>
          <div>房价约1500-23000美元/晚</div>
          <div class="box"></div>
        </div>

        <div class="d-flex flex-column align-items-center flex-grow-1">
          <div class="h3">III期-唯逸奢华度假村</div>
          <div>延续成功</div>
          <div> 项目已动工</div>
          <div>EB-5资金募集中</div>
          <div class="box"></div>
        </div>
      </div>

    </div>


    <div class="d-flex flex-column align-items-center layout">
      <div class="shu"></div>
      <div style="    color: #b1824a;font-size: 30px;font-weight: bold">绿卡快速获批</div>
      <div class="text-white">
        黄石国家公园 · 美国国家公园 · 世界自然遗产
      </div>
      <el-carousel class="my-4" :interval="4000" type="card" height="300px" style="width: 1180px">
        <el-carousel-item v-for="(item,index) in list" :key="index">

          <div class="position-relative">
            <img :src="item.img" alt="" width="100%">
            <div class="img-text">{{ item.name }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="d-flex flex-column align-items-center">
      <div class="shu"></div>
      <div style="    color: #b1824a;font-size: 30px;font-weight: bold">黄石俱乐部</div>
      <div class="">
        世界富豪俱乐部
      </div>
      <div class="d-flex my-3" style="width: 1180px;background-color: #eeeeee">
        <img src="../assets/oneandonly/0071.jpg" alt="">
        <div class="p-3" style="">
          <p class="height-big">黄石俱乐部坐落在美国蒙大拿州西南部落基山下的黄石国家公园内。</p>

          <p class="height-big">
            俱乐部实行严格的会员制，初始入会费40万美元，年费约4.4万美元，入会会员需购买俱乐部指定区域内不少于700万美元的公寓/别墅，尽管入会门槛高到咂舌，但申请人依旧趋之若鹜。</p>
        </div>
        <img src="../assets/oneandonly/0072.jpg" alt="">
      </div>
      <img src="../assets/oneandonly/008.svg" alt="" width="200" class="my-3">
      <div class="d-flex my-3 align-items-center">
        <div style="height:1px ;width: 465px;background-color: #174f42 "></div>
        <div class="px-4" style="    font-size: 24px; color: #174f42;">黄石俱乐部名人堂</div>
        <div style="height:1px ;width: 465px;background-color: #174f42 "></div>
      </div>

      <div class="" style="width: 1180px;overflow:hidden;">
        <!-- 轮播图 -->
        <!-- Swiper -->
        <div class="swiper-container" style="width: 200px;">
          <div class="swiper-wrapper">
            <div class="swiper-slide d-flex flex-column align-items-center" v-for="(item,index) in list2" :key="index">
              <img :src="item.img"/>
              <div>{{ item.name }}</div>
              <div>{{ item.content }}</div>
            </div>
          </div>
          <!-- Add Pagination -->
        </div>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center layout2" style="height: 500px">
      <div class="shu"></div>
      <div style="    color: #FFFFFF;font-size: 30px;font-weight: bold">黄石俱乐部</div>
      <div class="text-white">
        世界富豪俱乐部
      </div>
    </div>

    <div class="d-flex justify-content-center" style="margin-top: -300px;">
      <div class="d-flex px-3" style="background-color:#8f9daa;width: 983px;">
        <div>
          <div class="item3" :class="{'active':now===0}" @mouseover="now = 0">
            <div class="l-box" v-if="now===0"></div>
            <div class="title">开发商</div>
            <div>CrossHarbor Capital</div>
            <div>黄石俱乐部主要股东</div>
            <div>坐拥大天空度假区80%可开发土地</div>
          </div>
          <div class="item3" :class="{'active':now===1}" @mouseover="now = 1">
            <div class="l-box" v-if="now===1"></div>
            <div class="title">酒店管理</div>
            <div>Kerzner International</div>
            <div>迪拜主权基金控股酒店集团，旗下已有4个奢华及精</div>
            <div>品酒店品牌，在全球拥有无数精英拥趸</div>
          </div>
          <div class="item3" :class="{'active':now===2}" @mouseover="now = 2">
            <div class="l-box" v-if="now===2"></div>
            <div class="title">奢华品牌</div>
            <div>One & Only 唯逸酒店</div>
            <div>Kerzner旗舰品牌，获奖无数，全球拥趸</div>
            <div>黄石俱乐部III期为唯逸酒店北美旗舰店</div>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-grow-1">
          <img :src="p[now]" alt="" height="416px">
        </div>
      </div>
    </div>


    <div class="d-flex   w-100" style="background-color: #323754;">
      <div class="flex-grow-1">
        <img src="../assets/oneandonly/0121l.jpg" alt="" class=" w-100">
      </div>
      <div class="d-flex flex-column justify-content-center" style="width: 50vw;flex-shrink: 0">
        <div class="text-center " style="font-size: 30px;font-weight: bold;color: #b1824a;">
          项目概要
        </div>
        <div class="d-flex">
          <div class="w-75">
            <img src="../assets/oneandonly/018.png" alt="" class="w-100">
          </div>
          <div class="d-flex flex-column justify-content-center">
            <div class="my-3 text-white">项目总预算 3亿美元</div>
            <div class="my-3 text-white">移民投资款 80万美元</div>
            <div class="my-3 text-white">总投资名额 150个</div>
          </div>
        </div>
      </div>
      <div class="flex-grow-1">
        <img src="../assets/oneandonly/0121r.jpg" alt="" class=" w-100">
      </div>
    </div>

    <div class="d-flex flex-column align-items-center">
      <div class="shu"></div>
      <div style="color:#b1824a ;font-size: 30px;font-weight: bold">投资安全·绿卡保障</div>
      <div class="">
        机构级美国乡村EB-5项目
      </div>

      <div>
        <div class="" style="width: 1180px;">
          <div class="row" content="w-100">
            <div class="col-3 d-flex flex-column align-items-center ">
              <img src="../assets/oneandonly/0131.png">
            </div>
            <div class="col-3  d-flex flex-column align-items-center ">
              <img src="../assets/oneandonly/0132.png">
            </div>
            <div class="col-3  d-flex flex-column align-items-center ">
              <img src="../assets/oneandonly/0133.png">
            </div>
            <div class="col-3  d-flex flex-column align-items-center ">
              <img src="../assets/oneandonly/0134.png">
            </div>
          </div>
          <div class="row"
               style="font-size: 14px;background-color: #eeeeee;padding-top: 160px;padding-bottom: 16px;margin-top: -140px;margin-bottom: 20px;">
            <div class="col-3  d-flex flex-column align-items-center py-3">
              <h2>还款保证</h2>
              <div>
                开发商提供I-526投资款还款保证
              </div>
              <div>
                若I-526被拒，将返还投资人本金
              </div>
            </div>
            <div class="col-3  d-flex flex-column align-items-center border-start py-3">
              <h2>退出机制</h2>
              <p>通过出售/再融资完成退出<br>投资人第一顺位获得还款
              </p>
            </div>
            <div class="col-3  d-flex flex-column align-items-center border-start py-3">
              <h2>绿卡保障</h2>
              <div>4.7倍移民局就业要求</div>
              <div>
                2021年项目已开工
              </div>

            </div>
            <div class="col-3  d-flex flex-column align-items-center border-start py-3">
              <h2>资金安全</h2>
              <p>开发商出资60%<br>项目预算资金已全部就位
              </p>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="d-flex flex-column align-items-center layout3 pb-4">
      <div class="shu"></div>
      <div style="    color: #b1824a;font-size: 30px;font-weight: bold">项目进度</div>
      <div class="text-white mt-3">
        大天空度假区唯逸度假村已于2021年开工，目前项目的建设工作正在有条不紊的进行之中。
      </div>
      <div class="text-white">
        首批19套私人豪华住宅于2022年推出后即售罄，售价区间845万 - 1420万美元。
      </div>
      <img class="mt-4" src="../assets/oneandonly/015.png" alt="" width="1000px">
    </div>
    <LiveBox/>
  </div>
</template>

<script>
import Swiper from "swiper";
import LiveBox from "@/components/LiveBox";

export default {
  name: "oneandonly",
  components: {LiveBox},
  mounted() {
    new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
      },
    });

  },
  data() {
    return {
      now: 0,
      p: [
        require("../assets/oneandonly/0111.jpg"),
        require("../assets/oneandonly/0112.jpg"),
        require("../assets/oneandonly/0113.jpg"),
      ],
      list: [
        {name: "黄石湖", img: require('../assets/oneandonly/0061.jpg')},
        {name: "西姆指间歇泉盆地", img: require('../assets/oneandonly/0062.jpg')},
        {name: "黄石大峡谷", img: require('../assets/oneandonly/0063.jpg')},
        {name: "猛犸象温泉", img: require('../assets/oneandonly/0064.jpg')},
        {name: "黄石国际机场", img: require('../assets/oneandonly/0065.jpg')},
      ],
      list2: [
        {name: "比尔·盖茨", content: "微软创始人", img: require('../assets/oneandonly/00901.jpg')},
        {name: "马克·扎克伯格", content: "脸书创始人兼CEO", img: require('../assets/oneandonly/00902.jpg')},
        {name: "埃里克·施密特", content: "谷歌前CEO", img: require('../assets/oneandonly/00903.jpg')},
        {name: "奥普拉·温弗瑞", content: "脱口秀主持人", img: require('../assets/oneandonly/00904.jpg')},
        {name: "本·阿弗莱克", content: "演员", img: require('../assets/oneandonly/00905.jpg')},
        {name: "阿什顿·库彻", content: "演员", img: require('../assets/oneandonly/00906.jpg')},
        {name: "贾斯汀·汀布莱克", content: "歌手", img: require('../assets/oneandonly/00907.jpg')},
        {name: "吉赛尔·邦辰", content: "超模", img: require('../assets/oneandonly/00908.jpg')},
        {name: "汤姆·韦斯科夫", content: "传奇高尔夫巨星", img: require('../assets/oneandonly/00909.jpg')},
        {name: "菲尔·迈克尔森", content: "传奇高尔夫巨星", img: require('../assets/oneandonly/00910.jpg')},
        {name: "汤姆·布雷迪", content: "传奇美式橄榄球运动员", img: require('../assets/oneandonly/00911.jpg')},
        {name: "詹姆斯·丹·奎尔", content: "美国前副总统", img: require('../assets/oneandonly/00912.jpg')},
      ]
    }
  }
}
</script>

<style scoped>

@import url("../../node_modules/swiper/swiper-bundle.css");

.l-box {
  background-color: #b1824a;
  height: 100%;
  width: 24px;
  position: absolute;
  left: -10px;
  top: 0;
}

.item3 {
  margin-bottom: 16px;
  position: relative;
  background-color: #dadfe3;
  padding: 10px 20px;
}

.item3.active {
  background-color: #FFFFFF;
  padding: 10px 20px;
}

.item3 .title {
  font-size: 24px;
}

.item3.active .title {
  color: #b1824a;
}

.swiper-container {
  height: 200px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  width: 200px;
}

.swiper-slide img {

}


.img-text {
  padding: 6px 0;
  width: 100%;
  color: #FFFFFF;
  text-align: center;
  position: absolute;
  bottom: 52px;
  background-color: rgba(0, 0, 0, 0.79);
}

.layout {
  background-image: url("../assets/oneandonly/005.jpg");
  background-size: cover;
}

.layout2 {
  background-image: url("../assets/oneandonly/010.jpg");
  background-size: cover;
}

.layout3 {
  background-image: url("../assets/oneandonly/014.jpg");
  background-size: cover;
}


.h3 {
  margin: 16px 0;
  font-size: 18px;
}

.text-brown {
  color: #b1824a;
}

.height-large {
  line-height: 40px;
}

.height-big {
  line-height: 30px;
}

.shadow {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.shu {
  width: 1px;
  height: 70px;
  background: #b1824a;
}

.box {
  margin: 16px 0;
  width: 120px;
  height: 11px;
  background: #b1824a;
}
</style>