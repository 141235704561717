<template>
  <div class="d-flex flex-column align-items-center">
    <img src="../assets/tiec/1.jpg" alt="" class="w-100">
    <div class="d-flex w-100 justify-content-center layout py-5">
      <div class="w1180 d-flex flex-column align-items-center ">
        <img src="../assets/tiec/2.png" alt="" class="">
        <p style="color:#004932;font-size:25px;" class="margin-large-top my-4">
          集四大优势于一体，稀缺的优质美国EB-5投资移民项目</p>
        <img src="../assets/tiec/4.jpg" alt="" class="">
      </div>
    </div>
    <div class="d-flex w-100 justify-content-center layout1 py-5">
      <div class="w1180 d-flex flex-column align-items-center ">
        <img src="../assets/tiec/2.png" alt="" class="">
        <p style="color:#FFF;font-size:25px;" class="margin-large-top my-4">
          综合马术中心，连续3年举办美国马术三项赛的赛事</p>
        <img src="../assets/tiec/6.png" alt="" class="">
      </div>
    </div>

    <div class="line-big row w1180 justify-content-center">
      <div class="col-10" style="padding-left:0;">
        <p>
          腾龙国际马会位于北卡罗兰纳州腾龙(Tryon)镇，占地1,600英亩(近10,000市亩)，目前已成为全球知名的综合马术中心。马会包括马术表演场馆(已建成)、训练中心(已建成)、五星级酒店、度假别墅(已建成)、露营公园、多家餐厅(已建成)、零售中心(已建成)、高尔夫球场、以及一个包括网球场馆,
          健身中心和各类泳池及水上项目的综合运动场地。</p>

        <p>
          腾龙国际马会的主表演场馆、训练中心、餐厅及商业中心已经完工并对外开放。2017年中的52周里，有41周在举办高规格的赛事。目前，室内马术赛场以及五星级酒店正在兴建中。</p>

        <p>
          马术运动是一项年产值达上千亿美元的产业，也深受社会名流、精英阶层的热爱。特朗普、盖茨、乔布斯、斯普林斯汀(摇滚巨星)、布隆伯格(前纽约市长、亿万富翁)等社会名流家庭都是马术运动的爱好者，也是腾龙国际马会的常客。</p>
      </div>
    </div>

    <img src="../assets/tiec/7.png" alt="" class="">
    <img src="../assets/tiec/8.png" alt="" class="">
    <div class="layout2 w-100 d-flex justify-content-center py-4">
      <div class="w1180">
        <div class="row w-100">
          <div class="col-7 align-items-center row">
            <div class="col-1">
              <img src="../assets/tiec/TIEC_30.jpg">
            </div>
            <div class="col-11">
              <div class="mb-2" style="color:#000; font-size:25px ">2018世界马术运动会举办地</div>
              <div style="color:#000; font-size:25px ">2018 FEI World Equestrian Games</div>
            </div>
          </div>
          <div class="col-5 d-flex justify-content-center">
            <img src="../assets/tiec/10.png" alt="">
          </div>
        </div>
        <div class="w-75 my-1">
          2018世界马术运动会将于2018年9月11-23日于腾龙国际马会举办。世界马术运动会为国际马术联合会(FEI)旗下赛事，每4年一届，是高级别的世界马术大赛，被誉为马术奥运会。能承办世界马术运动会，对于马会而言是至高的荣誉。
        </div>
        <div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i1.png" alt="">
            <div class="ms-3">
              逾50万现场观众
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i1.png" alt="">
            <div class="ms-3">
              逾4.5亿次媒体报道
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i2.png" alt="">
            <div class="ms-3">
              逾1.3亿社交媒体粉丝互动
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i3.png" alt="">
            <div class="ms-3">
              齐聚70个国家和50个州的选手
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i4.png" alt="">
            <div class="ms-3">
              逾500万美元奖金及40周马术比赛
            </div>
          </div>
          <div class="d-flex align-items-center">
            <img src="../assets/tiec/i5.png" alt="">
            <div class="ms-3">
              每年130万独立网站访问者
            </div>
          </div>
        </div>
        <div style="height: 200px;">
        </div>
      </div>
    </div>

    <div class="w1180 d-flex flex-column align-items-center my-4">
      <img src="../assets/tiec/2.png" alt="">
      <p class="margin-large-top my-4" style="color: rgb(0, 73, 50); font-size: 25px;">
        首批投资人在递交申请2个月内快速获批l-526条件绿卡</p>
      <img src="../assets/tiec/11.jpg" alt="">
      <img src="../assets/tiec/12.jpg" alt="" class="my-4">
    </div>

    <div class="w-100 d-flex justify-content-center layout3">
      <div class="w1180 py-4">
        <img src="../assets/tiec/14.png" alt="">
      </div>
    </div>
    <div class="w-100 d-flex justify-content-center layout4">
      <div class="w1180 py-4">
        <div class="w-100 align-items-center d-flex">
          <div class="">
            <img src="../assets/tiec/TIEC_30.jpg">
          </div>
          <div class="ms-4">
            <div class="mb-2" style="color:#000; font-size:25px ">区域中心</div>
            <div style="color:#000; font-size:25px ">Appalachian EB-5, LLC</div>
          </div>
        </div>

        <div class="w-100 align-items-center justify-content-between row">
          <div class="col-7 text-left" style="padding-left:0">
            <p style="color:#000;margin-bottom:18px">美国阿巴拉契亚EB-5区域中心由Odlum
              Capital创建，致力于筹集EB-5资金，促进地方经济增长。</p>
            <p style="color:#000;margin-bottom:18px">由于Odlum
              Capital为传统农业地区成功引入资金并创造大量就业，因此其在政商两届声誉卓著，其董事会成员及区域中心顾问委员会成员包括耶鲁大学现任校长彼得
              · 沙洛维(Peter Salovey)以及美国前陆军参谋长Larry Ellis上将等各届名流。</p>
          </div>
          <div class="col-4">
            <img src="../assets/tiec/16.jpg" alt="" class="w-100">
          </div>
        </div>
        <div class="w-100 align-items-center d-flex">
          <div class="">
            <img src="../assets/tiec/TIEC_30.jpg">
          </div>
          <div class="ms-4">
            <div class="mb-2" style="color:#000; font-size:25px ">开发商</div>
            <div style="color:#000; font-size:25px ">TRYON EQUESTRIAN PARTNERS, LLC</div>
          </div>
        </div>

        <div class="w-100 align-items-center justify-content-between row">
          <div class="col-7 text-left" style="padding-left:0">
            <p style="color:#000;margin-bottom:18px">以首席执行官Mark
              Bellissimo为首的腾龙国际马会的股东们在马术赛事和举办场地的开发业务方面都拥有相当丰富的经验。</p>
            <p style="color:#000;margin-bottom:18px">
              腾龙国际马会的创办股东同时也是惠灵顿马术合伙企业(WEP)的创办大股东和管理合伙人。股东们拥有并管理棕榈滩国际马术中心(PBIEC)，PBIEC是位于佛罗里达惠灵顿的一家私人马会，被认为是世界上三大马会之一。举世闻名的冬季马术节(WEF)即在此地举办，该马术节在全球享有崇高声誉。</p>
          </div>
          <div class="col-4">
            <img src="../assets/tiec/17.jpg" alt="" class="w-100">
          </div>
        </div>
        <div style="height: 150px"></div>
      </div>

    </div>
    <LiveBox/>
  </div>
</template>

<script>
export default {
  name: "tiec"
}
</script>

<style scoped>
.layout {
  background: url('../assets/tiec/3.png') no-repeat center center;
  background-size: cover;
}

.layout1 {
  background: url('../assets/tiec/5.jpg') no-repeat center center;
  background-size: cover;
}

.layout2 {
  background: url('../assets/tiec/9.jpg') no-repeat center center;
  background-size: cover;
}

.layout3 {
  background: url('../assets/tiec/13.png') no-repeat center center;
  background-size: cover;
}

.layout4 {
  background: url('../assets/tiec/15.jpg') no-repeat center center;
  background-size: cover;
}
</style>