<template>
<div class="d-flex flex-column align-items-center">
  <img src="../assets/maltazt/1.jpg" alt="" class="w-100">
  <img src="../assets/maltazt/2.jpg" alt="" width="1180">
  <div class="layout d-flex justify-content-center w-100">
    <img src="../assets/maltazt/4.png" alt="" width="1180">
  </div>
  <img src="../assets/maltazt/5.jpg" alt="" class="w-100"  style="max-width: 2000px">

  <LiveBox  />
</div>
</template>

<script>
export default {
  name: "maltazt"
}
</script>

<style scoped>

.layout {
  background: url('../assets/maltazt/bj_maertat05.jpg') no-repeat center center;
  background-size: cover;
}

</style>